import React from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { mixpanel } from 'mednet-util/src/tracking'

import { Header1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { openModal } from 'mednet-cns/src/reducers/modal'
import {
  createAnswer,
  createAnswerAsUser,
  CREATE_ANSWER,
  CREATE_ANSWER_AS_USER,
} from 'mednet-cns/src/reducers/question'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { POST_ANSWER_MODAL } from 'mednet-util/src/constants/modal'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import { TextEditor } from 'pharmacy/src/input/textEditor'

import { PostOnBehalf } from '../../question/postOnBehalf'

import PostAnswerModal from './postAnswerModal'

import css from './writeAnswer.scss'

class WriteAnswer extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
    this.state = {
      value: '',
      author: null,
    }
  }

  componentDidMount() {
    mixpanel.track_forms(this.form.current, 'Answered Question', {
      id: this.props.questionId,
      question: this.props.question,
    })
  }

  handleEditorChange = (value) => {
    this.setState({ value })
  }

  onAuthorChange = (author) => {
    this.setState({ author: author || null })
  }

  onPostAsSomebodyElseToggle = () => {
    this.setState((prevState) => ({
      postAsSomebodyElse: !prevState.postAsSomebodyElse,
      author: null,
    }))
  }

  handleSubmit = () => {
    const { createAnswer, isPermittedToPostAsUser } = this.props
    window.tinyMCE.triggerSave()

    createAnswer(isPermittedToPostAsUser, this.form.current, (response) => {
      if (response.isError) {
        return
      }

      this.setState(
        {
          value: '',
          author: null,
        },
        () => {
          window.tinyMCE.triggerSave()
          localStorage.removeItem(
            `tinymce-autosave-${document.location.pathname}-answer-time`
          )
          localStorage.removeItem(
            `tinymce-autosave-${document.location.pathname}-answer-draft`
          )
        }
      )

      this.props.openPostAnswerModal(response)
    })
  }

  render() {
    const { createAnswerIsLoading, pollId, stubbed } = this.props

    return (
      <div id="writeAnswer">
        <PostAnswerModal pollId={pollId} />
        <form ref={this.form}>
          <div className={css.container}>
            <Header1>Write an answer</Header1>
            <ul className={css.bullets}>
              <li>Directly answer the question and give a recommendation</li>
              <li>Explain the rationale for the recommendation</li>
              <li>Cite personal experience and/or published literature</li>
            </ul>
            {stubbed ? null : (
              <PostOnBehalf
                onChange={this.onAuthorChange}
                checkboxProps={{ selectText: 'Select to answer on behalf of' }}
              />
            )}
            <TextEditor
              disabled={createAnswerIsLoading}
              id="answer"
              onEditorChange={this.handleEditorChange}
              name="answer"
              value={this.state.value}
            />
            <Button
              className={css.submitButton}
              isDisabled={!this.state.value}
              isLoading={createAnswerIsLoading}
              onClick={this.handleSubmit}
              type="primary"
            >
              Submit an Answer
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const { question, pollId } = state.question.questions[questionId] || {}
  const createAnswerRequest =
    _.get(state.request.requests, makeRequestName(CREATE_ANSWER, questionId)) ||
    _.get(
      state.request.requests,
      makeRequestName(CREATE_ANSWER_AS_USER, questionId)
    ) ||
    {}
  const isPermittedToPostAsUser = ownProps.isUserPermittedTo(
    authItems.postAsUser
  )

  return {
    createAnswerIsLoading: createAnswerRequest.isLoading,
    isPermittedToPostAsUser,
    question,
    pollId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createAnswer: (isPermittedToPostAsUser, form, callback) =>
      isPermittedToPostAsUser
        ? dispatch(createAnswerAsUser(ownProps.questionId, form, callback))
        : dispatch(createAnswer(ownProps.questionId, form, callback)),
    openPostAnswerModal: (response) =>
      dispatch(openModal(POST_ANSWER_MODAL.modalId, { response })),
  }
}

export default compose(
  withPermissions(authItems.createAnswer),
  connect(mapStateToProps, mapDispatchToProps)
)(WriteAnswer)
