import React from 'react'
import Autosuggest from 'react-autosuggest'
import {
  Configure,
  connectAutoComplete,
  InstantSearch,
} from 'react-instantsearch-dom'
import ReactTooltip from 'react-tooltip'

import { Link } from 'pharmacy/src/navigation/link'
import { Header4, Subtitle2 } from 'pharmacy/src/typography'

import algoliasearch from 'algoliasearch'

import css from './hintedQuestionInput.scss'

class QuestionInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      showTooltip: false,
    }
    props.refine(props.value)
    this.tooSpecificTooltipRef = React.createRef()
  }

  checkIfQuestionIsCaseBased = (question) => {
    const regexp = /\d+\s*(?:years?[.\-/ ]old|y[.\-/ ]*rs? old|y[.\-/ ]*o)/i

    if (regexp.exec(question)) {
      this.showTooltip()
    } else {
      this.hideTooltip()
    }
  }

  hideTooltip = () => {
    this.setState({ showTooltip: false })
  }

  showTooltip = () => {
    this.setState({ showTooltip: true })
  }

  onChange = (event, { method, newValue }) => {
    if (method === 'type') {
      this.setState({
        value: newValue,
      })
      this.props.onChange(newValue)
      this.checkIfQuestionIsCaseBased(newValue)
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
  }

  getSuggestionValue(hit) {
    return hit.question
  }

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { className, ...restProps } = containerProps
    const classes = [className]

    !(children && this.state.value) &&
      classes.push(css.suggestionsContainerHidden)

    return (
      <div {...restProps} className={classes.join(' ')}>
        <Header4>Similar questions that may have your answer</Header4>
        {children}
      </div>
    )
  }

  renderSuggestion = (hit) => {
    const highlightResult = hit._highlightResult
    const hitContent = highlightResult.question.value

    return (
      <Link
        className={css.suggestionLink}
        target="_blank"
        pathname={`/question/${hit.question_id}`}
      >
        <Subtitle2>
          <div
            className={css.suggestionLinkContent}
            dangerouslySetInnerHTML={{ __html: hitContent }}
          />
        </Subtitle2>
      </Link>
    )
  }

  getSectionSuggestions(section) {
    return section.hits
  }

  renderInputComponent = (inputProps) => {
    return <textarea autoComplete="off" component="textarea" {...inputProps} />
  }

  render() {
    const { hits, inputProps } = this.props
    const { value, showTooltip } = this.state

    const theme = {
      sectionContainer: css.sectionContainer,
      sectionContainerFirst: css.sectionContainerFirst,
      sectionTitle: css.sectionTitle,
      suggestion: css.suggestion,
      suggestionsContainer: css.suggestionsContainer,
      suggestionsList: css.suggestionsList,
      input: css.input,
    }

    return (
      <>
        <div ref={(ref) => (this.tooSpecificTooltipRef = ref)} data-tip="">
          <ReactTooltip
            effect="solid"
            clickable={false}
            scrollHide={false}
            overridePosition={() => ({ top: -30, left: 0 })}
            className={css.tooltip}
            event="keydown"
            eventOff="fake"
            multiline
          >
            {!showTooltip ? null : (
              <div>
                It looks like you&apos;re trying to ask a case-based question.
                <br />
                Questions should not be case-based or contain any patient
                identifiable information.
              </div>
            )}
          </ReactTooltip>
          <Autosuggest
            getSectionSuggestions={this.getSectionSuggestions}
            getSuggestionValue={this.getSuggestionValue}
            inputProps={{
              ...inputProps,
              value,
              onChange: this.onChange,
            }}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={this.renderSuggestion}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            suggestions={hits}
            theme={theme}
            alwaysRenderSuggestions
            focusInputOnSuggestionClick
          />
        </div>
      </>
    )
  }
}
const ConnectedQuestionInput = connectAutoComplete(QuestionInput)

class HintedQuestionInput extends React.Component {
  render() {
    const { userData, ...restProps } = this.props

    const { algoliaApp, algoliaKey, algoliaNamespace } = userData
    const searchClient = algoliasearch(algoliaApp, algoliaKey)

    return (
      <InstantSearch
        indexName={`${algoliaNamespace}_question`}
        searchClient={searchClient}
      >
        <ConnectedQuestionInput {...restProps} />

        <Configure highlightPreTag="<em>" highlightPostTag="</em>" />
        <Configure hitsPerPage={5} />
      </InstantSearch>
    )
  }
}

export default HintedQuestionInput
