import React from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { ProximaNova } from 'pharmacy/src/typography/index'

import { makeImageURL } from 'mednet-util/src/router'
import { getUserName } from 'mednet-util/src/string'
import { VIEW_BIO_MODAL } from 'mednet-util/src/constants/modal'

import { openModal } from 'mednet-cns/src/reducers/modal'

import css from './userPhotoCard.scss'

const userCardSize = {
  xsmall: {
    nameComponent: ProximaNova.Subtitle2,
    titleComponent: ProximaNova.Subtitle3,
  },
  // Will be used for associate editors
  small: {
    nameComponent: ProximaNova.Subtitle2,
    titleComponent: ProximaNova.Subtitle3,
  },
  // Used for the team members in about us page
  medium: {
    nameComponent: ProximaNova.Header3,
    titleComponent: ProximaNova.Subtitle1,
  },
  // Will be used for deputy editors
  large: {
    nameComponent: ProximaNova.Header3,
    titleComponent: ProximaNova.Subtitle1,
  },
}

export const UserPhotoCard = (props) => {
  const {
    imagePath,
    size = 'medium',
    user,
    viewBio = true,
    showTitle = true,
    showInstitution = false,
    showSpecialty = false,
    horizontalInSmallScreens = false,
    readBioFromDb = true,
    CustomNameComponent,
    CustomTitleComponent,
  } = props

  const dispatch = useDispatch()
  const { userId, specialty, title, institutionName, photoName, imageVersion } =
    user

  const imgUrl = makeImageURL(
    photoName || userId || 'nophoto',
    imageVersion,
    undefined,
    imagePath
  )

  const userName = getUserName(user)

  const NameComponent = CustomNameComponent
    ? CustomNameComponent
    : userCardSize[size]['nameComponent']
  const TitleComponent = CustomTitleComponent
    ? CustomTitleComponent
    : userCardSize[size]['titleComponent']

  const cardContainerClasses = classNames({
    [css.centeredContainer]: true,
    [css.horizontalContainerWhenSmallScreen]: horizontalInSmallScreens,
  })

  const userImageContainerClasses = classNames({
    [css.centeredContainer]: true,
    [css[`userImageContainer_${size}`]]: true,
    [css.userImageContainerInHorizontalCard]: horizontalInSmallScreens,
  })

  const openBioModal = () =>
    dispatch(
      openModal(VIEW_BIO_MODAL.modalId, {
        userId,
        specialty,
        showTitle,
        showSpecialty,
        showInstitution,
        readDataFromDb: readBioFromDb,
      })
    )

  return (
    <div className={cardContainerClasses}>
      <div className={userImageContainerClasses}>
        <img src={imgUrl} className={css.userImage} />
        {viewBio && (
          <ProximaNova.LabelWhiteText
            className={css.imageLabel}
            onClick={openBioModal}
          >
            View Bio
          </ProximaNova.LabelWhiteText>
        )}
      </div>
      <div className={css.userDescription}>
        <NameComponent className={css.userDescriptionTextContainer}>
          {userName}
        </NameComponent>
        {specialty && showSpecialty && (
          <TitleComponent className={css.userDescriptionTextContainer}>
            {specialty}
          </TitleComponent>
        )}
        {title && showTitle && (
          <TitleComponent className={css.userDescriptionTextContainer}>
            {title}
          </TitleComponent>
        )}
        {institutionName && showInstitution && (
          <TitleComponent className={css.userDescriptionTextContainer}>
            {institutionName}
          </TitleComponent>
        )}
      </div>
    </div>
  )
}
