import React from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { Link1, Header4 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import { fetchUserPeers, FETCH_USER_PEERS } from 'mednet-cns/src/reducers/user'

import UserInviteCard from './userInviteCard'

import css from './userInviteMixin.scss'

const PEERS_TO_SHOW = 4

class UserInviteMixin extends React.Component {
  componentDidMount() {
    const { fetchUserPeers, numPeers } = this.props

    if (numPeers === 0 || numPeers >= PEERS_TO_SHOW) {
      fetchUserPeers()
    }
  }

  render() {
    const { peerUserIds, isLoaded } = this.props

    if (isLoaded && !peerUserIds.length) {
      return null
    }

    return (
      <div className={css.container}>
        <div className={css.header}>
          <Header4 className={css.headerTitle}>People you may know</Header4>
          <Link1>
            <Link
              external
              className={css.seeMoreLink}
              pathname="/peerSuggest/view"
            >
              See more
            </Link>
          </Link1>
        </div>
        <div className={css.cards}>
          {_.range(_.min([PEERS_TO_SHOW, peerUserIds.length])).map((index) => {
            const peerUserId = peerUserIds[index]

            return <UserInviteCard key={index} userId={peerUserId} />
          })}
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const peers = state.user.peers || []
  const peerUserIds = peers.slice(
    PEERS_TO_SHOW * ownProps.itemIndex,
    PEERS_TO_SHOW * (ownProps.itemIndex + 1)
  )

  const userPeersRequest = _.get(state.request.requests, FETCH_USER_PEERS) || {}

  return {
    peerUserIds,
    numPeers: peers.length,
    isLoaded: userPeersRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserPeers: () => dispatch(fetchUserPeers()),
  }
}

export default compose(
  withPermissions(authItems.member),
  connect(mapStateToProps, mapDispatchToProps)
)(UserInviteMixin)
