import React from 'react'
import { InView } from 'react-intersection-observer'

import { pluralizeWord } from 'mednet-util/src/string'
import { Link2, Header2, Body1 } from 'pharmacy/src/typography'
import { Card } from 'pharmacy/src/display/card'
import { GoodQuestion } from 'pharmacy/src/question/goodQuestion'
import { FollowQuestionButton } from 'pharmacy/src/question/followQuestionButton'
import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'
import { QuestionHighlight } from 'pharmacy/src/question/questionHighlight'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { UpdatePreview } from 'pharmacy/src/question/updatePreview'

import { mixpanel } from 'mednet-util/src/tracking'

import css from './questionCard.scss'

class TrackedQuestionCardInnerWrapper extends React.Component {
  componentDidUpdate(prevProps) {
    const { isVisible, item, itemIndex } = this.props
    if (isVisible && !prevProps.isVisible) {
      mixpanel.track('viewed_feed_item', {
        item_type: 'question_summary',
        item_position: itemIndex + 1,
        question_id: item.questionId,
        question_specialty: item.authorSpecialtyId,
        question_topics: item.questionTopicsIds,
        is_anonymous: item.isAnonymous ? true : false,
        is_starred: item.isFollowed ? true : false,
      })
    }
  }

  render() {
    const { children } = this.props

    return <>{children}</>
  }
}

class TrackedQuestionCardWrapper extends React.Component {
  render() {
    const { children, ...restProps } = this.props
    return (
      <InView threshold={[0.5]}>
        {({ ref, entry }) => {
          return (
            <div ref={ref}>
              <TrackedQuestionCardInnerWrapper
                isVisible={entry?.intersectionRatio >= 0.5}
                {...restProps}
              >
                {children}
              </TrackedQuestionCardInnerWrapper>
            </div>
          )
        }}
      </InView>
    )
  }
}

class QuestionCard extends React.Component {
  static defaultProps = {
    questionLinkProps: {
      target: '_blank',
    },
    WrapperCompnent: React.Fragment,
  }

  render() {
    const { item, questionLinkProps, feedName, itemIndex, trackViewdItems } =
      this.props

    const { question, questionId, numAnswers, lastUpdate } = item

    let WrapperCompnent = this.props.WrapperCompnent
    let wrapperComponentProps = {}

    if (trackViewdItems) {
      WrapperCompnent = TrackedQuestionCardWrapper
      wrapperComponentProps = { item, itemIndex }
    }

    const extendedQuestionLinkProps =
      feedName && Number.isInteger(itemIndex)
        ? {
            ...questionLinkProps,
            search: { src: `${feedName}_feed`, pos: itemIndex + 1 },
          }
        : questionLinkProps

    return (
      <WrapperCompnent {...wrapperComponentProps}>
        <Card>
          <div className={css.questionContainer}>
            <div className={css.starContainer}>
              <FollowQuestionButton
                questionId={questionId}
                feedName={feedName}
                itemIndex={itemIndex}
              />
            </div>
            <div className={css.content}>
              <QuestionHighlight questionId={questionId} theme="card" />
              <Header2>
                <QuestionLink
                  className={css.questionLink}
                  questionId={questionId}
                  {...extendedQuestionLinkProps}
                >
                  {question}
                </QuestionLink>
              </Header2>
              <GoodQuestion
                questionId={questionId}
                feedName={feedName}
                itemIndex={itemIndex}
              />
            </div>
          </div>
          <div className={css.updateContainer}>
            <UserUpdateInformation
              userId={lastUpdate.userId}
              dateUpdated={lastUpdate.dateUpdated}
              actionId={lastUpdate.actionId}
            >
              <UpdatePreview
                item={item.lastUpdate}
                TextComponent={Body1}
                questionLinkProps={{
                  className: css.moreLink,
                  ...extendedQuestionLinkProps,
                }}
              />
            </UserUpdateInformation>
          </div>
          {numAnswers > 0 && (
            <div className={css.numAnswerContainer}>
              <Link2>
                <QuestionLink
                  className={css.numAnswerLink}
                  questionId={questionId}
                  {...extendedQuestionLinkProps}
                >
                  See {numAnswers} {pluralizeWord(numAnswers, 'Answer')}
                </QuestionLink>
              </Link2>
            </div>
          )}
        </Card>
      </WrapperCompnent>
    )
  }
}

export default QuestionCard
