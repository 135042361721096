import React from 'react'
import moment from 'moment'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link } from 'pharmacy/src/navigation/link'

import { usePressReleases } from 'mednet-cns/src/hooks/pressRelease'

import { LandingPageContent } from 'components/landingPageContent'

import { EXTERNAL_LINKS } from 'constants/links'

import css from './newsReleases.scss'

export const NewsReleases = () => {
  const [pressReleases, pressReleasesRequest] = usePressReleases()

  if (!pressReleasesRequest.isLoaded) {
    return <StarLoader isFullScreen />
  }

  return (
    <LandingPageContent>
      <div className={css.pageContent}>
        <div className={css.pageTitle}>Recent Press Releases</div>

        <div className={css.innerPageContent}>
          <div className={css.releasesContainer}>
            {pressReleases.map((pressRelease, index) => {
              return (
                <div key={index} className={css.release}>
                  <div className={css.releaseSubtitle}>
                    TheMednet -{' '}
                    {moment(pressRelease.date, 'YYYY-MM-DD').format(
                      'MMM D, YYYY'
                    )}
                  </div>
                  <Link pathname={pressRelease.url} target="_blank" external>
                    <div className={css.releaseTitle}>{pressRelease.title}</div>
                  </Link>
                </div>
              )
            })}
          </div>

          <div className={css.pressDetails}>
            <div className={css.detailItem}>
              <a href="mailto: press@themednet.org">
                <div className={css.detailTitle}>press@themednet.org</div>
              </a>
              <div className={css.detailDescription}>
                Get in touch with our PR team
              </div>
            </div>

            <div className={css.detailItem}>
              <Link {...EXTERNAL_LINKS.blog}>
                <div className={css.detailTitle}>Our Blog</div>
              </Link>
              <div className={css.detailDescription}>
                See what&rsquo;s up at the Mednet
              </div>
            </div>

            <div className={css.detailItem}>
              <Link {...EXTERNAL_LINKS.press}>
                <div className={css.detailTitle}>In the News</div>
              </Link>
              <div className={css.detailDescription}>
                See what people are saying
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingPageContent>
  )
}
