import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { FieldArray, Field } from 'formik'

import { Link1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import Stub from 'pharmacy/src/navigation/stub/stub'

import PollOptions from './pollOptions'

import css from './pollForm.scss'

class PollForm extends React.Component {
  onSameResponse = () => {
    const { setFieldValue, handleSubmit } = this.props

    setFieldValue('same', 1, false)
    handleSubmit()
  }

  onNoRelevantExperience = () => {
    const { setFieldValue, handleSubmit, noExperienceOptionId } = this.props
    setFieldValue('vote', [], false)
    setFieldValue('noExperience', noExperienceOptionId, false)
    handleSubmit()
  }

  render() {
    const {
      handleReset,
      handleSubmit,
      initialValues,
      innerRef,
      isMultiple,
      isSubmitting,
      noExperienceOptionId,
      options,
      showResults,
      showVote,
      updated,
      values,
      stubbed,
    } = this.props

    const enableVote =
      (updated || !_.isEqual(values, initialValues)) &&
      Boolean(values.vote.length)

    const submitContainerClasses = classNames(css.submitContainer, {
      [css.submitContainer_hidden]: !showVote,
    })

    const voteButtonClasses = classNames(css.voteButton, {
      [css.voteButton_revote]: showResults,
    })

    const optionsById = _.keyBy(options, 'optionId')
    const shouldAutoFocus = !_.some(
      values.vote.map((value) => _.get(optionsById[value], 'isOther'))
    )

    const LinkComponent = stubbed ? Stub : Link1

    return (
      <form ref={innerRef} onSubmit={handleSubmit} onReset={handleReset}>
        <FieldArray
          name="vote"
          render={(arrayHelpers) => (
            <PollOptions
              arrayHelpers={arrayHelpers}
              options={options}
              isMultiple={isMultiple}
              showResults={showResults}
              showVote={showVote}
              noExperienceOptionId={noExperienceOptionId}
            />
          )}
        />
        {showVote && enableVote && !stubbed && (
          <Field
            component="textarea"
            name="pollComment"
            placeholder="(Optional) Describe your reasoning"
            className={css.pollComment}
            autoFocus={shouldAutoFocus}
            rows={3}
          />
        )}
        <div className={submitContainerClasses}>
          <LinkComponent>
            <Button
              type={
                enableVote
                  ? values.pollComment
                    ? 'primary'
                    : 'secondary'
                  : 'neutral'
              }
              className={voteButtonClasses}
              isDisabled={!enableVote}
              isLoading={isSubmitting}
              onClick={stubbed ? _.noop : handleSubmit}
            >
              {showResults && !stubbed ? 'Change my response' : 'Vote'}
            </Button>
          </LinkComponent>
          <Button
            type="text"
            onClick={
              stubbed
                ? _.noop
                : showResults
                ? this.onSameResponse
                : this.onNoRelevantExperience
            }
            isLoading={isSubmitting}
          >
            <LinkComponent className={css.secondaryButton}>
              {showResults && !stubbed
                ? 'My response is the same'
                : 'I don’t have relevant experience'}
            </LinkComponent>
          </Button>
        </div>
      </form>
    )
  }
}

export default PollForm
