import { EXTERNAL_LINKS } from 'constants/links'
import { PAGES } from 'constants/pages'

export const getAboutNavigationLinksHierarchy = (
  getInternalPageLink,
  isGuest
) => {
  return [
    {
      header: 'Community',
      children: [
        getInternalPageLink(PAGES.COMMUNITY_OVERVIEW),
        getInternalPageLink(PAGES.EXPERTS),
        getInternalPageLink(PAGES.EDITORS),
        getInternalPageLink(PAGES.FELLOWS),
        EXTERNAL_LINKS.conduct,
      ],
    },
    {
      header: 'Company',
      children: [
        getInternalPageLink(PAGES.ABOUT_US),
        isGuest ? getInternalPageLink(PAGES.FAQ) : EXTERNAL_LINKS.faq,
        EXTERNAL_LINKS.privacyPolicy,
        EXTERNAL_LINKS.termsOfUse,
        EXTERNAL_LINKS.work,
        { ...getInternalPageLink(PAGES.PARTNERSHIPS), label: 'Programs' },
      ],
    },
    {
      header: 'News',
      children: [
        getInternalPageLink(PAGES.NEWS_RELEASES),
        EXTERNAL_LINKS.press,
        EXTERNAL_LINKS.publications,
        EXTERNAL_LINKS.blog,
      ],
    },
  ]
}
