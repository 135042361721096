import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik, Field, Form } from 'formik'
import classNames from 'classnames'
import { Redirect } from 'react-router'

import { Subtitle3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { MocProfileSubForm } from 'pharmacy/src/cme/mocProfileSubForm'

import { mixpanel } from 'mednet-util/src/tracking'
import { MOC_STATUS } from 'mednet-util/src/constants/cme'

import {
  useCmeAndMocAvailabilityCheck,
  useUpdateMocProfile,
} from 'mednet-cns/src/hooks/cme'
import { useUserMocProfile } from 'mednet-cns/src/hooks/user'

import css from './userMoc.scss'
import { UserProfileUpdateTemplate } from './userProfileUpdateTemplate'

const validationSchema = yup.object().shape({
  abimNumber: yup.string().when(['optOutMoc', 'abimEnabled'], {
    is: (optOutMoc, abimEnabled) => !optOutMoc && abimEnabled,
    then: yup
      .string()
      .required('Required')
      .matches(/^\d{6}$/, 'Invalid ABIM'),
  }),
  abpNumber: yup.string().when(['optOutMoc', 'abpEnabled'], {
    is: (optOutMoc, abpEnabled) => !optOutMoc && abpEnabled,
    then: yup
      .string()
      .required('Required')
      .matches(/^\d{4,}$/, 'Invalid ABP'),
  }),
  birthDate: yup.string().when(['optOutMoc', 'abimEnabled', 'abpEnabled'], {
    is: (optOutMoc, abimEnabled, abpEnabled) =>
      !optOutMoc && (abimEnabled || abpEnabled),
    then: yup
      .string()
      .required('Required')
      .matches(/^\d{2}\/\d{2}$/, 'Should be MM/dd'),
  }),
})

const MocProfileForm = (props) => {
  const { isSubmitting, values, handleSubmit, submissionResult } = props

  const [showValidationErrors, setShowValidationErrors] = useState(false)

  useEffect(() => {
    setShowValidationErrors(false)
  }, [values])

  return (
    <Form className={css.formConatiner}>
      <div>
        <MocProfileSubForm
          {...props}
          showValidationErrors={showValidationErrors}
          useLegacyStyle
        />

        <Subtitle3 className={classNames(css.row, css.optOutRow)}>
          <Field
            type="checkbox"
            name="optOutMoc"
            value={values.optOutMoc}
            checked={values.optOutMoc}
          />
          I would like to opt-out of MOC for all future activities on theMednet
        </Subtitle3>
      </div>

      <div className={css.formFooter}>
        {submissionResult === false && (
          <div className={css.submittionErrorMessage}>Something went wrong</div>
        )}
        <Button
          className={css.button}
          onClick={(...args) => {
            setShowValidationErrors(true)
            handleSubmit(args)
          }}
          isLoading={isSubmitting}
          icon={['fas', 'check']}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

export const UserMoc = (props) => {
  const {
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    isCmeAndMocAvailabilityCheckLoading,
    isCmeAndMocAvailabilityCheckLoaded,
  } = useCmeAndMocAvailabilityCheck()

  const [userData, userRequest] = useUserMocProfile()
  const updateMocProfile = useUpdateMocProfile()
  const [submissionResult, setSubmissionResult] = useState(undefined)

  const initialValues = {
    abimNumber: userData.abim ?? '',
    abpNumber: userData.abp ?? '',
    birthDate: userData.birthDate ?? '',
    optOutMoc:
      userData.cmeAndMocAvailability?.mocStatus === MOC_STATUS.MOC_DISABLED,
    abimEnabled: isAbimMocEnabledForUser,
    abpEnabled: isAbpMocEnabledForUser,
  }

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      updateMocProfile(values, (res) => {
        setSubmitting(false)
        if (res.success) {
          mixpanel.track('Successfully updated data in MOC profile page')
          props.history.push(`/user/user/view/id/${userData.userId}`)
        } else {
          mixpanel.track('Failed to update data in MOC profile page')
          setSubmissionResult(res.success)
        }
      })
    },
    [userData.userId, props.history, setSubmissionResult]
  )

  useEffect(() => {
    mixpanel.track('Visited MOC profile page')
  }, [])

  if (
    !userRequest.isLoaded ||
    isCmeAndMocAvailabilityCheckLoading ||
    !isCmeAndMocAvailabilityCheckLoaded
  ) {
    return <StarLoader />
  }

  if (!userData.isMocAvailableByDefault) {
    return <Redirect to={`/user/user/view/id/${userData.userId}`} push />
  }

  return (
    <UserProfileUpdateTemplate
      pageHeader="Edit CME Information"
      location={props.location}
    >
      <div className={css.details}>
        <div>
          theMednet is proud to offer CME credit & MOC points to our physician
          community. MOC points for qualifying specialties will be reported by
          the University of Chicago at year end.
        </div>
        <div>
          The below information is required by ABIM and ABP maintenance of
          certification (MOC) points to be officially submitted. You may opt in
          or out of MOC at any time.
        </div>
      </div>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        {(props) => (
          <MocProfileForm {...props} submissionResult={submissionResult} />
        )}
      </Formik>
    </UserProfileUpdateTemplate>
  )
}
