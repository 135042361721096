import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import includes from 'lodash/includes'

import css from './header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.throttledResizeHeaderSpacer = _.throttle(this.resizeHeaderSpacer, 100)
    this.headerContainer = React.createRef()
    this.headerSpacer = React.createRef()

    this.unlistenToLocation = this.props.history.listen(this.setIsPublicPage)

    this.state = {
      rendered: false,
      isPublicPage: false,
    }
  }

  componentDidMount() {
    this.resizeHeaderSpacer()
    window.addEventListener('resize', this.throttledResizeHeaderSpacer)
    this.setState({
      rendered: true,
    })

    this.setIsPublicPage()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizeHeaderSpacer)
    this.unlistenToLocation()
  }

  resizeHeaderSpacer = () => {
    if (!this.headerContainer.current) {
      return
    }

    const clientRect = this.headerContainer.current.getBoundingClientRect()
    this.headerSpacer.current.style.height = `${clientRect.height}px`
  }

  setIsPublicPage = () => {
    const pathname = this.props.history.location.pathname
    this.setState({
      isPublicPage:
        !pathname ||
        pathname === '/' ||
        this.props.publicRoutes.some((publicPath) =>
          includes(pathname, publicPath)
        ),
    })
  }

  render() {
    const { rendered, isPublicPage } = this.state

    const headerClasses = classNames(css.header, this.props.className, {
      [css.header_fixed]: rendered,
      [css.visibleContainer]: !isPublicPage,
    })

    const headerContentClasses = classNames({
      [css.content]: true,
      [css.visibleContainer]: isPublicPage,
      [css.content_large]: !isPublicPage,
      [css.content_xlarge]: isPublicPage,
    })

    return (
      <div>
        <div className={headerClasses} ref={this.headerContainer}>
          <div className={headerContentClasses}>{this.props.children}</div>
        </div>
        <div className={css.headerSpacer} ref={this.headerSpacer} />
      </div>
    )
  }
}

export default Header
