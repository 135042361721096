import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { Button } from 'pharmacy/src/input/button'
import { Link2, Subtitle3 } from 'pharmacy/src/typography'

import { withPermissions } from 'mednet-util/src/permission'
import { toggleVoteQuestion } from 'mednet-cns/src/reducers/question'
import { authItems } from 'mednet-util/src/constants/permission'

import css from './goodQuestion.scss'

class GoodQuestion extends React.Component {
  static defaultProps = {
    numVotes: 0,
  }

  handleClick = () => {
    const { isVoted, toggleVoteQuestion } = this.props
    toggleVoteQuestion(isVoted)
  }

  render() {
    const { numVotes, isVoted } = this.props
    const thumbIcon = [isVoted ? 'fas' : 'far', 'thumbs-up']

    return (
      <div className={css.container}>
        <Link2 className={css.text}>
          <Button onClick={this.handleClick} type="text" icon={thumbIcon}>
            Good Question
          </Button>
        </Link2>
        {numVotes > 0 && (
          <Subtitle3 className={css.subtitle}>{numVotes}</Subtitle3>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { numVotes, isVoted } =
    state.question.questions[ownProps.questionId] || {}

  return {
    numVotes,
    isVoted,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleVoteQuestion: (prevVotedState) =>
      dispatch(
        toggleVoteQuestion(
          ownProps.questionId,
          ownProps.feedName,
          ownProps.itemIndex,
          prevVotedState
        )
      ),
  }
}

export default compose(
  withPermissions(authItems.createVote),
  connect(mapStateToProps, mapDispatchToProps)
)(GoodQuestion)
