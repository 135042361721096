import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { DatePicker } from 'pharmacy/src/input/datePicker'
import { Header2, Subtitle3, Link1, Body1 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { Button } from 'pharmacy/src/input/button'
import { refresh } from 'mednet-util/src/router'

import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import {
  votePoll,
  fetchPollResults,
  FETCH_POLL_RESULTS,
  VOTE_POLL,
} from 'mednet-cns/src/reducers/poll'
import { makeRequestName } from 'mednet-cns/src/reducers/request'

import { CAMPAIGN_REGISTER_MODAL } from 'mednet-util/src/constants/modal'

import { openModal } from 'mednet-cns/src/reducers/modal'

import PollForm from './pollForm'

import css from './questionPoll.scss'

const ExportPollLink = withPermissions(authItems.exportPoll)((props) => (
  <Link1 className={css.pollExportContainer}>
    <Link
      pathname={`/poll/export/${props.pollId}`}
      external
      target="_blank"
      className={css.pollExportLink}
    >
      Export to CSV
    </Link>
  </Link1>
))

class QuestionPoll extends React.Component {
  static defaultProps = {
    HeaderComponent: Header2,
  }

  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  handleSubmit = (values, actions) => {
    this.props.votePoll(this.form.current, () => {
      actions.setSubmitting(false)
    })
  }

  onSinceChange = (evt) => {
    if (!evt) {
      return
    }

    this.props.fetchPollResults(moment(evt).format('YYYY-MM-DD'))
  }

  render() {
    const {
      poll,
      className,
      isLoading,
      isError,
      hideHeaderInfo,
      hideExportToCSV,
      HeaderComponent,
      stubbed,
    } = this.props

    if (!poll) {
      return null
    }

    const containerClasses = classNames(css.container, className)

    if (isError) {
      return (
        <div className={containerClasses}>
          <Body1>Something went wrong.</Body1>
          <Subtitle3>
            Try{' '}
            <Button onClick={refresh} type="text">
              refreshing
            </Button>{' '}
            the page in a couple of minutes.
          </Subtitle3>
        </div>
      )
    }

    const {
      created,
      isMultiple,
      noExperienceOptionId,
      options,
      pollId,
      pollQuestion,
      response,
      showResults,
      showVote,
      updated,
    } = poll

    return (
      <div className={containerClasses}>
        <div className={css.metadataContainer}>
          {!hideHeaderInfo && (
            <div className={css.datesText}>
              <Subtitle3>
                Poll began: {moment(created).format('MM/DD/YYYY')}
              </Subtitle3>
              {updated && (
                <Subtitle3>
                  Poll updated: {moment(updated).format('MM/DD/YYYY')}
                </Subtitle3>
              )}
            </div>
          )}
          {showResults && !hideHeaderInfo && (
            <div className={css.sinceContainer}>
              <Subtitle3 className={css.sinceText}>
                Show results since:
              </Subtitle3>
              <DatePicker
                defaultValue={moment(updated || created).toDate()}
                onChange={this.onSinceChange}
                isLoading={isLoading}
                dayPickerProps={{
                  fromMonth: new Date(created),
                  toMonth: new Date(),
                  disabledDays: [
                    {
                      before: new Date(created),
                      after: new Date(),
                    },
                  ],
                }}
                inputProps={{}}
              />
            </div>
          )}
        </div>
        <HeaderComponent className={css.pollQuestion}>
          {pollQuestion}
        </HeaderComponent>
        <Formik
          initialValues={{
            same: 0,
            vote: options
              .filter((option) => option.isVoted)
              .map((option) => option.optionId),
            other: response || '',
            noExperience: '',
            pollComment: '',
          }}
          onSubmit={this.handleSubmit}
        >
          {(props) => (
            <PollForm
              innerRef={this.form}
              isMultiple={isMultiple}
              noExperienceOptionId={noExperienceOptionId}
              options={options}
              showResults={showResults || stubbed}
              showVote={showVote || stubbed}
              updated={updated}
              stubbed={stubbed}
              {...props}
            />
          )}
        </Formik>
        {showResults && !hideExportToCSV && <ExportPollLink pollId={pollId} />}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { pollId } = ownProps.poll
    ? ownProps.poll
    : state.question.questions[ownProps.questionId] || {}
  const poll = ownProps.poll || state.poll.polls[pollId]

  const pollResultsRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_POLL_RESULTS, pollId)
    ) || {}
  const pollVoteRequest =
    _.get(state.request.requests, makeRequestName(VOTE_POLL, pollId)) || {}

  return {
    poll,
    pollId,
    isLoading: pollResultsRequest.isLoading,
    isError: pollResultsRequest.isError || pollVoteRequest.isError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    votePoll: (pollId, questionId) => (form, callback) =>
      dispatch(votePoll(pollId, form, callback, questionId)),
    fetchPollResults: (pollId) => (since) =>
      dispatch(fetchPollResults(pollId, since)),
    openRegistrationModal: () => {
      dispatch(openModal(CAMPAIGN_REGISTER_MODAL.modalId))
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    votePoll: dispatchProps.votePoll(stateProps.pollId, ownProps.questionId),
    fetchPollResults: dispatchProps.fetchPollResults(stateProps.pollId),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(QuestionPoll)
