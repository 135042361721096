import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import algoliasearch from 'algoliasearch'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react'

import { getUserName } from 'mednet-util/src/string'
import {
  makeAssetURL,
  tagReleaseURL,
  makeDomainURL,
} from 'mednet-util/src/router'
import { transformSearchUserObject } from 'mednet-util/src/search'

import MentionHit from './mentionHit'

import css from './textEditor.scss'

const PLUGINS = [
  'autolink', // automatically creates hyperlinks when a user inputs a valid, complete URL
  'autosave',
  'autoresize',
  'link',
  'lists',
  'paste', // will filter/cleanup content pasted from Microsoft Word
  'searchreplace', // adds search/replace dialogs
  'spellchecker',
  'image',
  'table',
  'template',
  'code',
  'hr',
]

const EXTERNAL_PLUGINS = {
  mentions: makeAssetURL(
    tagReleaseURL('js/tinymce/plugins/mentions/plugin.min.js')
  ),
}

export class TextEditor extends React.Component {
  static defaultProps = {
    excludePlugins: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      loadedDependencies: false,
    }
  }

  componentDidMount() {
    Promise.all([
      import(/* webpackMode: "eager" */ 'tinymce/tinymce'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/autolink'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/autoresize'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/link'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/lists'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/paste'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/searchreplace'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/spellchecker'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/autosave'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/image'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/table'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/template'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/code'),
      import(/* webpackMode: "eager" */ 'tinymce/plugins/hr'),
    ]).then(() => {
      this.setState(
        {
          loadedDependencies: true,
        },
        this.props.onMount
      )
    })
  }

  getIndex = () => {
    const { algoliaApp, algoliaKey, algoliaNamespace } = this.props

    if (this.index) {
      return this.index
    }

    if (!algoliaApp) {
      return null
    }

    this.client = algoliasearch(algoliaApp, algoliaKey)
    this.index = this.client.initIndex(`${algoliaNamespace}_user`)
    return this.index
  }

  render() {
    const {
      name,
      className,
      innerRef,
      excludePlugins,
      algoliaApp,
      toolbar,
      templates,
      onSetup,
      height,
      ...restProps
    } = this.props
    const { loadedDependencies } = this.state

    if (!loadedDependencies || !algoliaApp) {
      return null
    }

    const containerClasses = classNames(css.container, className)

    const contentCss = [
      makeAssetURL('js/tinymce/skins/ui/oxide/content.min.css'),
      makeAssetURL(tagReleaseURL('js/tinymce/css/content.css')),
    ].join(',')

    const themeUrl = makeAssetURL(
      tagReleaseURL('js/tinymce/themes/silver/theme.min.js')
    )
    const skinUrl = makeAssetURL('js/tinymce/skins/ui/oxide')

    return (
      <div className={containerClasses}>
        <Editor
          init={{
            setup: onSetup,
            branding: false,
            browser_spellcheck: true,
            height: height === undefined ? 200 : height,
            language: 'en',
            menubar: false,
            document_base_url: makeDomainURL(),
            remove_script_host: false,
            relative_urls: false,
            plugins: _.pullAll(PLUGINS, excludePlugins),
            external_plugins: EXTERNAL_PLUGINS,
            spellchecker_languages: 'English=en',
            spellchecker_rpc_url: '//speller.yandex.net/services/tinyspell',
            statusbar: false,
            theme_url: themeUrl,
            mobile: {
              theme: 'silver',
            },
            fontsize_formats:
              '8px 10px 12px 13px 14px 16px 18px 24px 36px 48px',
            toolbar: toolbar
              ? toolbar
              : 'spellchecker | bold italic underline | removeformat | numlist bullist | link unlink',
            templates,
            default_link_target: '_blank',
            contextmenu: false,
            skin_url: skinUrl,
            content_css: contentCss,
            autosave_restore_when_empty: true,
            mentions_fetch: (query, success) => {
              this.getIndex().search(
                {
                  query: query.term,
                },
                (err, { hits }) => {
                  success(
                    hits.map((hit) => {
                      const transformedHit = transformSearchUserObject(hit)
                      const name = getUserName(transformedHit)

                      return {
                        id: hit.user_id.toString(),
                        name,
                        fullName: name,
                        html: ReactDOMServer.renderToString(
                          <MentionHit hit={transformedHit} />
                        ),
                      }
                    })
                  )
                }
              )
            },
            mentions_menu_complete: (editor, userInfo) => {
              const link = editor.getDoc().createElement('a')
              link.className = 'mention'
              link.setAttribute('href', `/user/user/view/id/${userInfo.id}`)

              link.appendChild(
                editor.getDoc().createTextNode(`@${userInfo.name}`)
              )

              return link
            },
            mentions_selector: 'a.mention',
          }}
          ref={innerRef}
          textareaName={name}
          {...restProps}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { algoliaApp, algoliaKey, algoliaNamespace } = state.user.data || {}

  return {
    algoliaApp,
    algoliaKey,
    algoliaNamespace,
  }
}

export default connect(mapStateToProps)(TextEditor)
