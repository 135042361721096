import React from 'react'

import { LoginForm } from 'components/LoginForm/loginForm'
import { LandingPageContent } from 'components/landingPageContent'

import css from './login.scss'

export const Login = () => {
  return (
    <LandingPageContent>
      <div className={css.centeredContainer}>
        <LoginForm />
      </div>
    </LandingPageContent>
  )
}
