import _ from 'lodash'
import mixpanel from 'mixpanel-browser'
import smartlookClient from 'smartlook-client'

const guardedMixpanel = (() => {
  if (process.env.MIXPANEL_TOKEN) {
    return mixpanel
  }

  return {
    track: _.noop,
    track_forms: _.noop,
    identify: _.noop,
    get_distinct_id: _.noop,
    init: _.noop,
    register: _.noop,
  }
})()

const guardedSmartlook = (() => {
  if (process.env.SMARTLOOK_API_KEY) {
    return smartlookClient
  }

  return {
    init: _.noop,
    identify: _.noop,
  }
})()

const calcMixpanelReferrerLabel = (location) => {
  if (
    !_.isEmpty(sessionStorage.getItem('request_redirected_from_notification'))
  ) {
    return {
      referrerLabel: sessionStorage.getItem(
        'request_redirected_from_notification'
      ),
      searchQuery: null,
    }
  }

  let referrerLabel = mapReferrerUrlToLabel(document.referrer)
  let searchQuery = null

  if (referrerLabel === 'theMednet Search') {
    const referrerUrl = new URL(document.referrer)
    searchQuery = referrerUrl.searchParams.get('query')
  }

  // Check that the referrer is the site itself, otherwise someone could have copied the link and sent it to another person
  if (referrerLabel === 'theMednet') {
    const currentUrlParams = new URLSearchParams(location.search)

    if (currentUrlParams.get('src') === 'popover-search') {
      referrerLabel = 'theMednet Popover Search'
      searchQuery = currentUrlParams.get('query')
    }
  }

  return {
    referrerLabel,
    searchQuery,
  }
}

const mapReferrerUrlToLabel = (referrerUrl) => {
  const referrerMap = {
    // put internal urls first, to skip checking all the list when moving to other pages in the site
    'theMednet Search': [/(the)?mednet\.(com|org)\/search\/siteSearch/i],

    theMednet: [/(the)?mednet\.(com|org)/i],

    Email: [
      // https://stackoverflow.com/questions/41026078/what-is-com-google-android-gm
      /com\.google\.android\.gm/i, // Gmail app for android

      /^https?:\/\/(z|my|web|e)?mail.?\./i,
    ],

    'Google Search': [
      /www\.google\..*/i,

      // https://carloseo.com/what-is-com-google-android-googlequicksearchbox-referral-google-analytics/#:~:text=com.google.android.googlequicksearchbox%20is%20part%20of%20the%20Google,of%20naming%20apps%20in%20Android.
      /com\.google\.android\.googlequicksearchbox/i, // Quick Google search bar for android
    ],

    'Bing Search': [/www\.bing\.com/i],

    'Yahoo Search': [/search\.yahoo\.com/i],

    Facebook: [/facebook\.com/i],

    Twitter: [/^https?:\/\/t.co/i, /twitter\.com/i],

    LinkedIn: [/linkedin\.com/i],
  }

  if (_.isEmpty(referrerUrl)) {
    return 'Direct'
  }

  for (const [label, urls] of Object.entries(referrerMap)) {
    for (const url of urls) {
      if (url.test(referrerUrl)) {
        return label
      }
    }
  }

  return 'Other'
}
export {
  guardedMixpanel as mixpanel,
  guardedSmartlook as smartlookClient,
  calcMixpanelReferrerLabel,
}
