import React, { useCallback, useEffect, useState } from 'react'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import max from 'lodash/max'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { Page } from 'pharmacy/src/display/page'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import { CenteredContent } from 'pharmacy/src/display/content'
import { Header1, Header3, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { Card } from 'pharmacy/src/display/card'
import { getRequest } from 'mednet-cns/src/api/v1'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { CoverLoader, StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'
import { ConfirmationModal } from 'pharmacy/src/display/modal'
import { RouterTabs, TabButton } from 'pharmacy/src/navigation/tabs'

import {
  FETCH_SPONSORSHIP,
  fetchSponsorship,
  setSponsoredQuestionsOrder,
  getFetchSponsorshipsStatsIsLoading,
  getFetchSponsorshipsStatsIsLoaded,
  fetchSponsorshipsStats,
  fetchSponsorshipReach,
  getFetchSponsorshipReachIsLoaded,
  getFetchSponsorshipReachIsLoading,
  getSponsorshipsStats,
  getSponsorshipReach,
  removeQuestion,
  excludeQuestionsFromSponsorship,
  includeQuestionsInSponsorship,
  fetchSponsorshipPublications,
  FETCH_SPONSORSHIP_PUBLICATIONS,
  removePublicationFromSponsorship,
} from 'mednet-cns/src/reducers/sponsorship'
import { openModal } from 'mednet-cns/src/reducers/modal'

import {
  SPONSORSHIP_CREATE_QUESTION_MODAL,
  SPONSORSHIP_ADD_QUESTION_MODAL,
  SPONSORSHIP_UPDATE_MODAL,
  SPONSORSHIP_REMOVE_QUESTION,
  SPONSORSHIP_UPLOAD_PAPER_MODAL,
  SPONSORSHIP_EDIT_PAPER_MODAL,
  SPONSORSHIP_REMOVE_PAPER,
} from 'mednet-util/src/constants/modal'
import {
  questionStatus,
  STATUS_LABEL,
} from 'mednet-util/src/constants/question'

import { EXTERNAL_LINKS } from 'constants/links'
import { PAGES } from 'constants/pages'
import { ROUTES } from 'constants/routes'

import AddQuestionModal from './modals/addQuestion'
import CreateQuestionModal from './modals/createQuestion'
import UpdateSponsorshipModal from './modals/update'
import StatsList from './statList'
import AddPaperModal from './modals/addPaper'
import EditPaperModal from './modals/editPaper'

import css from './view.scss'

function QuestionCard({
  question,
  stats,
  reach,
  provided,
  isReachLoading,
  onMoreClick,
  onRemoveClick,
  isExcluded,
  onInjectionToggle,
}) {
  const { questionId, numAnswers, poll, status, isReviewed } = question
  const title = question.question

  const cardClasses = classNames(css.questionCard, {
    [css.questionCard_denied]: status === questionStatus.DENIED,
    [css.questionCard_approved]: status === questionStatus.APPROVED,
    [css.questionCard_pending]:
      !isReviewed && status === questionStatus.PENDING,
    [css.questionCard_excluded]: isExcluded,
  })

  const pollIconClasses = classNames(css.pollIcon, {
    [css.pollIcon_missing]: !poll,
  })

  const [showMore, setShowMore] = useState(false)

  const handleClickMore = () => {
    setShowMore(!showMore)
    onMoreClick()
  }

  const DAILYDIGEST = '3'
  const NEWSLETTER = 'N'

  const dailydigestsReach = max(reach.map((r) => r.dailydigests)) || 0
  const newslettersReach = max(reach.map((r) => r.newsletters)) || 0

  const dailyDigestsStats = stats.filter((s) => String(s.type) === DAILYDIGEST)
  const newsletterStats = stats.filter((s) => String(s.type) === NEWSLETTER)

  return (
    <Card key={questionId} className={cardClasses}>
      <div className={css.cardHeader}>
        <div className={css.dragHandler} {...provided.dragHandleProps}>
          <Icon className={css.grip} icon={['far', 'grip-horizontal']} />
        </div>
      </div>
      <div className={css.questionHeaderContainer}>
        <Header3>
          <QuestionLink
            target="_blank"
            questionId={questionId}
            className={css.questionLink}
          >
            {title}
          </QuestionLink>
        </Header3>
        <div className={css.questionActions}>
          <Button
            size="small"
            type="destructive"
            icon="times"
            onClick={onRemoveClick}
            className={css.action}
          />
          <Button
            size="small"
            type="neutral"
            icon="edit"
            pathname={`/question/update/${questionId}`}
            target="_blank"
            external
            className={css.action}
          />
          <Button
            size="small"
            type={isExcluded ? 'neutral' : 'destructive'}
            icon={isExcluded ? 'syringe' : 'ban'}
            className={css.action}
            alt={isExcluded ? 'Include in injection' : 'Exclude from injection'}
            onClick={onInjectionToggle}
          />
        </div>
      </div>
      <div className={css.questionMeta}>
        <Subtitle2>
          Status:{' '}
          {status === questionStatus.PENDING && isReviewed
            ? 'Reviewed'
            : STATUS_LABEL[status]}
        </Subtitle2>
        <Subtitle2>Answers: {numAnswers}</Subtitle2>
        <Subtitle2>
          Poll:{' '}
          <Icon
            className={pollIconClasses}
            isFixedWidth
            size="sm"
            icon={poll ? 'signal-alt' : 'signal-alt-slash'}
          />
        </Subtitle2>
      </div>
      <div className={css.footer}>
        <div className={css.statsContainer}>
          <div className={css.statsPart}>
            <Subtitle2>Daily digests:</Subtitle2>
            <StatsList stats={dailyDigestsStats} />
          </div>
          <div className={css.statsPart}>
            <Subtitle2>Newsletters:</Subtitle2>
            <StatsList stats={newsletterStats} />
          </div>
          {!showMore ? null : isReachLoading ? (
            <div className={classNames(css.statsPart, css.reachPart)}>
              <StarLoader size="small" isVerticalMargin={false} />
            </div>
          ) : (
            <div className={classNames(css.statsPart, css.reachPart)}>
              <Subtitle2>Max reach:</Subtitle2>
              <ul className={css.statsList}>
                <li>
                  <Subtitle2 className={css.statElement}>
                    Daily digests:{' '}
                  </Subtitle2>
                  <Subtitle2 className={css.num}>{dailydigestsReach}</Subtitle2>
                </li>
                <li>
                  <Subtitle2 className={css.statElement}>
                    Newsletters:{' '}
                  </Subtitle2>
                  <Subtitle2 className={css.num}>{newslettersReach}</Subtitle2>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className={css.footerOpener}>
          <Link className={css.showMore} onClick={handleClickMore}>
            {!showMore ? 'show more' : 'hide'}
          </Link>
        </div>
      </div>
    </Card>
  )
}

const QuestionsList = (props) => {
  const {
    sponsorship,
    stats,
    reach,
    sponsorshipsReachIsLoaded,
    sponsorshipsReachIsLoading,
    openRemoveQuestionModal,
    handleDragEnd,
    handleInjectionToggle,
    loadReach,
  } = props

  const renderQuestions = useCallback(() => {
    const { questions, order, excluded } = sponsorship

    if (!questions || isEmpty(questions)) {
      return (
        <div>
          <Subtitle2>No questions</Subtitle2>
        </div>
      )
    }

    return (
      <div>
        {map(order, (questionId, index) => {
          const question = questions[questionId]

          return (
            <Draggable
              key={questionId}
              draggableId={questionId.toString()}
              index={index}
            >
              {(provided) => (
                <li
                  className={css.draggableElement}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <QuestionCard
                    question={question}
                    provided={provided}
                    stats={stats.filter(
                      (item) => Number(item.questionId) === Number(questionId)
                    )}
                    reach={reach.filter(
                      (item) => Number(item.questionId) === Number(questionId)
                    )}
                    isReachLoading={
                      !sponsorshipsReachIsLoaded || sponsorshipsReachIsLoading
                    }
                    onMoreClick={loadReach}
                    onRemoveClick={openRemoveQuestionModal(questionId)}
                    isExcluded={includes(excluded, question.questionId)}
                    onInjectionToggle={() =>
                      handleInjectionToggle(question.questionId)
                    }
                  />
                </li>
              )}
            </Draggable>
          )
        })}
      </div>
    )
  }, [
    stats,
    reach,
    sponsorshipsReachIsLoaded,
    sponsorshipsReachIsLoading,
    openRemoveQuestionModal,
    handleInjectionToggle,
    loadReach,
  ])

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="questions">
        {(provided) => (
          <ul
            className={css.draggable}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {renderQuestions()}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const PaperCard = ({
  publication,
  openEditPaperModal,
  openRemovePaperModal,
}) => {
  const { publicationId, title, journal, date, stats } = publication

  const cardClasses = classNames(css.publicationCard)

  return (
    <Card key={publicationId} className={cardClasses}>
      <div className={css.publicationHeaderContainer}>
        <Header3>
          <Link
            target="_blank"
            className={css.publicationLink}
            pathname={`/publication/moderatorDownload/${publicationId}`}
          >
            {title}
          </Link>
        </Header3>
        <div className={css.publicationActions}>
          <Button
            size="small"
            type="destructive"
            icon="times"
            onClick={openRemovePaperModal(publicationId)}
            className={css.action}
          />
          <Button
            size="small"
            type="neutral"
            icon="edit"
            onClick={() => openEditPaperModal(publication)}
            className={css.action}
          />
        </div>
      </div>
      <div className={css.publicationDetail}>
        <Subtitle2>
          {!isEmpty(journal) && !isEmpty(date)
            ? `${journal}, ${date}`
            : isEmpty(date)
            ? journal
            : date}
        </Subtitle2>
      </div>
      <div className={css.footer}>
        <div className={css.statsContainer}>
          <div className={css.publicationStatsPart}>
            <Subtitle2>Downloads:</Subtitle2>
            <ul className={css.statsList}>
              <li>
                <Subtitle2 className={css.statElement}>
                  Total:{' '}
                  <div className={css.num}>
                    {stats.pclick_downloads + stats.cclick_downloads}
                  </div>
                </Subtitle2>
              </li>
              <li>
                <Subtitle2 className={css.statElement}>
                  From questions citations:{' '}
                  <div className={css.num}>{stats.cclick_downloads}</div>
                </Subtitle2>
              </li>
              <li>
                <Subtitle2 className={css.statElement}>
                  From publication view page and emails:{' '}
                  <div className={css.num}>{stats.pclick_downloads}</div>
                </Subtitle2>
              </li>
            </ul>
          </div>
          <div className={css.publicationStatsPart}>
            <Subtitle2>Clicks:</Subtitle2>
            <ul className={css.statsList}>
              <li>
                <Subtitle2 className={css.statElement}>
                  Total:{' '}
                  <div className={css.num}>
                    {stats.pclick_clicks + stats.cclick_clicks}
                  </div>
                </Subtitle2>
              </li>
              <li>
                <Subtitle2 className={css.statElement}>
                  From questions citations:{' '}
                  <div className={css.num}>{stats.cclick_clicks}</div>
                </Subtitle2>
              </li>
              <li>
                <Subtitle2 className={css.statElement}>
                  From publication view page:{' '}
                  <div className={css.num}>{stats.pclick_clicks}</div>
                </Subtitle2>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Card>
  )
}

const PapersList = (props) => {
  const {
    sponsorshipPublications: publications,
    sponsorshipPublicationsIsLoaded,
    sponsorshipPublicationsIsLoading,
    fetchSponsorshipPublications,
    openEditPaperModal,
    openRemovePaperModal,
  } = props

  useEffect(() => {
    if (!sponsorshipPublicationsIsLoaded && !sponsorshipPublicationsIsLoading) {
      fetchSponsorshipPublications()
    }
  }, [])

  if (!sponsorshipPublicationsIsLoaded || sponsorshipPublicationsIsLoading) {
    return (
      <div className={css.loaderContainer}>
        <StarLoader />
      </div>
    )
  }

  if (!publications || isEmpty(publications)) {
    return <Subtitle2>No papers</Subtitle2>
  }

  return (
    <div>
      {publications.map((publication) => (
        <PaperCard
          key={publication.publicationId}
          publication={publication}
          openEditPaperModal={openEditPaperModal}
          openRemovePaperModal={openRemovePaperModal}
        />
      ))}
    </div>
  )
}

class ViewSponsorshipPage extends React.Component {
  state = {
    loading: false,
  }

  componentDidMount() {
    const {
      sponsorshipsStatsIsLoaded,
      fetchSponsorshipsStats,
      fetchSponsorship,
    } = this.props

    if (!sponsorshipsStatsIsLoaded) {
      fetchSponsorshipsStats()
    }

    fetchSponsorship()
  }

  handleRemoveQuestion = ({ closeModal, questionId }) => {
    const { removeQuestion } = this.props

    removeQuestion(questionId)
    closeModal()
  }

  handleRemovePaper = ({ closeModal, publicationId }) => {
    const { removePaper } = this.props

    removePaper(publicationId, (res) => {
      res.success && closeModal()
    })
  }

  setNewQuestionPosition = (questionId, destinationIndex) => {
    const { sponsorship, setSponsoredQuestionsOrder } = this.props
    const { order } = sponsorship

    const newOrder = order.filter(
      (orderQuestionId) => orderQuestionId !== questionId
    )
    newOrder.splice(destinationIndex, 0, questionId)

    this.setState({ loading: true })
    setSponsoredQuestionsOrder(newOrder, () =>
      this.setState({ loading: false })
    )
  }

  handleInjectionToggle = (questionId) => {
    const {
      sponsorship,
      excludeQuestionsFromSponsorship,
      includeQuestionsInSponsorship,
    } = this.props

    this.setState({ loading: true })
    const resetState = () => this.setState({ loading: false })
    if (!includes(sponsorship.excluded, questionId)) {
      excludeQuestionsFromSponsorship([questionId], resetState)
    } else {
      includeQuestionsInSponsorship([questionId], resetState)
    }
  }

  handleDragEnd = (result) => {
    const questionId = Number(result.draggableId)
    const destinationIndex = get(result, 'destination.index', null)
    const sourceIndex = get(result, 'source.index', null)

    if (
      destinationIndex === sourceIndex ||
      destinationIndex === null ||
      sourceIndex === null
    )
      return

    this.setNewQuestionPosition(questionId, destinationIndex)
  }

  loadReach = () => {
    const {
      fetchSponsorshipReach,
      sponsorshipsReachIsLoaded,
      sponsorshipsReachIsLoading,
    } = this.props

    if (!sponsorshipsReachIsLoaded && !sponsorshipsReachIsLoading) {
      fetchSponsorshipReach()
    }
  }

  renderButtons = () => {
    const {
      openAddPaperModal,
      openAddQuestionModal,
      openCreateQuestionModal,
      openUpdateModal,
      sponsorship,
    } = this.props
    const { sponsorshipId } = sponsorship

    return (
      <>
        <Subtitle2>
          <Button onClick={openUpdateModal} icon="edit" type="text">
            Edit Sponsorship
          </Button>
        </Subtitle2>
        <Subtitle2>
          <Button icon="search-plus" type="text" onClick={openAddQuestionModal}>
            Add Existing Question
          </Button>
        </Subtitle2>
        <Subtitle2>
          <Button icon="plus" type="text" onClick={openCreateQuestionModal}>
            Create New Question
          </Button>
        </Subtitle2>
        <Subtitle2>
          <Button
            route={ROUTES[PAGES.SPONSORSHIP_MANAGE_EMAILS]}
            routeParams={{ sponsorshipId }}
            icon="chalkboard-teacher"
            type="text"
          >
            Manage Expert Emails
          </Button>
        </Subtitle2>
        <Subtitle2>
          <Button
            pathname="/campaign/new"
            search={{ sponsorshipId }}
            icon="envelope"
            type="text"
          >
            Add new campaign
          </Button>
        </Subtitle2>
        <Subtitle2>
          <Button
            route={ROUTES[PAGES.SPONSORSHIP_INJECTED_CONTENT_DASHBOARD]}
            search={{ sponsorshipId }}
            icon="table"
            type="text"
          >
            Stats
          </Button>
        </Subtitle2>
        <Subtitle2>
          <Button onClick={openAddPaperModal} icon="file-plus" type="text">
            Add Paper
          </Button>
        </Subtitle2>
      </>
    )
  }

  render() {
    const {
      sponsorship,
      sponsorshipIsLoaded,
      sponsorshipsStatsIsLoading,
      sponosrshipIsLoading,
    } = this.props

    const { loading } = this.state

    if (
      !sponsorshipIsLoaded ||
      sponsorshipsStatsIsLoading ||
      sponosrshipIsLoading
    ) {
      return <StarLoader />
    }

    const { description, sponsorshipId } = sponsorship

    const tabs = [
      {
        header: <TabButton title="Questions" />,
        component: (
          <QuestionsList
            handleDragEnd={this.handleDragEnd}
            handleInjectionToggle={this.handleInjectionToggle}
            loadReach={this.loadReach}
            {...this.props}
          />
        ),
      },
      {
        header: <TabButton title="Papers" />,
        component: <PapersList {...this.props} />,
      },
    ]

    return (
      <Page>
        {loading && <CoverLoader />}
        <CenteredContent size="large">
          <Breadcrumbs
            links={[
              EXTERNAL_LINKS.moderatorPage,
              ROUTES[PAGES.SPONSORSHIP_DASHBOARD],
              {
                label: description,
              },
            ]}
          />
          <div className={css.container}>
            <div className={css.leftContent}>{this.renderButtons()}</div>
            <div>
              <div className={css.header}>
                <Header1>{description}</Header1>
              </div>
              <RouterTabs tabs={tabs} />
            </div>
          </div>
        </CenteredContent>
        <AddPaperModal sponsorshipId={sponsorshipId} />
        <EditPaperModal sponsorshipId={sponsorshipId} />
        <AddQuestionModal sponsorshipId={sponsorshipId} />
        <CreateQuestionModal sponsorship={sponsorship} />
        <UpdateSponsorshipModal sponsorshipId={sponsorshipId} />
        <ConfirmationModal
          closeText="Cancel"
          closeType="neutral"
          header="Are you sure you want to remove this question?"
          modalId={SPONSORSHIP_REMOVE_QUESTION.modalId}
          onSubmit={this.handleRemoveQuestion}
          submitText="Delete"
          submitType="destructive"
        />
        <ConfirmationModal
          closeText="Cancel"
          closeType="neutral"
          header="Are you sure you want to remove this paper?"
          modalId={SPONSORSHIP_REMOVE_PAPER.modalId}
          onSubmit={this.handleRemovePaper}
          submitText="Delete"
          submitType="destructive"
        />
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sponsorshipId } = ownProps.match.params
  const sponsorshipRequest = getRequest(state, FETCH_SPONSORSHIP, sponsorshipId)
  const sponsorshipPublicationsRequest = getRequest(
    state,
    FETCH_SPONSORSHIP_PUBLICATIONS,
    sponsorshipId
  )

  const sponsorshipPublicationsStats =
    state.sponsorship.publications[sponsorshipId] || {}

  const sponsorshipPublications = Object.keys(sponsorshipPublicationsStats).map(
    (publicationId) => ({
      ...(state.publication.publications[publicationId] || {}),
      stats: sponsorshipPublicationsStats[publicationId],
    })
  )

  sponsorshipPublications.sort((a, b) => b.publicationId - a.publicationId)

  const sponsorshipsStatsIsLoaded = getFetchSponsorshipsStatsIsLoaded(state)
  const sponsorshipsStatsIsLoading = getFetchSponsorshipsStatsIsLoading(state)

  const sponsorshipsReachIsLoaded = getFetchSponsorshipReachIsLoaded(
    state,
    sponsorshipId
  )
  const sponsorshipsReachIsLoading = getFetchSponsorshipReachIsLoading(
    state,
    sponsorshipId
  )

  const stats = getSponsorshipsStats(state, sponsorshipId)
  const reach = getSponsorshipReach(state, sponsorshipId)

  return {
    sponsorship: state.sponsorship.sponsorships[sponsorshipId],
    sponsorshipPublications,
    stats,
    reach,
    sponsorshipIsLoaded: Boolean(sponsorshipRequest.isLoaded),
    sponosrshipIsLoading: Boolean(sponsorshipRequest.isLoading),
    sponsorshipsStatsIsLoaded,
    sponsorshipsStatsIsLoading,
    sponsorshipsReachIsLoaded,
    sponsorshipsReachIsLoading,
    sponsorshipPublicationsIsLoaded: Boolean(
      sponsorshipPublicationsRequest.isLoaded
    ),
    sponsorshipPublicationsIsLoading: Boolean(
      sponsorshipPublicationsRequest.isLoading
    ),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps.match.params

  return {
    fetchSponsorship: () => dispatch(fetchSponsorship(sponsorshipId)),
    openAddQuestionModal: () =>
      dispatch(openModal(SPONSORSHIP_ADD_QUESTION_MODAL.modalId)),
    openCreateQuestionModal: () =>
      dispatch(openModal(SPONSORSHIP_CREATE_QUESTION_MODAL.modalId)),
    openUpdateModal: () =>
      dispatch(openModal(SPONSORSHIP_UPDATE_MODAL.modalId)),
    setSponsoredQuestionsOrder: (order, callback) =>
      dispatch(setSponsoredQuestionsOrder(sponsorshipId, order, callback)),
    fetchSponsorshipsStats: () => dispatch(fetchSponsorshipsStats()),
    fetchSponsorshipReach: () => dispatch(fetchSponsorshipReach(sponsorshipId)),
    removeQuestion: (questionId) =>
      dispatch(removeQuestion(sponsorshipId, questionId)),
    openRemoveQuestionModal: (questionId) => () =>
      dispatch(openModal(SPONSORSHIP_REMOVE_QUESTION.modalId, { questionId })),
    excludeQuestionsFromSponsorship: (questionIds, callback) =>
      dispatch(
        excludeQuestionsFromSponsorship(sponsorshipId, questionIds, callback)
      ),
    includeQuestionsInSponsorship: (questionIds, callback) =>
      dispatch(
        includeQuestionsInSponsorship(sponsorshipId, questionIds, callback)
      ),
    fetchSponsorshipPublications: () =>
      dispatch(fetchSponsorshipPublications(sponsorshipId)),
    openAddPaperModal: () =>
      dispatch(openModal(SPONSORSHIP_UPLOAD_PAPER_MODAL.modalId)),
    openEditPaperModal: (publication) =>
      dispatch(
        openModal(SPONSORSHIP_EDIT_PAPER_MODAL.modalId, { publication })
      ),
    openRemovePaperModal: (publicationId) => () =>
      dispatch(openModal(SPONSORSHIP_REMOVE_PAPER.modalId, { publicationId })),
    removePaper: (publicationId, callback) =>
      dispatch(
        removePublicationFromSponsorship(publicationId, sponsorshipId, callback)
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSponsorshipPage)
