import _ from 'lodash'
import { takeLatest, takeEvery, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import {
  makePOSTHeaders,
  makePOSTHeadersFromParams,
} from 'mednet-cns/src/api/v1'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

import { makeRequestName } from './request'
import { UPLOAD_SPONSORED_PAPER } from './publication'

export const FETCH_SPONSORSHIPS = 'sponsorship/FETCH_SPONSORSHIPS'
export const FETCH_SPONSORSHIPS_STATS = 'sponsorship/FETCH_SPONSORSHIPS_STATS'
export const FETCH_SPONSORSHIP_REACH = 'sponsorship/FETCH_SPONSORSHIP_REACH'
export const FETCH_SPONSORSHIP = 'sponsorship/FETCH_SPONSORSHIP'
export const FETCH_ACTIVE_SPONSORSHIPS = 'sponsorship/FETCH_ACTIVE_SPONSORSHIPS'
export const CREATE_SPONSORSHIP = 'sponsorship/CREATE_SPONSORSHIP'
export const UPDATE_SPONSORSHIP = 'sponsorship/UPDATE_SPONSORSHIP'
export const FETCH_CAMPAIGNS = 'sponsorship/FETCH_CAMPAIGNS'
export const SAVE_CAMPAIGNS = 'sponsorship/SAVE_CAMPAIGNS'
export const VALIDATE_CAMPAIGN_EXISTS = 'sponsorship/VALIDATE_CAMPAIGN_EXISTS'
export const ADD_QUESTION = 'sponsorship/ADD_QUESTION'
export const REMOVE_QUESTION = 'sponsorship/REMOVE_QUESTION'
export const CREATE_QUESTION = 'sponsorship/CREATE_QUESTION'
export const FETCH_EXPERTS = 'sponsorship/FETCH_EXPERTS'
export const ASSIGN_QUESTION = 'sponsorship/ASSIGN_QUESTION'
export const UNASSIGN_QUESTION = 'sponsorship/UNASSIGN_QUESTION'
export const SEND_EMAIL = 'sponsorship/SEND_EMAIL'
export const FETCH_EXPERT_QUESTIONS = 'sponsorship/FETCH_EXPERT_QUESTIONS'
export const ANSWER_QUESTIONS = 'sponsorship/ANSWER_QUESTIONS'
export const FETCH_INJECTION_STATS = 'sponsorship/FETCH_INJECTION_STATS'
export const SET_SPONSOR_QUESTIONS_ORDER =
  'sponsorship/SET_SPONSOR_QUESTIONS_ORDER'
export const FETCH_NOTIFICATION_IDS = 'sponsorship/FETCH_NOTIFICATION_IDS'
export const FETCH_NOTIFICATIONS_VOLUME =
  'sponsorship/FETCH_NOTIFICATIONS_VOLUME'
export const EXCLUDE_QUESTIONS = 'sponsorship/EXCLUDE_QUESTIONS'
export const INCLUDE_QUESTIONS = 'sponsorship/INCLUDE_QUESTIONS'
export const FETCH_INJECTED_QUESTIONS = 'sponsorship/FETCH_INJECTED_QUESTIONS'
export const ADD_INJECTED_QUESTION = 'sponsorship/ADD_INJECTED_QUESTION'
export const REMOVE_INJECTED_QUESTION = 'sponsorship/REMOVE_INJECTED_QUESTION'
export const FETCH_SPONSORSHIP_PUBLICATIONS =
  'sponsorship/FETCH_SPONSORSHIP_PUBLICATIONS'
export const ADD_PUBLICATION_TO_SPONSORSHIP =
  'publication/ADD_PUBLICATION_TO_SPONSORSHIP'
export const REMOVE_PUBLICATION_FROM_SPONSORSHIP =
  'publication/REMOVE_PUBLICATION_FROM_SPONSORSHIP'

export function fetchSponsorships() {
  return {
    type: FETCH_SPONSORSHIPS,
  }
}

export function fetchSponsorshipsStats() {
  return {
    type: FETCH_SPONSORSHIPS_STATS,
  }
}

export function fetchInjectedQuestions() {
  return {
    type: FETCH_INJECTED_QUESTIONS,
  }
}

export function removeInjectedQuestion(injectionId, callback) {
  return {
    type: REMOVE_INJECTED_QUESTION,
    injectionId,
    callback,
  }
}

export function fetchSponsorshipReach(sponsorshipId) {
  return {
    type: FETCH_SPONSORSHIP_REACH,
    requestId: sponsorshipId,
    sponsorshipId,
  }
}

export function fetchSponsorship(sponsorshipId, callback) {
  return {
    type: FETCH_SPONSORSHIP,
    requestId: sponsorshipId,
    sponsorshipId,
    callback,
  }
}

export function fetchInjectionStats(
  from,
  to,
  {
    specialtyId,
    subspecialtiesIds,
    usersIds,
    viewedTopicsIds,
    followedTopicsIds,
  } = {}
) {
  return {
    type: FETCH_INJECTION_STATS,
    requestId: `${from} - ${to}`,
    from,
    to,
    specialtyId,
    subspecialtiesIds,
    usersIds,
    viewedTopicsIds,
    followedTopicsIds,
  }
}

export function fetchActiveSponsorships(from, to) {
  return {
    type: FETCH_ACTIVE_SPONSORSHIPS,
    requestId: `${from} - ${to}`,
    from,
    to,
  }
}

export function fetchNotificaitonsVolume(
  from,
  to,
  {
    specialtyId,
    subspecialtiesIds,
    usersIds,
    viewedTopicsIds,
    followedTopicsIds,
  } = {}
) {
  return {
    type: FETCH_NOTIFICATIONS_VOLUME,
    requestId: `${from} - ${to}`,
    from,
    to,
    specialtyId,
    subspecialtiesIds,
    usersIds,
    viewedTopicsIds,
    followedTopicsIds,
  }
}

export function createSponsorship(formData, callback) {
  return {
    type: CREATE_SPONSORSHIP,
    callback,
    formData,
  }
}

export function updateSponsorship(sponsorshipId, formData, callback) {
  return {
    type: UPDATE_SPONSORSHIP,
    callback,
    formData,
    sponsorshipId,
  }
}

export function fetchCampaigns(sponsorshipId) {
  return {
    type: FETCH_CAMPAIGNS,
    requestId: sponsorshipId,
    sponsorshipId,
  }
}

export function saveCampaigns(sponsorshipId, formData, callback) {
  return {
    type: SAVE_CAMPAIGNS,
    requestId: sponsorshipId,
    sponsorshipId,
    formData,
    callback,
  }
}

export function validateCampaignExists(campaignId) {
  return {
    type: VALIDATE_CAMPAIGN_EXISTS,
    requestId: campaignId,
    campaignId,
  }
}

export function addQuestion(sponsorshipId, formData, callback) {
  return {
    type: ADD_QUESTION,
    requestId: sponsorshipId,
    sponsorshipId,
    formData,
    callback,
  }
}

export function removeQuestion(sponsorshipId, questionId, callback) {
  return {
    type: REMOVE_QUESTION,
    requestId: sponsorshipId,
    sponsorshipId,
    questionId,
    callback,
  }
}

export function assignQuestion(sponsorshipId, userId, questionId, callback) {
  return {
    type: ASSIGN_QUESTION,
    requestId: sponsorshipId,
    sponsorshipId,
    userId,
    questionId,
    callback,
  }
}

export function unassignQuestion(sponsorshipId, userId, questionId, callback) {
  return {
    type: UNASSIGN_QUESTION,
    requestId: sponsorshipId,
    sponsorshipId,
    userId,
    questionId,
    callback,
  }
}

export function createQuestion(sponsorshipId, formData, callback) {
  return {
    type: CREATE_QUESTION,
    requestId: sponsorshipId,
    sponsorshipId,
    formData,
    callback,
  }
}

export function fetchExperts(sponsorshipId, callback) {
  return {
    type: FETCH_EXPERTS,
    requestId: sponsorshipId,
    sponsorshipId,
    callback,
  }
}

export function sendEmail(sponsorshipId, userId, formData, callback) {
  return {
    type: SEND_EMAIL,
    requestId: sponsorshipId,
    sponsorshipId,
    userId,
    formData,
    callback,
  }
}

export function fetchExpertQuestions(sponsorshipId, userId) {
  return {
    type: FETCH_EXPERT_QUESTIONS,
    sponsorshipId,
    userId,
  }
}

export function answerQuestions(sponsorshipId, userId, formData, callback) {
  return {
    type: ANSWER_QUESTIONS,
    sponsorshipId,
    userId,
    formData,
    callback,
  }
}

export function setSponsoredQuestionsOrder(sponsorshipId, order, callback) {
  const formData = new FormData()
  formData.append('order', order)
  return {
    type: SET_SPONSOR_QUESTIONS_ORDER,
    sponsorshipId,
    order,
    formData,
    callback,
  }
}

export function excludeQuestionsFromSponsorship(
  sponsorshipId,
  questionIds,
  callback
) {
  const formData = new FormData()
  formData.append('questionIds', questionIds)
  return {
    type: EXCLUDE_QUESTIONS,
    sponsorshipId,
    questionIds,
    formData,
    callback,
  }
}

export function includeQuestionsInSponsorship(
  sponsorshipId,
  questionIds,
  callback
) {
  const formData = new FormData()
  formData.append('questionIds', questionIds)
  return {
    type: INCLUDE_QUESTIONS,
    sponsorshipId,
    questionIds,
    formData,
    callback,
  }
}

export function addQuestionInjection(
  { sponsorshipId, specialtyId, questionId, updateId, injectionDate },
  callback
) {
  const formData = new FormData()
  formData.append('sponsorshipId', sponsorshipId)
  formData.append('specialtyId', specialtyId)
  formData.append('questionId', questionId)
  formData.append('updateId', updateId)
  formData.append('injectionDate', injectionDate)
  return {
    type: ADD_INJECTED_QUESTION,
    formData,
    callback,
  }
}

export function fetchNotificationIds(
  date,
  notificationType,
  sponsorshipId,
  questionId,
  updateId,
  specialtyId,
  subspecialtiesIds,
  viewedTopicsIds,
  followedTopicsIds,
  usersIds
) {
  return {
    type: FETCH_NOTIFICATION_IDS,
    date,
    sponsorshipId,
    questionId,
    updateId,
    notificationType,
    specialtyId,
    subspecialtiesIds,
    viewedTopicsIds,
    followedTopicsIds,
    usersIds,
  }
}

export function fetchSponsorshipPublications(id) {
  return {
    type: FETCH_SPONSORSHIP_PUBLICATIONS,
    requestId: id,
  }
}

export function addPublicationToSponsorship(formData, callback) {
  return {
    type: ADD_PUBLICATION_TO_SPONSORSHIP,
    formData,
    callback,
  }
}

export function removePublicationFromSponsorship(
  publicationId,
  sponsorshipId,
  callback
) {
  return {
    type: REMOVE_PUBLICATION_FROM_SPONSORSHIP,
    publicationId,
    sponsorshipId,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_SPONSORSHIPS,
    makeURL(`sponsorship/getSponsorshipsJSON`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_SPONSORSHIPS_STATS,
    makeURL(`sponsorship/getAllSponsorshipsStatsJSON`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_INJECTED_QUESTIONS,
    makeURL(`sponsorship/getInjectedQuestionsJSON`)
  )

  yield makeFetchEffect(
    takeLatest,
    ADD_INJECTED_QUESTION,
    () => makeURL('sponsorship/forceInjectionJSON/'),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, REMOVE_INJECTED_QUESTION, (action) =>
    makeURL(`sponsorship/removeForcedInjectionJSON/${action.injectionId}`)
  )

  yield makeFetchEffect(takeEvery, FETCH_SPONSORSHIP_REACH, (action) =>
    makeURL(`sponsorship/getSponsorshipReachJSON/${action.sponsorshipId}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_SPONSORSHIP, (action) =>
    makeURL(`sponsorship/getSponsorshipJSON/${action.sponsorshipId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_SPONSORSHIP,
    makeURL('sponsorship/createJSON'),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    UPDATE_SPONSORSHIP,
    (action) => makeURL(`sponsorship/updateJSON/${action.sponsorshipId}`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, FETCH_CAMPAIGNS, (action) =>
    makeURL(`sponsorship/getCampaignTemplatesJSON/${action.sponsorshipId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    SAVE_CAMPAIGNS,
    (action) =>
      makeURL(`sponsorship/getCampaignTemplatesJSON/${action.sponsorshipId}`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, VALIDATE_CAMPAIGN_EXISTS, (action) =>
    makeURL(`campaign/validateCampaignExists/id/${action.campaignId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    ADD_QUESTION,
    (action) => makeURL(`sponsorship/addQuestionJSON/${action.sponsorshipId}`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    REMOVE_QUESTION,
    (action) =>
      makeURL(`sponsorship/removeQuestionJSON/${action.sponsorshipId}`),
    (action) => {
      const formData = new FormData()
      formData.append('questionId', JSON.stringify(action.questionId))
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_QUESTION,
    (action) =>
      makeURL(`sponsorship/createQuestionJSON/${action.sponsorshipId}`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    ASSIGN_QUESTION,
    (action) =>
      makeURL(`sponsorship/assignQuestionJSON/${action.sponsorshipId}`),
    (action) =>
      makePOSTHeadersFromParams({
        userId: action.userId,
        questionId: action.questionId,
      })
  )

  yield makeFetchEffect(
    takeLatest,
    UNASSIGN_QUESTION,
    (action) =>
      makeURL(`sponsorship/unassignQuestionJSON/${action.sponsorshipId}`),
    (action) =>
      makePOSTHeadersFromParams({
        userId: action.userId,
        questionId: action.questionId,
      })
  )

  yield makeFetchEffect(takeLatest, FETCH_EXPERTS, (action) =>
    makeURL(`sponsorship/getExpertsJSON/${action.sponsorshipId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    SEND_EMAIL,
    (action) =>
      makeURL(`sponsorship/expertEmailJSON/${action.sponsorshipId}`, {
        userId: action.userId,
      }),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, FETCH_EXPERT_QUESTIONS, (action) =>
    makeURL(`sponsorship/getQuestionsJSON/${action.sponsorshipId}`, {
      userId: action.userId,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    ANSWER_QUESTIONS,
    (action) =>
      makeURL(`sponsorship/answerQuestionsJSON/${action.sponsorshipId}`, {
        userId: action.userId,
      }),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_INJECTION_STATS,
    ({
      from,
      to,
      specialtyId,
      subspecialtiesIds,
      usersIds,
      followedTopicsIds,
      viewedTopicsIds,
    }) =>
      makeURL(`sponsorship/allInjectedDataStatsJSON/`, {
        from,
        to,
        specialtyId,
        subspecialtiesIds,
        usersIds,
        followedTopicsIds,
        viewedTopicsIds,
      })
  )

  yield makeFetchEffect(takeLatest, FETCH_ACTIVE_SPONSORSHIPS, ({ from, to }) =>
    makeURL(`sponsorship/getActiveSponsorships/`, {
      from,
      to,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_NOTIFICATIONS_VOLUME,
    ({
      from,
      to,
      specialtyId,
      subspecialtiesIds,
      usersIds,
      followedTopicsIds,
      viewedTopicsIds,
    }) =>
      makeURL(`sponsorship/getNotificationVolumeJSON/`, {
        from,
        to,
        specialtyId,
        subspecialtiesIds,
        usersIds,
        followedTopicsIds,
        viewedTopicsIds,
      })
  )

  yield makeFetchEffect(
    takeLatest,
    SET_SPONSOR_QUESTIONS_ORDER,
    (action) => makeURL(`sponsorship/setQuestionOrder/${action.sponsorshipId}`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    EXCLUDE_QUESTIONS,
    (action) =>
      makeURL(`sponsorship/excludeQuestionJSON/${action.sponsorshipId}`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    INCLUDE_QUESTIONS,
    (action) =>
      makeURL(`sponsorship/includeQuestionJSON/${action.sponsorshipId}`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_NOTIFICATION_IDS,
    ({
      sponsorshipId,
      date,
      questionId,
      updateId,
      notificationType,
      specialtyId,
      subspecialtiesIds,
      viewedTopicsIds,
      followedTopicsIds,
      usersIds,
    }) =>
      makeURL(`sponsorship/notifications/${sponsorshipId}`, {
        date,
        questionId,
        updateId,
        notificationType,
        specialtyId,
        subspecialtiesIds,
        viewedTopicsIds,
        followedTopicsIds,
        usersIds,
      })
  )

  yield makeFetchEffect(takeLatest, FETCH_SPONSORSHIP_PUBLICATIONS, (action) =>
    makeURL(`sponsorship/getSponsorshipPublicationsJSON/${action.requestId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    ADD_PUBLICATION_TO_SPONSORSHIP,
    makeURL(`sponsorship/AddPublicationToSponsorshipJSON`),
    (action) => makePOSTHeadersFromParams(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    REMOVE_PUBLICATION_FROM_SPONSORSHIP,
    makeURL(`sponsorship/removePublicationFromSponsorshipJSON`),
    (action) =>
      makePOSTHeadersFromParams({
        publicationId: action.publicationId,
        sponsorshipId: action.sponsorshipId,
      })
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  sponsorships: {},
  questions: {},
  injectionStats: {},
  notifications: [],
  volume: {},
  stats: {}, // Stats from release date to now/enddate
  reach: {}, // Reach of each sponsorship per question
  publications: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SPONSOR_QUESTIONS_ORDER: {
      return {
        ...state,
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...state.sponsorships[action.sponsorshipId],
            order: action.order,
          },
        },
      }
    }

    case receiveAction(UPDATE_SPONSORSHIP): {
      const { sponsorships } = action.response

      return receiveReducer(state, action, () => ({
        sponsorships: _.merge(
          state.sponsorships,
          _.keyBy(sponsorships, 'sponsorshipId')
        ),
      }))
    }

    case receiveAction(CREATE_SPONSORSHIP):
    case receiveAction(FETCH_SPONSORSHIPS): {
      const { sponsorships } = action.response

      return receiveReducer(state, action, () => ({
        sponsorships: _.merge(
          {},
          state.sponsorships,
          _.keyBy(sponsorships, 'sponsorshipId')
        ),
        orderedSponsorships: _.map(sponsorships, 'sponsorshipId'),
      }))
    }

    case receiveAction(FETCH_SPONSORSHIPS_STATS): {
      const { stats } = action.response

      return receiveReducer(state, action, () => ({
        stats: {
          ...state.stats,
          ..._.reduce(
            stats,
            (obj, stat) => {
              obj[stat.sponsorshipId] = obj[stat.sponsorshipId] || []
              obj[stat.sponsorshipId].push(stat)
              return obj
            },
            {}
          ),
        },
      }))
    }

    case receiveAction(ADD_INJECTED_QUESTION):
    case receiveAction(REMOVE_INJECTED_QUESTION):
    case receiveAction(FETCH_INJECTED_QUESTIONS): {
      const { injectedQuestions } = action.response

      return receiveReducer(state, action, () => ({
        injectedQuestions,
      }))
    }

    case receiveAction(FETCH_SPONSORSHIP_REACH): {
      const { reach } = action.response

      return receiveReducer(state, action, () => ({
        reach: {
          ...state.reach,
          ...reach,
        },
      }))
    }

    case receiveAction(FETCH_SPONSORSHIP):
    case receiveAction(INCLUDE_QUESTIONS):
    case receiveAction(EXCLUDE_QUESTIONS):
    case receiveAction(SET_SPONSOR_QUESTIONS_ORDER): {
      const { sponsorship } = action.response

      return receiveReducer(state, action, () => ({
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...state.sponsorships[action.sponsorshipId],
            ...sponsorship,
          },
        },
      }))
    }

    case receiveAction(FETCH_SPONSORSHIP_PUBLICATIONS): {
      const { publicationsStats } = action.response

      return receiveReducer(state, action, () => ({
        publications: {
          ...state.publications,
          [action.requestId]: publicationsStats,
        },
      }))
    }

    case receiveAction(FETCH_EXPERT_QUESTIONS): {
      const { sponsorship, questions } = action.response

      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          [action.userId]: questions,
        },
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...state.sponsorships[action.sponsorshipId],
            ...sponsorship,
          },
        },
      }))
    }

    case receiveAction(FETCH_CAMPAIGNS): {
      return receiveReducer(state, action, () => ({
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...state.sponsorships[action.sponsorshipId],
            campaigns: action.response,
          },
        },
      }))
    }

    case receiveAction(VALIDATE_CAMPAIGN_EXISTS): {
      const validatedCampaigns = state.validatedCampains
        ? state.validatedCampains
        : []

      if (action.response && !validatedCampaigns.includes(action.campaignId)) {
        return receiveReducer(state, action, () => ({
          validatedCampaigns: [...validatedCampaigns, action.campaignId],
        }))
      }

      return state
    }

    case receiveAction(FETCH_INJECTION_STATS): {
      return receiveReducer(state, action, () => ({
        injectionStats: action.response,
      }))
    }

    case receiveAction(FETCH_ACTIVE_SPONSORSHIPS): {
      return receiveReducer(state, action, () => ({
        sponsorships: {
          ...state.sponsorships,
          ...action.response,
        },
      }))
    }

    case receiveAction(FETCH_NOTIFICATIONS_VOLUME): {
      return receiveReducer(state, action, () => ({
        volume: {
          ...state.volume,
          ...action.response,
        },
      }))
    }

    case receiveAction(REMOVE_QUESTION): {
      const sponsorship = state.sponsorships[action.sponsorshipId]

      if (action.response.success) {
        delete sponsorship.questions[action.questionId]
      }

      return receiveReducer(state, action, () => ({
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...sponsorship,
            questions: {
              ...sponsorship.questions,
            },
            order: action.response.success
              ? action.response.order
              : sponsorship.order,
          },
        },
      }))
    }

    case receiveAction(ADD_QUESTION):
    case receiveAction(CREATE_QUESTION): {
      const sponsorship = state.sponsorships[action.sponsorshipId]

      return receiveReducer(state, action, () => ({
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...sponsorship,
            questions: action.response.success
              ? action.response.questions
              : sponsorship.questions,
            order: action.response.success
              ? action.response.order
              : sponsorship.order,
          },
        },
      }))
    }

    case ASSIGN_QUESTION:
    case UNASSIGN_QUESTION: {
      const sponsorship = state.sponsorships[action.sponsorshipId]
      const expertIndex = sponsorship.experts.findIndex(
        (expert) => expert.userId === action.userId
      )
      const expert = sponsorship.experts[expertIndex]
      const loadedQuestions = expert.questions.filter(
        (question) => question !== parseInt(action.questionId)
      )

      sponsorship.experts[expertIndex] = {
        ...expert,
        questions: loadedQuestions,
        loadingQuestion: action.questionId,
      }

      return {
        ...state,
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...sponsorship,
          },
        },
      }
    }

    case receiveAction(SEND_EMAIL):
    case receiveAction(ASSIGN_QUESTION):
    case receiveAction(UNASSIGN_QUESTION):
    case receiveAction(FETCH_EXPERTS): {
      const sponsorship = state.sponsorships[action.sponsorshipId]

      return receiveReducer(state, action, () => ({
        sponsorships: {
          ...state.sponsorships,
          [action.sponsorshipId]: {
            ...sponsorship,
            experts: action.response.experts,
          },
        },
      }))
    }

    case receiveAction(FETCH_NOTIFICATION_IDS): {
      return receiveReducer(state, action, () => ({
        notifications: action.response.notificationIds,
      }))
    }

    case receiveAction(ADD_PUBLICATION_TO_SPONSORSHIP):
    case receiveAction(UPLOAD_SPONSORED_PAPER): {
      return receiveReducer(state, action, () => {
        if (action.response.success) {
          const sponsorshipPublications =
            state.publications[action.formData.sponsorshipId] || {}

          const publications = {
            ...state.publications,
            [action.formData.sponsorshipId]: {
              [action.response.publication.publicationId]: action.response
                .publicationStats
                ? action.response.publicationStats
                : {
                    cclick_clicks: 0,
                    cclick_downloads: 0,
                    pclick_clicks: 0,
                    pclick_downloads: 0,
                  },
              ...sponsorshipPublications,
            },
          }

          return {
            publications,
          }
        }

        return state
      })
    }

    case receiveAction(REMOVE_PUBLICATION_FROM_SPONSORSHIP): {
      return receiveReducer(state, action, () => {
        if (action.response.success) {
          const sponsorshipPublications = {
            ...state.publications[action.sponsorshipId],
          }

          delete sponsorshipPublications[action.publicationId]

          const publications = {
            ...state.publications,
            [action.sponsorshipId]: sponsorshipPublications,
          }

          return {
            publications,
          }
        }

        return state
      })
    }

    default:
      return state
  }
}

export function getSponsorship(state, sponsorshipId) {
  return _.get(state.sponsorship, ['sponsorships', sponsorshipId], {})
}

export function getSponsorshipRequest(state, sponsorshipId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SPONSORSHIP, sponsorshipId),
    {}
  )
}

export function getInjectionStats(state) {
  return state.sponsorship.injectionStats
}

export function getAllSponsorships(state) {
  return state.sponsorship.sponsorships
}

export function getNotificationsVolume(state) {
  return state.sponsorship.volume
}

export function getIsInjectionStatsLoading(state, from, to) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_INJECTION_STATS, `${from} - ${to}`),
    {}
  ).isLoading
}

export function getAreSponsorshipsLoading(state, from, to) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SPONSORSHIPS, `${from} - ${to}`),
    {}
  ).isLoading
}

export function getIsActiveSponsorshipsLoading(state, from, to) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_ACTIVE_SPONSORSHIPS, `${from} - ${to}`),
    {}
  ).isLoading
}

export function getIsNotificationsVolumeLoading(state, from, to) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_NOTIFICATIONS_VOLUME, `${from} - ${to}`),
    {}
  ).isLoading
}

export function getIsFetchNotificationsIdsLoading(state) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_NOTIFICATION_IDS),
    {}
  ).isLoading
}

export function getNotificationIds(state) {
  return _.get(state, 'sponsorship.notifications', [])
}

export function getFetchSponsorshipsStatsIsLoaded(state) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SPONSORSHIPS_STATS),
    {}
  ).isLoaded
}

export function getFetchInjectedQuestionsRequest(state) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_INJECTED_QUESTIONS),
    {}
  )
}

export function getFetchSponsorshipReachIsLoaded(state, sponsorshipId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SPONSORSHIP_REACH, sponsorshipId),
    {}
  ).isLoaded
}

export function getFetchSponsorshipsStatsIsLoading(state) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SPONSORSHIPS_STATS),
    {}
  ).isLoading
}

export function getFetchSponsorshipReachIsLoading(state, sponsorshipId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SPONSORSHIP_REACH, sponsorshipId),
    {}
  ).isLoading
}

export function getSponsorshipsStats(state, sponsorshipId) {
  if (sponsorshipId) {
    return _.get(state.sponsorship, `stats[${sponsorshipId}]`, [])
  }
  return _.get(state.sponsorship, 'stats', {})
}

export function getSponsorshipReach(state, sponsorshipId) {
  return _.get(state.sponsorship, ['reach', sponsorshipId], [])
}

export function getInjectedQuestions(state) {
  return _.get(state.sponsorship, ['injectedQuestions'], [])
}
