import React from 'react'

import { mixpanel } from 'mednet-util/src/tracking'
import { RouterTabs, TabButton } from 'pharmacy/src/navigation/tabs'
import { QuestionFeed } from 'pharmacy/src/display/feed'
import { UserInviteMixin } from 'pharmacy/src/display/feed/mixins/userInviteMixin'
import { QuestionCard } from 'pharmacy/src/question/questionCard'

class HomeFeed extends React.Component {
  static tabs = [
    {
      header: <TabButton title="Most Recent" icon={['far', 'clock']} />,
      component: (
        <QuestionFeed
          itemComponent={QuestionCard}
          feedName="recent"
          trackViewdItems
          mixins={[
            {
              mixinComponent: UserInviteMixin,
              firstIndex: 10,
              repeatEvery: 20,
            },
          ]}
        />
      ),
    },
    {
      header: <TabButton title="Most Active" icon="chart-line" />,
      component: (
        <QuestionFeed
          feedName="active"
          itemComponent={QuestionCard}
          trackViewdItems
        />
      ),
    },
    {
      header: <TabButton title="Unanswered" icon="comment-slash" />,
      component: (
        <QuestionFeed
          feedName="unanswered"
          itemComponent={QuestionCard}
          trackViewdItems
        />
      ),
    },
    {
      header: <TabButton title="Starred" icon="star" />,
      component: (
        <QuestionFeed feedName="starred" itemComponent={QuestionCard} />
      ),
    },
  ]

  onTabChange = (activeTab) => {
    mixpanel.track('viewed_feed_tab', {
      feed_sort: HomeFeed.tabs[activeTab].component.props.feedName,
    })
  }

  render() {
    return <RouterTabs onTabChange={this.onTabChange} tabs={HomeFeed.tabs} />
  }
}

export default HomeFeed
