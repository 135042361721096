import { all, takeLatest, takeLeading, takeEvery } from 'redux-saga/effects'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
  requestAction,
} from 'mednet-cns/src/cns-util/reducer'
import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'

import { makeURL } from 'mednet-util/src/router'
import {
  CME_ACTIVITY_TYPE,
  CME_CERTIFICATE_TYPE,
} from 'mednet-util/src/constants/cme'

import { TOGGLE_FOLLOW_QUESTION } from './question'

export const FETCH_CME_CONFIG = 'cme/FETCH_CME_CONFIG'
export const FETCH_AVAILABLE_CME_ACTIVITES = 'cme/FETCH_AVAILABLE_CME_ACTIVITES'
export const FETCH_REDEEMED_CME_ACTIVITES = 'cme/FETCH_REDEEMED_CME_ACTIVITES'
export const FETCH_REDEEMED_MOC_ACTIVITES = 'cme/FETCH_REDEEMED_MOC_ACTIVITES'
export const FETCH_CME_GOALS = 'cme/FETCH_CME_GOALS'
export const FETCH_CME_APPLICATIONS = 'cme/FETCH_CME_APPLICATIONS'
export const FETCH_CME_IMPACTS = 'cme/FETCH_CME_IMPACTS'
export const REDEEM_CME_ACTIVITY = 'cme/REDEEM_CME_ACTIVITY'
export const REDEEM_MOC_ACTIVITY = 'cme/REDEEM_MOC_ACTIVITY'
export const UPDATE_REDEEMED_CME_ACTIVITY = 'cme/UPDATE_REDEEMED_CME_ACTIVITY'
export const CREATE_CME_CERTIFICATE = 'cme/CREATE_CME_CERTIFICATE'
export const FETCH_CME_CERTIFICATES = 'cme/FETCH_CME_CERTIFICATES'
export const FETCH_QUESTION_CME_PREVIEW_ITEMS =
  'cme/FETCH_QUESTION_CME_PREVIEW_ITEMS'
export const CHECK_IF_QUESTION_AVAILABLE_FOR_CME_AND_MOC =
  'cme/CHECK_IF_QUESTION_AVAILABLE_FOR_CME_AND_MOC'
export const FETCH_SPONSORSHIP_REDEEMABLE_ANSWERS =
  'cme/FETCH_SPONSORSHIP_REDEEMABLE_ANSWERS'
export const FETCH_CERTIFICATE_DOWNLOAD_LINK =
  'cme/FETCH_CERTIFICATE_DOWNLOAD_LINK'
export const FETCH_CURRENT_YEAR_CME_ACTIVITIES_COUNTS =
  'cme/FETCH_CURRENT_YEAR_CME_ACTIVITIES_COUNTS'
export const SET_RECENT_CERTIFICATES = 'cme/SET_RECENT_CERTIFICATES'
export const TRACK_CME_PAGE_VISIT = 'cme/TRACK_CME_PAGE_VISIT'
export const CHECK_IF_SHOULD_PROMPT_FOR_MOC =
  'cme/CHECK_IF_SHOULD_PROMPT_FOR_MOC'

export const UPDATE_MOC_PROFILE = 'cme/UPDATE_MOC_PROFILE'
export const FETCH_REDEMPTION_RELATED_QUESTIONS =
  'cme/FETCH_REDEMPTION_RELATED_QUESTIONS'

export const FETCH_REDEEMED_COUNTS_FOR_A_YEAR =
  'cme/FETCH_REDEEMED_COUNTS_FOR_A_YEAR'

export const FETCH_MOC_AVAILABLE_ACTIVITIES =
  'cme/FETCH_MOC_AVAILABLE_ACTIVITIES'

export function fetchCmeConfig() {
  return {
    type: FETCH_CME_CONFIG,
  }
}

export function fetchAvailableCmeActivites(fromDate, toDate, callback) {
  return {
    type: FETCH_AVAILABLE_CME_ACTIVITES,
    fromDate,
    toDate,
    callback,
  }
}

export function fetchRedeemedCmeActivites(year, callback) {
  return {
    type: FETCH_REDEEMED_CME_ACTIVITES,
    requestId: year,
    callback,
  }
}

export function fetchRedeemedMocActivities(year, callback) {
  return {
    type: FETCH_REDEEMED_MOC_ACTIVITES,
    requestId: year,
    callback,
  }
}

export function fetchCmeGoals(callback) {
  return {
    type: FETCH_CME_GOALS,
    callback,
  }
}

export function fetchCmeApplications(callback) {
  return {
    type: FETCH_CME_APPLICATIONS,
    callback,
  }
}

export function fetchCmeImpacts(callback) {
  return {
    type: FETCH_CME_IMPACTS,
    callback,
  }
}

export function checkIfQuestionAvailableForCme(questionId, callback) {
  return {
    type: CHECK_IF_QUESTION_AVAILABLE_FOR_CME_AND_MOC,
    requestId: questionId,
    callback,
  }
}

export function fetchSponsorshipRedeemableAnswers(sponsorshipId, callback) {
  return {
    type: FETCH_SPONSORSHIP_REDEEMABLE_ANSWERS,
    requestId: sponsorshipId,
    callback,
  }
}

export function redeemCmeActivity(formData, cmeActivityId, callback) {
  return {
    type: REDEEM_CME_ACTIVITY,
    formData,
    cmeActivityId,
    callback,
  }
}

export function redeemMocActivities(formData, callback) {
  return {
    type: REDEEM_MOC_ACTIVITY,
    formData,
    callback,
  }
}

export function updateRedeemedCmeActivity(formData, redeemId, callback) {
  return {
    type: UPDATE_REDEEMED_CME_ACTIVITY,
    redeemId,
    formData,
    callback,
  }
}

export function fetchCertificateDownloadLink(certificateId, callback) {
  return {
    type: FETCH_CERTIFICATE_DOWNLOAD_LINK,
    requestId: certificateId,
    callback,
  }
}

export function fetchCurrentYearCmeActivitiesCounts(callback) {
  return {
    type: FETCH_CURRENT_YEAR_CME_ACTIVITIES_COUNTS,
    callback,
  }
}

export function createCmeCertificate(formData, activitiesIds, callback) {
  return {
    type: CREATE_CME_CERTIFICATE,
    formData,
    activitiesIds,
    callback,
  }
}

export function fetchCmeCertificates(year, callback) {
  return {
    type: FETCH_CME_CERTIFICATES,
    requestId: year,
    callback,
  }
}

export function fetchQuestionCmePreviewItems(
  questionId,
  includeOwnAnswer,
  callback
) {
  return {
    type: FETCH_QUESTION_CME_PREVIEW_ITEMS,
    requestId: questionId,
    includeOwnAnswer,
    callback,
  }
}

export function setRecentCertificates(certificatesHashes) {
  return {
    type: SET_RECENT_CERTIFICATES,
    certificatesHashes,
  }
}

export function trackCmePageVisit(source) {
  return {
    type: TRACK_CME_PAGE_VISIT,
    source,
  }
}

export function updateMocProfile(formData, callback) {
  return {
    type: UPDATE_MOC_PROFILE,
    formData,
    callback,
  }
}

export function shouldPromptForMoc(callback) {
  return {
    type: CHECK_IF_SHOULD_PROMPT_FOR_MOC,
    callback,
  }
}

export function fetchRedemptionRelatedQuestions(
  redeemedActivitiesIds,
  callback
) {
  return {
    type: FETCH_REDEMPTION_RELATED_QUESTIONS,
    activitiesIds: redeemedActivitiesIds,
    requestId: redeemedActivitiesIds.join(','),
    callback,
  }
}

export function fetchRedeemedCountsForYear(year, callback) {
  return {
    type: FETCH_REDEEMED_COUNTS_FOR_A_YEAR,
    requestId: year,
    callback,
  }
}

export function fetchMocAvailableActivities(callback) {
  return {
    type: FETCH_MOC_AVAILABLE_ACTIVITIES,
    callback,
  }
}

function utcToLocal(date) {
  return moment
    .utc(date, 'YYYY-MM-DD HH:mm:ss')
    .local()
    .format('YYYY-MM-DD HH:mm:ss')
}

function localToUTC(date) {
  return moment(`${date}`, 'YYYY-MM-DD HH:mm:ss')
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')
}

function* watchFetch() {
  yield makeFetchEffect(takeLeading, FETCH_CME_CONFIG, 'cme/getCmeConfigJSON')

  yield makeFetchEffect(takeLatest, FETCH_AVAILABLE_CME_ACTIVITES, (action) =>
    makeURL('cme/getCmeAvailableActivitiesJSON', {
      fromDate: localToUTC(`${action.fromDate} 00:00:00`),
      toDate: localToUTC(`${action.toDate} 23:59:59`),
      localYear: new Date().getFullYear(),
    })
  )

  yield makeFetchEffect(takeLatest, FETCH_REDEEMED_CME_ACTIVITES, (action) =>
    makeURL('cme/getCmeRedeemedActivitiesJSON', {
      localYear: action.requestId,
    })
  )

  yield makeFetchEffect(takeLatest, FETCH_REDEEMED_MOC_ACTIVITES, (action) =>
    makeURL('cme/getMocRedeemedActivitiesJSON', {
      localYear: action.requestId,
    })
  )

  yield makeFetchEffect(takeLeading, FETCH_CME_GOALS, 'cme/getCmeGoalsJSON')

  yield makeFetchEffect(
    takeLeading,
    FETCH_CME_APPLICATIONS,
    'cme/getCmeApplicationsJSON'
  )

  yield makeFetchEffect(takeLeading, FETCH_CME_IMPACTS, 'cme/getCmeImpactsJSON')

  yield makeFetchEffect(
    takeEvery,
    REDEEM_CME_ACTIVITY,
    makeURL('cme/redeemCmeForActivityJSON'),
    (action) =>
      makePOSTHeadersFromParams({
        ...action.formData,
        cmeActivityId: action.cmeActivityId,
        localCurrentTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      })
  )

  yield makeFetchEffect(
    takeEvery,
    REDEEM_MOC_ACTIVITY,
    makeURL('cme/redeemMocForActivitiesJSON'),
    (action) =>
      makePOSTHeadersFromParams({
        ...action.formData,
        localCurrentTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      })
  )

  yield makeFetchEffect(
    takeLatest,
    CHECK_IF_QUESTION_AVAILABLE_FOR_CME_AND_MOC,
    (action) =>
      makeURL('cme/isQuestionAvailableForCmeAndMocJSON', {
        questionId: action.requestId,
        localYear: new Date().getFullYear(),
      })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_SPONSORSHIP_REDEEMABLE_ANSWERS,
    (action) =>
      makeURL('cme/getSponsorshipRedeemableAnswersJSON', {
        sponsorshipId: action.requestId,
        localYear: new Date().getFullYear(),
      })
  )

  yield makeFetchEffect(
    takeEvery,
    UPDATE_REDEEMED_CME_ACTIVITY,
    makeURL('cme/updateCmeRedemptionJSON'),
    (action) =>
      makePOSTHeadersFromParams({
        ...action.formData,
        redeemId: action.redeemId,
      })
  )

  yield makeFetchEffect(takeEvery, FETCH_CERTIFICATE_DOWNLOAD_LINK, (action) =>
    makeURL('cme/downloadCmeCertificateJSON', {
      hash: action.requestId,
      timezoneOffset: new Date().getTimezoneOffset(),
    })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_CURRENT_YEAR_CME_ACTIVITIES_COUNTS,
    makeURL('cme/getCmeActivitiesCountsForTheCurrentYearJSON', {
      localYear: new Date().getFullYear(),
    })
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_CME_CERTIFICATE,
    makeURL('cme/createCmeCertificateJSON'),
    (action) =>
      makePOSTHeadersFromParams({
        ...action.formData,
        activitiesIds: action.activitiesIds,
      })
  )

  yield makeFetchEffect(takeLatest, FETCH_CME_CERTIFICATES, (action) =>
    makeURL('cme/getCmeCertificatesJSON', {
      localYear: action.requestId,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_QUESTION_CME_PREVIEW_ITEMS,
    (action) =>
      makeURL('question/getCmePreviewItemsJSON', {
        questionId: action.requestId,
        includeOwnAnswer: action.includeOwnAnswer,
      })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_QUESTION_CME_PREVIEW_ITEMS,
    (action) =>
      makeURL('question/getCmePreviewItemsJSON', {
        questionId: action.requestId,
        includeOwnAnswer: action.includeOwnAnswer,
      })
  )

  yield makeFetchEffect(takeLatest, TRACK_CME_PAGE_VISIT, (action) =>
    makeURL('cme/trackPageVisitJSON', {
      source: action.source,
    })
  )

  yield makeFetchEffect(
    takeEvery,
    UPDATE_MOC_PROFILE,
    makeURL('user/mocProfile/updateMocProfile'),
    (action) => makePOSTHeadersFromParams(action.formData)
  )

  yield makeFetchEffect(
    takeLeading,
    CHECK_IF_SHOULD_PROMPT_FOR_MOC,
    'user/mocProfile/checkIfShouldPromptForMocInfo'
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_REDEMPTION_RELATED_QUESTIONS,
    (action) =>
      makeURL('cme/getRedemptionRelatedQuestionsJSON', {
        activitiesIds: action.activitiesIds,
      })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_REDEEMED_COUNTS_FOR_A_YEAR,
    (action) =>
      makeURL('cme/getRedeemedCountsForYearJSON', {
        localYear: action.requestId,
      })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_MOC_AVAILABLE_ACTIVITIES,
    makeURL('cme/getMocAvailableActivitiesJSON', {
      localYear: new Date().getFullYear(),
    })
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const getStateWithMaxCmeRedeemed = (cmeState) => {
  return {
    availableCmeActivities: {
      ...cmeState.availableCmeActivities,
      activities: [],
    },
    cmeRedeemableQuestionsIds: {},
    cmeSponsorshipsActivities: {},
    redeemedMaxCmeForCurrentYear: true,
    availableCmeCount: 0,
  }
}

const getStateWithMaxMocRedeemed = () => {
  return {
    activitiesAvailableForMoc: [],
    mocRedeemableQuestionsIds: {},
    redeemedMaxMocForCurrentYear: true,
  }
}

const getStateWithQuestionActivityAvailableForCme = (
  cmeState,
  questionId,
  cmeActivityId
) => {
  // Do not update availableCmeActivities as they are for specific date range,
  // instead reload them every time the user visits the CME dashboard page
  const cmeRedeemableQuestionsIds = { ...cmeState.cmeRedeemableQuestionsIds }
  let availableCmeCount = cmeState.availableCmeCount

  if (!cmeRedeemableQuestionsIds[questionId]) {
    cmeRedeemableQuestionsIds[questionId] = cmeActivityId
    availableCmeCount++
  }

  return {
    cmeRedeemableQuestionsIds,
    availableCmeCount,
  }
}

const getStateWithQuestionActivityAvailableForMoc = (
  cmeState,
  questionId,
  cmeActivityId
) => {
  const mocRedeemableQuestionsIds = { ...cmeState.mocRedeemableQuestionsIds }

  if (!mocRedeemableQuestionsIds[questionId]) {
    mocRedeemableQuestionsIds[questionId] = cmeActivityId
  }

  return {
    mocRedeemableQuestionsIds,
  }
}

const getStateWithQuestionActivityUnavailableForCme = (
  cmeState,
  questionId
) => {
  let availableCmeCount = cmeState.availableCmeCount
  let filteredOut = false

  const availableCmeActivities =
    cmeState.availableCmeActivities.activities.filter((activity) => {
      if (
        activity.contentType !== CME_ACTIVITY_TYPE.PUBLICATION_VIEW &&
        parseInt(activity.contentId) === parseInt(questionId)
      ) {
        filteredOut = true
        return false
      }

      return true
    })

  const cmeRedeemableQuestionsIds = Object.fromEntries(
    Object.entries(cmeState.cmeRedeemableQuestionsIds).filter(
      ([id, _cmeActivityId]) => {
        if (parseInt(id) === parseInt(questionId)) {
          filteredOut = true
          return false
        }

        return true
      }
    )
  )

  const cmeSponsorshipsActivities = Object.fromEntries(
    Object.entries(cmeState.cmeSponsorshipsActivities).map(
      ([sposorshipId, activities]) => {
        return [
          sposorshipId,
          activities.filter((activity) => {
            if (parseInt(activity.contentId) === parseInt(questionId)) {
              filteredOut = true
              return false
            }

            return true
          }),
        ]
      }
    )
  )

  if (filteredOut) {
    availableCmeCount = Math.max(availableCmeCount - 1, 0)
  }

  return {
    availableCmeActivities: {
      ...cmeState.availableCmeActivities,
      activities: availableCmeActivities,
    },
    cmeRedeemableQuestionsIds,
    cmeSponsorshipsActivities,
    availableCmeCount,
  }
}

const getStateWithQuestionActivitiesUnavailableForMoc = (
  cmeState,
  questionIds
) => {
  const intQuestionIds = questionIds.map((questionId) => parseInt(questionId))

  const activitiesAvailableForMoc = cmeState.activitiesAvailableForMoc.filter(
    (activity) => {
      if (
        activity.contentType !== CME_ACTIVITY_TYPE.PUBLICATION_VIEW &&
        intQuestionIds.includes(parseInt(activity.contentId))
      ) {
        return false
      }

      return true
    }
  )

  const mocRedeemableQuestionsIds = Object.fromEntries(
    Object.entries(cmeState.mocRedeemableQuestionsIds).filter(
      ([id, _cmeActivityId]) => {
        if (intQuestionIds.includes(parseInt(id))) {
          return false
        }

        return true
      }
    )
  )

  return {
    activitiesAvailableForMoc,
    mocRedeemableQuestionsIds,
  }
}

const getStateWithPublicationActivityUnavailableForCme = (
  cmeState,
  publicationId
) => {
  let availableCmeCount = cmeState.availableCmeCount
  let filteredOut = false

  const availableCmeActivities =
    cmeState.availableCmeActivities.activities.filter((activity) => {
      if (
        activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW &&
        parseInt(activity.contentId) === parseInt(publicationId)
      ) {
        filteredOut = true
        return false
      }
      return true
    })

  if (filteredOut) {
    availableCmeCount--
  }

  return {
    availableCmeActivities: {
      ...cmeState.availableCmeActivities,
      activities: availableCmeActivities,
    },
    availableCmeCount,
  }
}

const getStateWithPublicationActivitiesUnavailableForMoc = (
  cmeState,
  publicationIds
) => {
  const intPublicationsIds = publicationIds.map((publicationId) =>
    parseInt(publicationId)
  )

  const activitiesAvailableForMoc =
    cmeState.availableCmeActivities.activities.filter((activity) => {
      if (
        activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW &&
        intPublicationsIds.includes(parseInt(activity.contentId))
      ) {
        return false
      }
      return true
    })

  return {
    activitiesAvailableForMoc,
  }
}

const getStateAfterCertificateCreation = (
  redeemedActivities,
  certificates,
  createdCertificatesActivitiesIds,
  createdCertificatesTypes,
  createdCertificatesHashes
) => {
  let startDate = undefined
  let endDate = undefined

  Object.values(redeemedActivities)
    .flat()
    .forEach((activity) => {
      if (createdCertificatesActivitiesIds.includes(activity.cmeActivityId)) {
        const redeemDate = moment(activity.redeemDate, 'YYYY-MM-DD')

        if (!startDate || redeemDate < startDate) {
          startDate = redeemDate
        }

        if (!endDate || redeemDate > endDate) {
          endDate = redeemDate
        }
      }
    })

  const createdCretificates = createdCertificatesTypes.map((type) => ({
    hash: createdCertificatesHashes[type],
    type,
    creationDate: moment(new Date()).format('YYYY-MM-DD'),
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
    actvitiesCount: createdCertificatesActivitiesIds.length,
  }))

  const certificateYear = startDate.toDate().getFullYear()

  const updatedCertificates = {
    ...certificates,
    [certificateYear]: [
      ...createdCretificates, // creatd latest add first
      ...(certificates[certificateYear] || []),
    ],
  }

  const updatedRdeemedActivities = Object.fromEntries(
    Object.entries(redeemedActivities).map(([year, activities]) => {
      const updatedActivities = activities.map((activity) =>
        createdCertificatesActivitiesIds.includes(activity.cmeActivityId)
          ? { ...activity, createdCertificate: true }
          : activity
      )

      return [year, updatedActivities]
    })
  )

  const recentCertificates = Object.values(createdCertificatesHashes)

  return {
    updatedCertificates,
    updatedRdeemedActivities,
    recentCertificates,
  }
}

const initialState = {
  availableCmeActivities: {
    fromDate: undefined,
    toDate: undefined,
    activities: [],
  }, // Available activities in specific date range
  activitiesAvailableForMoc: [], // No date filter here, it is a requirement for an activity to be redeemed for CME to be available for MOC
  redeemedCmeActivities: {},
  redeemedMocActivities: {},
  cmeConfig: {},
  cmeRedeemableQuestionsIds: {},
  mocRedeemableQuestionsIds: {},
  cmeSponsorshipsActivities: {},
  certificatesDownloadUrls: {},
  certificates: {},
  availableCmeCount: 0,
  yearsRedeemedCmeCounts: {},
  yearsRedeemedMocCounts: {},
  questionsCmePreviewItems: {},
  recentCertificates: [],
  promptMocProfile: false,
  redeemedMaxCmeForCurrentYear: false,
  redeemedMaxMocForCurrentYear: false,
  redemptionRelatedQuestions: [],
}

export function reducer(cmeState = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_CME_CONFIG): {
      return receiveReducer(cmeState, action, () => ({
        cmeConfig: action.response,
      }))
    }
    case receiveAction(FETCH_AVAILABLE_CME_ACTIVITES): {
      return receiveReducer(cmeState, action, () => {
        if (action.response.redeemedMaxCmeForCurrentYear) {
          return {
            ...getStateWithMaxCmeRedeemed(cmeState),
            availableCmeActivities: {
              fromDate: action.fromDate,
              toDate: action.toDate,
              activities: [],
            },
          }
        }

        return {
          availableCmeActivities: {
            fromDate: action.fromDate,
            toDate: action.toDate,
            activities: action.response.activities.map((activity) => ({
              ...activity,
              activityDate: utcToLocal(activity.activityDate),
            })),
          },
        }
      })
    }
    case receiveAction(FETCH_REDEEMED_CME_ACTIVITES): {
      return receiveReducer(cmeState, action, () => ({
        redeemedCmeActivities: {
          ...cmeState.redeemedCmeActivities,
          [action.requestId]: action.response.map((activity) => ({
            ...activity,
            redeemDate: activity.redeemDate, // We get local date from backend
            createdCertificate: activity.createdCertificate === 1,
          })),
        },
      }))
    }

    case receiveAction(FETCH_REDEEMED_MOC_ACTIVITES): {
      return receiveReducer(cmeState, action, () => ({
        redeemedMocActivities: {
          ...cmeState.redeemedMocActivities,
          [action.requestId]: action.response.map((activity) => ({
            ...activity,
            redeemDate: activity.redeemDate, // We get local date from backend
            createdCertificate: activity.createdCertificate === 1,
          })),
        },
      }))
    }

    case receiveAction(FETCH_CME_GOALS): {
      return receiveReducer(cmeState, action, () => ({
        goals: action.response,
      }))
    }

    case receiveAction(FETCH_CME_APPLICATIONS): {
      return receiveReducer(cmeState, action, () => ({
        applications: action.response,
      }))
    }

    case receiveAction(FETCH_CME_IMPACTS): {
      return receiveReducer(cmeState, action, () => ({
        impacts: action.response,
      }))
    }

    case receiveAction(CHECK_IF_QUESTION_AVAILABLE_FOR_CME_AND_MOC): {
      return receiveReducer(cmeState, action, () => {
        if (
          action.response.redeemedMaxCmeForCurrentYear &&
          action.response.redeemedMaxMocForCurrentYear
        ) {
          return {
            ...getStateWithMaxCmeRedeemed(cmeState),
            ...getStateWithMaxMocRedeemed(cmeState),
          }
        }

        let stateWithAnyMaxRedeemed = {}

        if (action.response.redeemedMaxCmeForCurrentYear) {
          stateWithAnyMaxRedeemed = getStateWithMaxCmeRedeemed(cmeState)
        }

        // This cannot happen if max CME is not redeemed
        if (action.response.redeemedMaxMocForCurrentYear) {
          stateWithAnyMaxRedeemed = {
            ...stateWithAnyMaxRedeemed,
            ...getStateWithMaxMocRedeemed(cmeState),
          }
        }

        let cmeUpdatedSubstate = {}
        let mocUpdatedSubstate = {}

        if (action.response.isAvailableForCme) {
          cmeUpdatedSubstate = getStateWithQuestionActivityAvailableForCme(
            cmeState,
            action.requestId,
            action.response.cmeActivityId
          )
        } else {
          cmeUpdatedSubstate = getStateWithQuestionActivityUnavailableForCme(
            cmeState,
            action.requestId
          )
        }

        if (action.response.isAvailableForMoc) {
          mocUpdatedSubstate = getStateWithQuestionActivityAvailableForMoc(
            cmeState,
            action.requestId,
            action.response.cmeActivityId
          )
        } else {
          mocUpdatedSubstate = getStateWithQuestionActivitiesUnavailableForMoc(
            cmeState,
            [action.requestId]
          )
        }

        return {
          ...stateWithAnyMaxRedeemed,
          ...cmeUpdatedSubstate,
          ...mocUpdatedSubstate,
        }
      })
    }

    case receiveAction(FETCH_SPONSORSHIP_REDEEMABLE_ANSWERS): {
      return receiveReducer(cmeState, action, () => {
        if (action.response.redeemedMaxCmeForCurrentYear) {
          return getStateWithMaxCmeRedeemed(cmeState)
        }

        return {
          // For sponsorship we will only show questions that are available for both cme & MOC (meaning CME since cme is a requirment for moc)
          cmeSponsorshipsActivities: {
            ...cmeState.cmeSponsorshipsActivities,
            [action.requestId]: action.response.activities.map((activity) => ({
              ...activity,
              activityDate: utcToLocal(activity.activityDate),
            })),
          },
        }
      })
    }

    case receiveAction(REDEEM_CME_ACTIVITY): {
      return receiveReducer(cmeState, action, () => {
        if (!action.response.success) {
          return cmeState
        }

        const availableCmeCount = cmeState.availableCmeCount - 1
        const currentYear = new Date().getFullYear()
        const yearsRedeemedCmeCounts = { ...cmeState.yearsRedeemedCmeCounts }
        const redeemedCmeCount = (yearsRedeemedCmeCounts[currentYear] || 0) + 1
        yearsRedeemedCmeCounts[currentYear] = redeemedCmeCount

        const redeemedActivity = {
          ...action.response.redeemedActivity,
          redeemDate: moment(new Date()).format('YYYY-MM-DD'),
          createdCertificate: false,
        }

        const redeemedCmeActivities = {
          ...cmeState.redeemedCmeActivities,
          [currentYear]: cmeState.redeemedCmeActivities[currentYear]
            ? [redeemedActivity, ...cmeState.redeemedCmeActivities[currentYear]]
            : [redeemedActivity],
        }

        const activitiesAvailableForMoc = [
          {
            ...action.response.redeemedActivity,
            activityDate: utcToLocal(
              action.response.redeemedActivity.activityDate
            ),
          },
          ...cmeState.activitiesAvailableForMoc,
        ]

        let stateWithActivityAvailableForMoc = {}

        if (
          redeemedActivity.contentType !== CME_ACTIVITY_TYPE.PUBLICATION_VIEW
        ) {
          stateWithActivityAvailableForMoc =
            getStateWithQuestionActivityAvailableForMoc(
              cmeState,
              redeemedActivity.contentId,
              redeemedActivity.cmeActivityId
            )
        }

        if (action.response.redeemedMaxCmeForCurrentYear) {
          return {
            ...getStateWithMaxCmeRedeemed(cmeState),
            yearsRedeemedCmeCounts,
            redeemedCmeActivities,
            ...stateWithActivityAvailableForMoc,
            activitiesAvailableForMoc,
          }
        }

        if (
          redeemedActivity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW
        ) {
          return {
            yearsRedeemedCmeCounts,
            availableCmeCount,
            redeemedCmeActivities,
            ...stateWithActivityAvailableForMoc,
            activitiesAvailableForMoc,
            ...getStateWithPublicationActivityUnavailableForCme(
              cmeState,
              redeemedActivity.contentId
            ),
          }
        }

        return {
          yearsRedeemedCmeCounts,
          availableCmeCount,
          redeemedCmeActivities,
          activitiesAvailableForMoc,
          ...stateWithActivityAvailableForMoc,
          ...getStateWithQuestionActivityUnavailableForCme(
            cmeState,
            redeemedActivity.contentId
          ),
        }
      })
    }

    case receiveAction(REDEEM_MOC_ACTIVITY): {
      return receiveReducer(cmeState, action, () => {
        if (
          !action.response.success ||
          isEmpty(action.response.redeemedActivities)
        ) {
          return cmeState
        }

        const currentYear = new Date().getFullYear()
        const yearsRedeemedMocCounts = { ...cmeState.yearsRedeemedMocCounts }
        const redeemedMocCount =
          (yearsRedeemedMocCounts[currentYear] || 0) +
          action.response.redeemedActivities.length
        yearsRedeemedMocCounts[currentYear] = redeemedMocCount

        const redeemedActivities = action.response.redeemedActivities.map(
          (activity) => ({
            ...activity,
            redeemDate: moment(new Date()).format('YYYY-MM-DD'),
            createdCertificate: false,
          })
        )

        const redeemedMocActivities = {
          ...cmeState.redeemedMocActivities,
          [currentYear]: cmeState.redeemedMocActivities[currentYear]
            ? [
                ...redeemedActivities,
                ...cmeState.redeemedMocActivities[currentYear],
              ]
            : redeemedActivities,
        }

        if (action.response.redeemedMaxMocForCurrentYear) {
          return {
            ...getStateWithMaxMocRedeemed(cmeState),
            yearsRedeemedMocCounts,
            redeemedMocActivities,
          }
        }

        const publicationsIdsUnavailableForMoc = redeemedActivities
          .filter(
            (activity) =>
              activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW
          )
          .map((activity) => activity.contentId)

        const questionsIdsUnavailableForMoc = redeemedActivities
          .filter(
            (activity) =>
              activity.contentType !== CME_ACTIVITY_TYPE.PUBLICATION_VIEW
          )
          .map((activity) => activity.contentId)

        const stateWithPublicationNotAvialable =
          getStateWithPublicationActivitiesUnavailableForMoc(
            cmeState,
            publicationsIdsUnavailableForMoc
          )

        const stateWithQuestionNotAvailable =
          getStateWithQuestionActivitiesUnavailableForMoc(
            cmeState,
            questionsIdsUnavailableForMoc
          )

        return {
          yearsRedeemedMocCounts,
          redeemedMocActivities,
          ...stateWithPublicationNotAvialable,
          ...stateWithQuestionNotAvailable,
        }
      })
    }

    case receiveAction(FETCH_CERTIFICATE_DOWNLOAD_LINK): {
      return receiveReducer(cmeState, action, () => {
        return {
          certificatesDownloadUrls: {
            ...cmeState.certificatesDownloadUrls,
            [action.requestId]: action.response.downloadUrl,
          },
        }
      })
    }

    case receiveAction(FETCH_CURRENT_YEAR_CME_ACTIVITIES_COUNTS): {
      return receiveReducer(cmeState, action, () => {
        if (action.response.redeemedMaxCmeForCurrentYear) {
          return {
            ...getStateWithMaxCmeRedeemed(cmeState),
            availableCmeCount: action.response.availableCount,
            yearsRedeemedCmeCounts: {
              ...cmeState.yearsRedeemedCmeCounts,
              [new Date().getFullYear()]: action.response.redeemedCount,
            },
          }
        }

        return {
          redeemedMaxCmeForCurrentYear: false,
          availableCmeCount: action.response.availableCount,
          yearsRedeemedCmeCounts: {
            ...cmeState.yearsRedeemedCmeCounts,
            [new Date().getFullYear()]: action.response.redeemedCount,
          },
        }
      })
    }

    case receiveAction(FETCH_REDEEMED_COUNTS_FOR_A_YEAR): {
      return receiveReducer(cmeState, action, () => {
        return {
          yearsRedeemedCmeCounts: {
            ...cmeState.yearsRedeemedCmeCounts,
            [action.requestId]: action.response.redeemedCmeCount,
          },
          yearsRedeemedMocCounts: {
            ...cmeState.yearsRedeemedMocCounts,
            [action.requestId]: action.response.redeemedMocCount,
          },
        }
      })
    }

    case receiveAction(CREATE_CME_CERTIFICATE): {
      return receiveReducer(cmeState, action, () => {
        if (!action.response.success) {
          return cmeState
        }

        // It is either CME or MOC but not both
        if (
          action.formData.types.length === 1 &&
          action.formData.types.includes(CME_CERTIFICATE_TYPE.CME)
        ) {
          const {
            updatedCertificates,
            updatedRdeemedActivities,
            recentCertificates,
          } = getStateAfterCertificateCreation(
            cmeState.redeemedCmeActivities,
            cmeState.certificates,
            action.activitiesIds,
            action.formData.types,
            action.response.certificatesHashes
          )

          return {
            certificates: updatedCertificates,
            redeemedCmeActivities: updatedRdeemedActivities,
            recentCertificates,
            promptMocProfile: false,
          }
        }

        const {
          updatedCertificates,
          updatedRdeemedActivities,
          recentCertificates,
        } = getStateAfterCertificateCreation(
          cmeState.redeemedMocActivities,
          cmeState.certificates,
          action.activitiesIds,
          action.formData.types,
          action.response.certificatesHashes
        )

        return {
          certificates: updatedCertificates,
          redeemedMocActivities: updatedRdeemedActivities,
          recentCertificates,
          promptMocProfile: false,
        }
      })
    }

    case receiveAction(FETCH_CME_CERTIFICATES): {
      return receiveReducer(cmeState, action, () => {
        return {
          certificates: {
            ...cmeState.certificates,
            [action.requestId]: action.response.map((certificate) => ({
              ...certificate,
              creationDate: utcToLocal(certificate.creationDate),
              startDate: certificate.startDate, // This is local date in DB
              endDate: certificate.endDate, // This is local date in DB
            })),
          },
        }
      })
    }

    case receiveAction(FETCH_QUESTION_CME_PREVIEW_ITEMS): {
      return receiveReducer(cmeState, action, () => {
        return {
          questionsCmePreviewItems: {
            ...cmeState.questionsCmePreviewItems,
            [action.requestId]: action.response,
          },
        }
      })
    }

    case requestAction(TOGGLE_FOLLOW_QUESTION): {
      const questionObject =
        cmeState.questionsCmePreviewItems[action.questionId]
      if (!questionObject) {
        return cmeState
      }

      return {
        ...cmeState,
        questionsCmePreviewItems: {
          ...cmeState.questionsCmePreviewItems,
          [action.questionId]: {
            ...questionObject,
            isFollowed: !questionObject.isFollowed,
          },
        },
      }
    }

    case SET_RECENT_CERTIFICATES: {
      return {
        ...cmeState,
        recentCertificates: action.certificatesHashes,
      }
    }

    case receiveAction(CHECK_IF_SHOULD_PROMPT_FOR_MOC): {
      return receiveReducer(cmeState, action, () => {
        return {
          promptMocProfile: action.response,
        }
      })
    }

    case receiveAction(UPDATE_MOC_PROFILE): {
      return receiveReducer(cmeState, action, () => {
        if (action.response.success) {
          return {
            promptMocProfile: false,
          }
        }
        return cmeState
      })
    }

    case receiveAction(FETCH_REDEMPTION_RELATED_QUESTIONS): {
      return receiveReducer(cmeState, action, () => {
        return {
          redemptionRelatedQuestions: action.response,
        }
      })
    }

    case receiveAction(FETCH_MOC_AVAILABLE_ACTIVITIES): {
      return receiveReducer(cmeState, action, () => {
        if (action.response.redeemedMaxMocForCurrentYear) {
          return getStateWithMaxMocRedeemed()
        }

        return {
          activitiesAvailableForMoc: action.response.activities,
        }
      })
    }

    default:
      return cmeState
  }
}
