import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'

import { Subtitle3 } from 'pharmacy/src/typography'
import { Icon } from 'pharmacy/src/display/icon'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import css from './datePicker.scss'

class OverlayComponent extends React.Component {
  render() {
    const { input, selectedDay, month, children, classNames, ...restProps } =
      this.props

    // Ref hack, see: https://github.com/gpbl/react-day-picker/issues/830
    return (
      <div className={classNames.overlayWrapper} {...restProps}>
        <div className={classNames.overlay} ref={classNames.overlayRef}>
          {children}
        </div>
      </div>
    )
  }
}

class DatePicker extends React.Component {
  static defaultProps = {
    onChange: _.noop,
    defaultValue: formatDate(moment()),
  }

  constructor(props) {
    super(props)

    this.overlay = React.createRef()

    this.state = {
      value: props.defaultValue,
      isOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      })
    }
  }

  handleOnClick = () => {
    if (!this.overlay.current) {
      return
    }

    const rect = this.overlay.current.getBoundingClientRect()
    if (rect.right > window.innerWidth) {
      this.overlay.current.style.right = 0
    }

    this.setState({
      isOpen: true,
    })
  }

  handleDayPickerHide = () => {
    this.setState({
      isOpen: false,
    })
  }

  handleDayChange = (selectedDay) => {
    this.setState(
      {
        value: selectedDay,
      },
      () => {
        this.props.onChange(selectedDay)
      }
    )
  }

  render() {
    const { isOpen } = this.state
    const {
      className,
      isLoading,
      innerRef,
      dayPickerProps = {},
      inputProps = {},
      formatDateFunction,
      placeholder,
      ...restProps
    } = this.props

    const containerClasses = classNames(css.datePickerContainer, className)
    const iconClasses = classNames(css.icon, {
      [css.icon_open]: isOpen,
    })
    const inputClasses = classNames(css.dayPickerInput_input, {
      [css.dayPickerInput_input_open]: isOpen,
    })

    return (
      <Subtitle3 className={containerClasses}>
        <DayPickerInput
          ref={innerRef}
          onDayPickerHide={this.handleDayPickerHide}
          inputProps={{
            className: inputClasses,
            disabled: inputProps.disabled || isLoading,
            onClick: this.handleOnClick,
            ...inputProps,
          }}
          overlayComponent={OverlayComponent}
          formatDate={formatDateFunction ?? formatDate}
          parseDate={parseDate}
          classNames={{
            container: css.dayPickerInput_container,
            overlayWrapper: css.dayPickerInput_overlayWrapper,
            overlay: css.dayPickerInput_overlay,
            overlayRef: this.overlay,
          }}
          placeholder={placeholder ? placeholder : `${formatDate(new Date())}`}
          dayPickerProps={{
            classNames: css,
            ...dayPickerProps,
          }}
          {...restProps}
          onDayChange={this.handleDayChange}
          value={this.state.value}
        />
        {isLoading ? (
          <StarLoader
            size="small"
            isVerticalMargin={false}
            className={iconClasses}
          />
        ) : (
          <Icon icon={['fal', 'arrow-down']} className={iconClasses} />
        )}
      </Subtitle3>
    )
  }
}

export default DatePicker
