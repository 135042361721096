import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Subtitle3, Subtitle1, Link1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { withPermissions } from 'mednet-util/src/permission'
import { Link } from 'pharmacy/src/navigation/link'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { FollowQuestionButton } from 'pharmacy/src/question/followQuestionButton'
import { GoodQuestion } from 'pharmacy/src/question/goodQuestion'
import { FollowUserButton } from 'pharmacy/src/user/followUserButton'
import { QuestionViewCme } from 'pharmacy/src/question/questionViewCme'

import { SHARE_MODAL } from 'mednet-util/src/constants/modal'
import { authItems } from 'mednet-util/src/constants/permission'

import { FETCH_QUESTION_METADATA } from 'mednet-cns/src/reducers/question'
import { makeRequestName } from 'mednet-cns/src/reducers/request'

import css from './questionActions.scss'

const ShareButton = withPermissions(authItems.share)((props) => (
  <Link1>
    <Button type="text" icon={['far', 'share']} onClick={props.openShareModal}>
      Share
    </Button>
  </Link1>
))

class QuestionActions extends React.Component {
  renderSponsorship = () => {
    const { sponsorship, stubbed } = this.props

    if (!sponsorship) {
      return null
    }

    const { type, topic, sponsor, useCustomInfo, infoText } = sponsorship

    if (type === 'topic') {
      if (!stubbed) {
        return (
          <div className={[css.sponsorContainer, css.print].join(' ')}>
            <hr className={css.divider} />
            {useCustomInfo ? (
              <Subtitle3
                className={css.sponsorText}
                dangerouslySetInnerHTML={{ __html: infoText }}
              />
            ) : (
              <>
                <Subtitle3 className={css.sponsorText}>
                  This question is part of a special educational program about{' '}
                  {topic} supported by:
                </Subtitle3>
                <Subtitle1 className={css.sponsorSponsor}>
                  {sponsor.sponsor}
                </Subtitle1>
                <Subtitle3 className={css.sponsorText}>
                  {sponsor.shortName} had no input on the questions or answers
                  in this session. Learn more about these programs{' '}
                  <Link
                    pathname="/site/SEP"
                    external
                    className={css.sponsorLink}
                  >
                    here
                  </Link>
                  .
                </Subtitle3>
              </>
            )}
          </div>
        )
      }
      return (
        <>
          <Subtitle3 className={css.sponsorText}>
            This question is part of a special educational program about {topic}
          </Subtitle3>
          <Subtitle1 className={css.sponsorSponsor}> </Subtitle1>
          <Subtitle3 className={css.sponsorText}>
            Learn more about these programs{' '}
            <Link pathname="/site/SEP" external className={css.sponsorLink}>
              here
            </Link>
            .
          </Subtitle3>
        </>
      )
    }

    return null
  }

  render() {
    const {
      questionId,
      numViews,
      isAnonymous,
      userId,
      openShareModal,
      stubbed,
      vertical,
      sponsorship,
      allowCme = true,
    } = this.props

    return (
      <div>
        {!isAnonymous && (
          <div>
            <div className={css.postedByContainer}>
              <Subtitle3 className={css.postedBy}>Posted by:</Subtitle3>
              <Subtitle1>
                <UserNameLink userId={userId} className={css.name} />
              </Subtitle1>
              <FollowUserButton
                userId={userId}
                className={css.followButtonPrint}
                callLocation="question"
                questionId={questionId}
              />
            </div>
            <hr className={css.divider} />
          </div>
        )}
        <div className={css.viewsContainer}>
          <Subtitle3>This question has been viewed</Subtitle3>
          <Subtitle3 className={css.numViews}>{numViews} times</Subtitle3>
        </div>
        <div className={css.buttonsContainer}>
          <div className={css.buttonWrapper}>
            <FollowQuestionButton
              questionId={questionId}
              type="button"
              className={css.button}
              stubbed={stubbed}
            />
          </div>
          <div className={css.buttonWrapper}>
            <ShareButton
              openShareModal={openShareModal}
              className={css.button}
            />
          </div>
          <div className={css.buttonWrapper}>
            <GoodQuestion questionId={questionId} />
          </div>
          {!stubbed && allowCme && (!sponsorship || sponsorship.isPaid === 0) && (
            <div className={css.questionViewCmeContainer}>
              <QuestionViewCme questionId={questionId} vertical={vertical} />
            </div>
          )}
        </div>
        {this.renderSponsorship()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const questionData = state.question.questions[questionId]
  const { userId, isAnonymous, isFollowed, numViews, sponsorship } =
    questionData
  const metadataRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_QUESTION_METADATA, questionId)
    ) || {}

  return {
    userId,
    isAnonymous,
    isFollowed,
    numViews,
    sponsorship,
    metadataIsLoaded: metadataRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { questionId } = ownProps

  return {
    openShareModal: () =>
      dispatch(openModal(SHARE_MODAL.modalId, { questionId })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionActions)
