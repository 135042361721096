import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Subtitle3, PageHeader, Link1 } from 'pharmacy/src/typography'

import { BaseQuestionLink } from 'pharmacy/src/navigation/questionLink'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { TopicLink } from 'pharmacy/src/navigation/topicLink'
import { QuestionHighlight } from 'pharmacy/src/question/questionHighlight'
import { QuestionActions } from 'pharmacy/src/question/questionActions'
import { RawBody } from 'pharmacy/src/display/rawBody'
import { QuestionCreatedWarning } from 'pharmacy/src/question/questionCreatedWarning'
import { authItems } from 'mednet-util/src/constants/permission'
import { Button } from 'pharmacy/src/input/button'
import Stub from 'pharmacy/src/navigation/stub/stub'

import QuestionAnswerButtons from './questionAnswerButtons'

import css from './questionInformation.scss'

class QuestionInformation extends React.Component {
  renderEditButton = () => {
    const { questionId, enableEdit, permissions } = this.props

    if (enableEdit === false) {
      return false
    }

    if (permissions && _.includes(permissions.items, authItems.updateComment)) {
      return (
        <Link1 className={css.headerLink}>
          <Button
            icon={['far', 'edit']}
            external
            className={css.editButton}
            pathname={`/question/update/${questionId}`}
            type="text"
          >
            Edit
          </Button>
        </Link1>
      )
    }

    return null
  }

  render() {
    const {
      question,
      detail,
      topics,
      questionId,
      detailProps,
      slug,
      stubbed,
      allowCme = true,
    } = this.props

    return (
      <div>
        <QuestionCreatedWarning questionId={questionId} />
        <QuestionHighlight questionId={questionId} theme="page" />
        <div className={[css.headerContainer, css.print].join(' ')}>
          <PageHeader>
            <span>{question}</span>
            <BaseQuestionLink
              slug={slug}
              questionId={questionId}
              className={css.printQuestionHeader}
            >
              {question}
            </BaseQuestionLink>
          </PageHeader>
          {this.renderEditButton()}
        </div>
        <RawBody body={detail} {...detailProps} />
        <QuestionAnswerButtons questionId={questionId} />
        <ErrorBoundary>
          {topics && (
            <div className={css.topicList}>
              <Subtitle3>Topics:</Subtitle3>
              {topics.map((topicObject) => (
                <Link1 key={topicObject.topicId}>
                  {stubbed ? (
                    <Stub className={css.topicLink}>{topicObject.topic}</Stub>
                  ) : (
                    <TopicLink
                      topicObject={topicObject}
                      className={css.topicLink}
                    />
                  )}
                </Link1>
              ))}
            </div>
          )}
        </ErrorBoundary>
        <div className={css.actionsContainer}>
          <ErrorBoundary>
            <QuestionActions
              questionId={questionId}
              stubbed={stubbed}
              allowCme={allowCme}
            />
          </ErrorBoundary>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const questionData = state.question.questions[questionId]
  const { question, detail, topics, numAnswers, slug } = questionData

  return {
    question,
    slug,
    detail,
    topics,
    numAnswers,
    questionId,
    permissions: state.user.permissions,
  }
}

export default connect(mapStateToProps)(QuestionInformation)
