import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { DropdownMenu } from 'pharmacy/src/input/dropdownMenu'
import { Button } from 'pharmacy/src/input/button'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { parseQueryString } from 'mednet-util/src/router'

import {
  CREATE_QUESTION_REQUEST,
  createQuestionRequest,
} from 'mednet-cns/src/reducers/question'
import { QUESTION_REQUEST_MODAL } from 'mednet-util/src/constants/modal'
import { makeRequestName } from 'mednet-cns/src/reducers/request'

class RequestAnswersButton extends React.Component {
  handleRequestAnswers = () => {
    const { questionId } = this.props
    this.props.openQuestionRequestModal(
      QUESTION_REQUEST_MODAL.screens.confirmation
    )

    const confirmationFormdata = new FormData()
    confirmationFormdata.append('question_id', questionId)
    confirmationFormdata.append('type', 'confirmation')
    this.props.createQuestionRequest(confirmationFormdata)
  }

  handleRequestMoreAnswers = () => {
    this.props.openQuestionRequestModal(
      QUESTION_REQUEST_MODAL.screens.moreAnswers
    )
  }

  handleRequestUpdatedAnswers = () => {
    this.props.openQuestionRequestModal(
      QUESTION_REQUEST_MODAL.screens.updatedAnswers
    )
  }

  render() {
    const { numAnswers, questionRequestWasCreated, isNewQuestion, className } =
      this.props

    if (questionRequestWasCreated || isNewQuestion) {
      return null
    }

    if (numAnswers > 0) {
      return (
        <DropdownMenu
          HeaderComponent={Button}
          headerComponentProps={{ type: 'secondary', className }}
          item={{
            header: 'Request Answers',
            children: [
              {
                label: 'I want more opinions',
                onClick: this.handleRequestMoreAnswers,
              },
              {
                label: 'I want more updated answers',
                onClick: this.handleRequestUpdatedAnswers,
              },
            ],
          }}
        />
      )
    }

    return (
      <Button
        type="secondary"
        onClick={this.handleRequestAnswers}
        className={className}
      >
        Request Answers
      </Button>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const { numAnswers } = state.question.questions[questionId]
  const createQuestionRequest =
    _.get(
      state.request.requests,
      makeRequestName(CREATE_QUESTION_REQUEST, questionId)
    ) || {}

  const params = parseQueryString(state.router.location.search)
  const isNewQuestion = params.action === 'new_question'

  return {
    numAnswers,
    questionRequestWasCreated: createQuestionRequest.isLoaded,
    isNewQuestion,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { questionId } = ownProps

  return {
    openQuestionRequestModal: (screen) =>
      dispatch(
        openModal(QUESTION_REQUEST_MODAL.modalId, { questionId }, screen)
      ),
    createQuestionRequest: (formData) =>
      dispatch(createQuestionRequest(ownProps.questionId, formData)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestAnswersButton)
