import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { formatDate, parseDate } from 'react-day-picker/moment'

import DatePicker from './datePicker'

import css from './datePicker.scss'

class DateRangePicker extends React.Component {
  static defaultProps = {
    onChange: _.noop,
    toDisabledDays: (from) => ({ before: from }),
    fromDisabledDays: (_from, to) => ({ after: to }),
  }

  constructor(props) {
    super(props)
    const { fromProps = {}, toProps = {} } = props
    const { defaultValue: defaultFrom = '' } = fromProps
    const { defaultValue: defaultTo = '' } = toProps

    this.toPicker = React.createRef()

    this.handleFromChange = this.handleFromChange.bind(this)
    this.handleToChange = this.handleToChange.bind(this)

    this.state = {
      from:
        typeof defaultFrom === 'string'
          ? parseDate(defaultFrom, 'YYYY-MM-DD')
          : defaultFrom,
      to:
        typeof defaultTo === 'string'
          ? parseDate(defaultTo, 'YYYY-MM-DD')
          : defaultTo,
    }
  }

  showFromMonth() {
    const { from, to } = this.state
    if (!from) {
      return
    }

    if (moment(to).diff(moment(from), 'months') < 2) {
      this.toPicker.current.getDayPicker().showMonth(from)
    }
  }

  handleFromChange(from) {
    const { to } = this.state
    this.setState({ from }, () => {
      this.props.onChange({ to, from })
    })
  }

  handleToChange(to) {
    const { from } = this.state
    this.setState({ to }, () => {
      this.props.onChange({ to, from })
      this.showFromMonth()
    })
  }

  handleFromSelect = () => {
    this.toPicker.current.getInput().focus()
  }

  render() {
    const {
      fromDisabledDays = {},
      fromMonth,
      toDisabledDays = {},
      fromProps = {},
      toProps = {},
    } = this.props
    const { defaultValue: defaultFrom = '' } = fromProps
    const { defaultValue: defaultTo = '' } = toProps
    const { from, to } = this.state

    const modifiers = { [css.start]: from, [css.end]: to }

    return (
      <div className={css.rangeContainer}>
        <DatePicker
          value={from}
          placeholder="From"
          defaultValue={defaultFrom}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: fromDisabledDays(from, to),
            fromMonth,
            toMonth: to,
            modifiers,
            onDayClick: this.handleFromSelect,
          }}
          onChange={this.handleFromChange}
          {...fromProps}
        />
        <span className={css.divider}>—</span>
        <DatePicker
          innerRef={this.toPicker}
          value={to}
          defaultValue={defaultTo}
          placeholder="To"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: toDisabledDays(from, to),
            modifiers,
            month: from,
            fromMonth: from,
          }}
          onChange={this.handleToChange}
          {...toProps}
        />
      </div>
    )
  }
}

export default DateRangePicker
