import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { makeAssetURL } from 'mednet-util/src/router'
import { Label1 } from 'pharmacy/src/typography'

import css from './questionHighlight.scss'

export class HighlightContainer extends React.Component {
  render() {
    const { description, icon, theme } = this.props

    const containerClasses = classNames(
      css.container,
      css[`container_${theme}`]
    )

    return (
      <div className={containerClasses}>
        <span className={css.iconContainer}>{icon}</span>
        <Label1>{description}</Label1>
      </div>
    )
  }
}

export class GenericHighlight extends React.Component {
  renderIcon() {
    const { icon, shortName } = this.props

    if (!icon && shortName) {
      return <div className={css.defaultIcon}>{shortName[0]}</div>
    }

    if (icon) {
      return (
        <img className={css.imageIcon} src={makeAssetURL(this.props.icon)} />
      )
    }

    return null
  }

  render() {
    const { description } = this.props

    return (
      <HighlightContainer icon={this.renderIcon()} description={description} />
    )
  }
}

class QuestionHighlight extends React.Component {
  render() {
    const { tumorBoard, journal, sponsorship, topicIcon } = this.props

    if (tumorBoard) {
      return <GenericHighlight {...tumorBoard} />
    }

    if (journal) {
      return <GenericHighlight {...journal} />
    }

    if (sponsorship && sponsorship.emblem) {
      return <GenericHighlight {...sponsorship} />
    }

    if (topicIcon) {
      return <GenericHighlight {...topicIcon} />
    }
    return null
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const { tumorBoard, journal, sponsorship, topicIcon } =
    state.question.questions[questionId] || {}

  return {
    tumorBoard,
    journal,
    sponsorship,
    topicIcon,
  }
}

export default connect(mapStateToProps)(QuestionHighlight)
