import React, { useState } from 'react'

import classNames from 'classnames'

import { Link } from 'pharmacy/src/navigation/link'
import StarLoader from 'pharmacy/src/misc/loaders/starLoader/starLoader'

import { makeAssetURL } from 'mednet-util/src/router'

import { useSpecialtiesPreviewEditors } from 'mednet-cns/src/hooks/editor'

import { LandingPageContent } from 'components/landingPageContent'
import { PageTitle } from 'components/pageTitle'
import { HighlightsBar } from 'components/highlightsBar'
import { SpecialtiesUsersPreviewList } from 'components/specialtiesUsersPreviewList'

import css from './communityOverview.scss'

const assetsPath = `images/community/`

const HorizontalStepsSection = () => {
  return (
    <div
      className={classNames(
        classNames(css.stepsSection, css.displayForLargeScreen)
      )}
    >
      <div className={css.step}>
        <div className={css.stepNumber}>1</div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 1.png`)} />
          <img
            src={makeAssetURL(
              `${assetsPath}how it works arrow 1 - desktop.png`
            )}
            className={css.arrow1}
          />
        </div>
        <div className={classNames(css.stepSummary, css.stepSummary1)}>
          Submit your question to theMednet.
        </div>
      </div>

      <div className={css.step}>
        <div className={css.stepNumber}>2</div>
        <div className={classNames(css.stepSummary, css.stepSummary2)}>
          Physician editors review and refine your question and identify the
          right experts to answer it.
        </div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 2.png`)} />
          <img
            src={makeAssetURL(
              `${assetsPath}how it works arrow 2 - desktop.png`
            )}
            className={css.arrow2}
          />
        </div>
        <Link pathname="/editors">
          <div className={css.moderationLink}>Learn More About Moderation</div>
        </Link>
      </div>

      <div className={css.step}>
        <div className={css.stepNumber}>3</div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 3.png`)} />
          <img
            src={makeAssetURL(
              `${assetsPath}how it works arrow 3 - desktop.png`
            )}
            className={css.arrow3}
          />
        </div>
        <div className={classNames(css.stepSummary, css.stepSummary3)}>
          Experts respond to your question.
        </div>
      </div>

      <div className={css.step}>
        <div className={css.stepNumber}>4</div>
        <div className={classNames(css.stepSummary, css.stepSummary4)}>
          Q&A is published on theMednet and can now be searched and read by
          physicians in theMednet community.
        </div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 4.png`)} />
        </div>
      </div>
    </div>
  )
}

const VerticalStepsSection = () => {
  return (
    <div
      className={classNames(
        classNames(css.stepsSection, css.displayForSmallScreen)
      )}
    >
      <div className={css.step}>
        <div className={css.stepNumber}>1</div>
        <div className={classNames(css.stepSummary, css.stepSummary1)}>
          Submit your question to theMednet.
        </div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 1.png`)} />
        </div>
      </div>

      <div className={css.verticalArrow}>
        <img
          src={makeAssetURL(`${assetsPath}how it works vertical arrow.png`)}
        />
      </div>

      <div className={css.step}>
        <div className={css.stepNumber}>2</div>
        <div className={classNames(css.stepSummary, css.stepSummary2)}>
          Physician editors review and refine your question and identify the
          right experts to answer it.
        </div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 2.png`)} />
        </div>
        <Link pathname="/editors">
          <div className={css.moderationLink}>Learn More About Moderation</div>
        </Link>
      </div>

      <div className={css.verticalArrow}>
        <img
          src={makeAssetURL(`${assetsPath}how it works vertical arrow.png`)}
        />
      </div>

      <div className={css.step}>
        <div className={css.stepNumber}>3</div>
        <div className={classNames(css.stepSummary, css.stepSummary3)}>
          Experts respond to your question.
        </div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 3.png`)} />
        </div>
      </div>

      <div className={css.verticalArrow}>
        <img
          src={makeAssetURL(`${assetsPath}how it works vertical arrow.png`)}
        />
      </div>

      <div className={css.step}>
        <div className={css.stepNumber}>4</div>
        <div className={classNames(css.stepSummary, css.stepSummary4)}>
          Q&A is published on theMednet and can now be searched and read by
          physicians in theMednet community.
        </div>
        <div className={css.stepImage}>
          <img src={makeAssetURL(`${assetsPath}how it works step 4.png`)} />
        </div>
      </div>
    </div>
  )
}

const AboutModerationTitle = () => {
  return <div className={css.moderationTitle}>ABOUT MODERATION</div>
}

const EditorsSection = () => {
  const [{ specialties, specialtiesEditorsIds }, editorsRequest] =
    useSpecialtiesPreviewEditors()

  const [specialtyIdFilter, setSpecialtyIdFilter] = useState('oncology_merged')

  if (!editorsRequest.isLoaded) {
    return <StarLoader />
  }

  return (
    <div className={css.editorsSection}>
      <div className={css.editorsTitle}>OUR EDITORS</div>
      <SpecialtiesUsersPreviewList
        specialtiesUsersIds={specialtiesEditorsIds}
        specialtyIdFilter={specialtyIdFilter}
        setSpecialtyIdFilter={setSpecialtyIdFilter}
        specialties={specialties}
      />
      <div className={css.allEditorsButtonContainer}>
        <Link
          className={css.editorsButton}
          pathname="/editors"
          search={
            !isNaN(specialtyIdFilter)
              ? { specialtyId: specialtyIdFilter }
              : undefined
          }
        >
          View List of All Editors
        </Link>
      </div>
    </div>
  )
}

export const CommunityOverview = () => {
  return (
    <LandingPageContent>
      <PageTitle>How it Works</PageTitle>
      <div className={css.communitySummary}>
        All content is moderated by{' '}
        <span className={css.blueText}>medical professionals</span> to uphold
        high quality and professionalism.
      </div>

      <HorizontalStepsSection />
      <VerticalStepsSection />

      <HighlightsBar header={<AboutModerationTitle />} noLine>
        <div className={css.moderationText}>
          Every day new questions are peer-reviewed and put through our rigorous
          editorial and moderation process. Editors are responsible for
          maintaining quality and professionalism on the platform by moderating
          questions and engaging key opinion leaders in their field.
        </div>
      </HighlightsBar>

      <EditorsSection />
    </LandingPageContent>
  )
}
