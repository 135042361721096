import React from 'react'
import classNames from 'classnames'

import { Button } from 'pharmacy/src/input/button'

import css from './headerRegisterButton.scss'

export const HeaderRegisterButton = () => {
  return (
    <>
      <Button
        type="dark_blue"
        className={classNames(css.button, css.displayForLargeScreen)}
        pathname="/register"
      >
        <div className={css.buttonText}>Register</div>
      </Button>
      <Button
        type="orange"
        className={classNames(css.button, css.displayForSmallScreen)}
        pathname="/register"
      >
        <div className={css.buttonText}>Register</div>
      </Button>
    </>
  )
}
