import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { ConfirmationScreen, Modal } from 'pharmacy/src/display/modal'
import { SPONSORSHIP_UPDATE_MODAL } from 'mednet-util/src/constants/modal'
import { Header1 } from 'pharmacy/src/typography'
import { FETCH_SPONSORS, fetchSponsors } from 'mednet-cns/src/reducers/sponsor'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { getRequest } from 'mednet-cns/src/api/v1'
import {
  FETCH_EXPERTS,
  fetchExperts,
  updateSponsorship,
} from 'mednet-cns/src/reducers/sponsorship'

import SponsorshipForm, { SponsorshipSchema } from '../forms/sponsorshipForm'

class FinishedScreen extends React.Component {
  render() {
    const { closeModal } = this.props

    return (
      <ConfirmationScreen
        header="Sponsorship updated"
        closeModal={closeModal}
      />
    )
  }
}

class UpdateScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  componentDidMount() {
    this.props.fetchSponsors()
    this.props.fetchExperts()
  }

  handleSubmit = (values, actions) => {
    const { updateSponsorship, changeModalScreen } = this.props

    const formData = new FormData(this.form.current)
    formData.set('infoText', values.infoText)
    updateSponsorship(formData, () => {
      actions.setSubmitting(false)
      changeModalScreen(SPONSORSHIP_UPDATE_MODAL.screens.finished)
    })
  }

  render() {
    const { sponsorsIsLoaded, sponsorship, expertsIsLoaded } = this.props
    const {
      description,
      sponsor,
      topic,
      topicId,
      topics,
      emblem,
      emblemText,
      editor,
      experts,
      startDate,
      endDate,
      useCustomInfo,
      infoText,
      releaseDate,
      isPaid,
    } = sponsorship

    if (!sponsorsIsLoaded || !expertsIsLoaded) {
      return <StarLoader />
    }

    return (
      <div>
        <Header1>Edit sponsorship</Header1>
        <hr />
        <Formik
          initialValues={{
            description,
            sponsor,
            topic: {
              topicId,
              topic,
            },
            topics,
            experts,
            editor,
            emblem,
            emblemText,
            useCustomInfo: Boolean(useCustomInfo),
            infoText,
            dates: {
              from: startDate,
              to: endDate,
            },
            releaseDate,
            isPaid: Boolean(isPaid),
          }}
          onSubmit={this.handleSubmit}
          validationSchema={SponsorshipSchema}
        >
          {(props) => (
            <SponsorshipForm
              {...props}
              {...this.props}
              formRef={this.form}
              submitText="Update"
            />
          )}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sponsorshipId } = ownProps
  const sponsorsRequest = getRequest(state, FETCH_SPONSORS)
  const expertsRequest = getRequest(state, FETCH_EXPERTS, sponsorshipId)

  return {
    sponsors: state.sponsor.sponsors,
    sponsorship: state.sponsorship.sponsorships[sponsorshipId],
    sponsorsIsLoaded:
      sponsorsRequest.isLoaded === true && sponsorsRequest.isLoading === false,
    expertsIsLoaded:
      expertsRequest.isLoaded === true && expertsRequest.isLoading === false,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps

  return {
    fetchSponsors: () => dispatch(fetchSponsors()),
    fetchExperts: () => dispatch(fetchExperts(sponsorshipId)),
    updateSponsorship: (formData, callback) =>
      dispatch(updateSponsorship(sponsorshipId, formData, callback)),
  }
}

const ConnectedUpdateScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateScreen)

const screens = {
  [SPONSORSHIP_UPDATE_MODAL.screens.create]: ConnectedUpdateScreen,
  [SPONSORSHIP_UPDATE_MODAL.screens.finished]: FinishedScreen,
}

class EditSponsorshipModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop={false}
        defaultScreen={SPONSORSHIP_UPDATE_MODAL.screens.create}
        modalId={SPONSORSHIP_UPDATE_MODAL.modalId}
        screens={screens}
        {...this.props}
      />
    )
  }
}

export default EditSponsorshipModal
