import React, { useMemo, useState } from 'react'

import {
  CenteredContent,
  FlexRow,
  FlexRowCell,
} from 'pharmacy/src/display/content'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { useSpecialtiesExperts } from 'mednet-cns/src/hooks/experts'

import { useSpecialties } from 'mednet-cns/src/hooks/specialty'

import { isEmpty } from 'lodash'

import { useSelectUserById } from 'mednet-cns/src/hooks/user'

import { SpecialtyFilters } from 'components/specialtyFilters'
import { PageTitle } from 'components/pageTitle'
import { TitledSection } from 'components/titledSection'
import { UserPhotoCard } from 'components/userPhotoCard'
import { LandingPageContent } from 'components/landingPageContent'

import css from './experts.scss'

const renderExpertsIntroduction = (expertsCount) => {
  return (
    <CenteredContent size="large" className={css.expertsIntroductionContainer}>
      <TitledSection header2="theMednet Experts" headerUnderlined>
        <p>
          More than {expertsCount.toLocaleString()} experts from top academic
          institutions answer questions on theMednet. Questions are assigned to
          experts based on their research, publications, clinical trials, case
          volume, and peer recommendation. All answers are peer-reviewed on our
          site to ensure the highest standards. Experts are not provided with
          monetary incentives to participate.
        </p>
        <p>Below is the list of our expert contributors.</p>
      </TitledSection>
    </CenteredContent>
  )
}

const ExpertCard = ({ expertId }) => {
  const expert = useSelectUserById(expertId)
  return (
    <FlexRowCell>
      <UserPhotoCard
        size="small"
        showInstitution
        user={expert}
        viewBio={false}
        horizontalInSmallScreens
      />
    </FlexRowCell>
  )
}

const renderExperts = (filteredSpecialty, expertsIds) => {
  return (
    <TitledSection header3={filteredSpecialty.specialty}>
      <FlexRow size="small">
        {expertsIds.map((expertId) => (
          <ExpertCard key={expertId} expertId={expertId} />
        ))}
      </FlexRow>
    </TitledSection>
  )
}

export const Experts = (props) => {
  const radiationOncologySpecialtyID = 2
  const params = new URLSearchParams(props.location.search)

  const [{ expertsCount, specialtiesExpertsIds }, expertsRequest] =
    useSpecialtiesExperts()

  const [specialties, specialtiesRequest] = useSpecialties()

  const [filteredSpecialtyId, setSpecialtyIdFilter] = useState(
    parseInt(params.get('specialtyId')) || radiationOncologySpecialtyID
  )

  const activeExpertsSpecialties = useMemo(
    () =>
      !isEmpty(specialties) && !isEmpty(specialtiesExpertsIds)
        ? Object.values(specialties).filter(
            (specialty) =>
              specialty.active && specialtiesExpertsIds[specialty.specialtyId]
          )
        : [],
    [specialties, specialtiesExpertsIds]
  )
  return (
    <LandingPageContent>
      <PageTitle>Experts</PageTitle>
      {renderExpertsIntroduction(expertsCount)}

      {!specialtiesRequest.isLoaded || !expertsRequest.isLoaded ? (
        <StarLoader isVerticalMargin />
      ) : (
        <React.Fragment>
          <SpecialtyFilters
            specialties={activeExpertsSpecialties}
            setSpecialtyFilter={setSpecialtyIdFilter}
            filteredSpecialtyId={filteredSpecialtyId}
          />
          {renderExperts(
            specialties[filteredSpecialtyId],
            specialtiesExpertsIds[filteredSpecialtyId]
          )}
        </React.Fragment>
      )}
    </LandingPageContent>
  )
}
