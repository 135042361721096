import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import algoliasearch from 'algoliasearch'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import endsWith from 'lodash/endsWith'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import noop from 'lodash/noop'
import some from 'lodash/some'

import { ContainedSelect } from 'pharmacy/src/input/select'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link } from 'pharmacy/src/navigation/link'
import { Button } from 'pharmacy/src/input/button'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Icon } from 'pharmacy/src/display/icon'
import {
  Header3,
  Subtitle1,
  Subtitle2,
  Subtitle3,
} from 'pharmacy/src/typography'

import { usePublicationSearchLazy } from 'mednet-cns/src/hooks/publication'
import { useUser } from 'mednet-cns/src/hooks/user'
import { useAddPaperToSponsorship } from 'mednet-cns/src/hooks/sponsorship'

import { PublicationSearch } from 'components/publicationSearch/publicationSearch'

import { AttachmentUpdateSubForm } from './attachmentUpdateSubForm'
import css from './addPaperForm.scss'

const SUPPORTED_FILE_FORMATS = {
  '.pdf': 'application/pdf',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx':
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

const fileFormatValidationArgs = [
  'fileFormat',
  `File format is not supported, must be one of: ${keys(
    SUPPORTED_FILE_FORMATS
  ).join(', ')}`,
  (value) => {
    if (!value) {
      return true
    }

    // IE11
    if (!value.type) {
      return some(Object.keys(SUPPORTED_FILE_FORMATS), (extension) =>
        endsWith(value.name, extension)
      )
    }

    return includes(Object.values(SUPPORTED_FILE_FORMATS), value.type)
  },
]

const validationSchema = Yup.object().shape({
  publicationId: Yup.string().required('Please choose a paper to add'),
  searchKey: Yup.string().required(),
  searchValue: Yup.string()
    .when(['searchKey'], {
      is: (searchKey) => searchKey === 'Pubmed ID',
      then: Yup.string()
        .matches(/^\d+$/, 'Pubmed ID should be a number')
        .required(),
    })
    .when(['searchKey'], {
      is: (searchKey) => searchKey === 'Publication ID',
      then: Yup.string()
        .matches(/^\d+$/, 'Publication ID should be a number')
        .required(),
    }),
  attachment: Yup.mixed()
    .test(...fileFormatValidationArgs)
    .when(['attachmentAction', 'pubmedId'], {
      is: (attachmentAction, pubmedId) =>
        attachmentAction === 'add' && !pubmedId,
      then: Yup.mixed()
        .required(
          'This paper does not have a Pubmed ID. Therefore it must have an attachment'
        )
        .test(...fileFormatValidationArgs),
    })
    .when(['attachmentAction'], {
      is: (attachmentAction) => attachmentAction === 'replace',
      then: Yup.mixed()
        .required('A replacement attachment was not uploaded')
        .test(...fileFormatValidationArgs),
    }),
})

const InternalAddPaperForm = ({
  isSubmitting,
  errors,
  values,
  handleSubmit,
  setFieldValue,
}) => {
  const [{ userData, permissions }, userRequest] = useUser()
  const [searchSubmitted, setSearchSubmitted] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [algoliaSearchIndex, setAlgoliaSearchIndex] = useState(undefined)
  const [searchPublicationsByTitleResult, setSearchPublicationsByTitleResult] =
    useState({})
  const [instantSearchSelectedSuggestion, setInstantSearchSelectedSuggestion] =
    useState(undefined)

  const [
    searchPublicationsByIdResult,
    searchPublicationsByIdRequest,
    searchPublicationsById,
  ] = usePublicationSearchLazy()

  const handleSearchKeyChange = useCallback((option) => {
    setFieldValue('searchKey', option.value)
    setFieldValue('searchValue', '')
  }, setFieldValue)

  const performAlgoliaSearch = useCallback(
    (page, searchValue) => {
      setSearchPublicationsByTitleResult({ isLoaded: false, isLoading: true })
      algoliaSearchIndex
        .search(searchValue, { page, hitsPerPage: 5 })
        .then(({ hits, nbPages, nbHits }) => {
          setSearchPublicationsByTitleResult({
            hits: hits.map((publication) => ({
              ...publication,
              publicationId: publication.publication_id,
            })),
            nbPages,
            nbHits,
            currentPage: page,
            isLoaded: true,
            isLoading: false,
          })
        })
    },
    [setSearchPublicationsByTitleResult, algoliaSearchIndex]
  )

  const handleSearchButtonClick = useCallback(() => {
    setSearchSubmitted(true)
    if (values.searchKey === 'Title') {
      performAlgoliaSearch(0, values.searchValue)
    } else {
      searchPublicationsById(values.searchKey, values.searchValue)
    }
  }, [
    setSearchSubmitted,
    performAlgoliaSearch,
    searchPublicationsById,
    values.searchKey,
    values.searchValue,
  ])

  const handleInstantSearchChange = useCallback(
    (value) => setFieldValue('searchValue', value),
    [setFieldValue]
  )

  const handleInstantSearchSuggestionSelect = useCallback(
    (event, suggestion) => {
      event.preventDefault() // Don't submit search yet, wait or next render when serachValue state is updated
      setFieldValue('searchValue', suggestion.title)
      setInstantSearchSelectedSuggestion(suggestion)
    },
    [setFieldValue, setInstantSearchSelectedSuggestion]
  )

  const handleInstantSearchEnterKey = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        setSearchSubmitted(true)
        performAlgoliaSearch(0, event.target.value)
      }
    },
    [performAlgoliaSearch]
  )

  const setAlgoliaIndex = useCallback(() => {
    if (userRequest.isLoaded && !algoliaSearchIndex) {
      const { algoliaApp, algoliaKey, algoliaNamespace } = userData
      const searchClient = algoliasearch(algoliaApp, algoliaKey)
      const index = searchClient.initIndex(`${algoliaNamespace}_publication`)
      setAlgoliaSearchIndex(index)
    }
  }, [
    userRequest.isLoaded,
    userData,
    setAlgoliaSearchIndex,
    algoliaSearchIndex,
  ])

  useEffect(setAlgoliaIndex, [])
  useEffect(setAlgoliaIndex, [userRequest.isLoaded])

  useEffect(() => {
    setSearchSubmitted(false)
    setFieldValue('publicationId', undefined)
  }, [values.searchKey, values.searchValue])

  useEffect(() => {
    setFormSubmitted(false)
  }, [values])

  useEffect(() => {
    if (
      values.searchKey === 'Title' &&
      instantSearchSelectedSuggestion &&
      instantSearchSelectedSuggestion.title === values.searchValue
    ) {
      setFieldValue(
        'publicationId',
        instantSearchSelectedSuggestion.publication_id
      )
      handleSearchButtonClick()
    }
  }, [instantSearchSelectedSuggestion])

  const searchKeys = useMemo(
    () => [
      { label: 'Title', value: 'Title' },
      { label: 'Publication ID', value: 'Publication ID' },
      { label: 'Pubmed ID', value: 'Pubmed ID' },
    ],
    []
  )

  const displaySearchResult = !errors['searchValue'] && searchSubmitted

  return (
    <Form>
      <Header3 className={css.searchTitle}>Search existing papers</Header3>

      <div className={css.inpuContainer}>
        <Field name="searchKey">
          {({ field }) => (
            <ContainedSelect
              name={field.name}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder="Search By"
              options={searchKeys}
              onChange={handleSearchKeyChange}
              value={Object.values(searchKeys).find(
                (specialty) => specialty.value === field.value
              )}
              onBlur={field.onBlur}
              isSearchable={false}
              formInput
              className={css.select}
            />
          )}
        </Field>
        <div className={css.textInputContainer}>
          {values.searchKey === 'Title' && (
            <PublicationSearch
              userData={userData}
              permissions={permissions}
              onSuggestionSelect={handleInstantSearchSuggestionSelect}
              inputProps={{
                name: 'searchValue',
                onKeyDown: handleInstantSearchEnterKey,
              }}
              onChange={handleInstantSearchChange}
              hideSuggestions={searchSubmitted}
              moderatorMode
            />
          )}
          {values.searchKey !== 'Title' && (
            <Field name="searchValue">
              {({ field }) => (
                <TextInput
                  {...field}
                  showClear={false}
                  onChangeHandlesEvent
                  onKeyDown={(event) =>
                    event.key === 'Enter' && handleSearchButtonClick()
                  }
                />
              )}
            </Field>
          )}
        </div>
        <Button
          onClick={handleSearchButtonClick}
          isDisabled={isEmpty(values.searchValue)}
          isLoading={searchPublicationsByIdRequest.isLoading}
          className={css.searchButton}
          type="secondary"
        >
          Search
        </Button>
      </div>

      {displaySearchResult && values.searchKey === 'Title' && (
        <PublicationsSearchResult
          result={searchPublicationsByTitleResult.hits}
          request={{
            isLoaded: searchPublicationsByTitleResult.isLoaded,
            isLoading: searchPublicationsByTitleResult.isLoading,
          }}
          values={values}
          setFieldValue={setFieldValue}
          resultCount={searchPublicationsByTitleResult.nbHits}
          resultPagesCount={searchPublicationsByTitleResult.nbPages}
          currentPage={searchPublicationsByTitleResult.currentPage}
          loadPageInResult={(page) =>
            performAlgoliaSearch(page, values.searchValue)
          }
        />
      )}

      {displaySearchResult && values.searchKey !== 'Title' && (
        <PublicationsSearchResult
          result={searchPublicationsByIdResult}
          request={searchPublicationsByIdRequest}
          values={values}
          setFieldValue={setFieldValue}
          resultCount={searchPublicationsByIdResult.length}
          resultPagesCount={1}
          currentPage={0}
          loadPageInResult={noop}
        />
      )}

      {/* To prevent elements from overlapping the footer with position absolute */}
      <div className={css.invisibleFooterPlaceHolder} />
      <div className={css.footer}>
        {errors['searchValue'] && searchSubmitted && (
          <Subtitle2 className={css.errorMessage}>
            {errors['searchValue']}
          </Subtitle2>
        )}

        {!isEmpty(errors) && !errors['searchValue'] && formSubmitted && (
          <Subtitle2 className={css.errorMessage}>
            {Object.values(errors)[0]}
          </Subtitle2>
        )}

        <Button
          className={css.button}
          isLoading={isSubmitting}
          onClick={(...args) => {
            setFormSubmitted(true)
            handleSubmit(args)
          }}
          isDisabled={!searchSubmitted}
        >
          Add Paper
        </Button>
      </div>
    </Form>
  )
}

const PagingTool = ({ currentPage, resultPagesCount, loadPageInResult }) => {
  const loadNextPage = useCallback(() => {
    currentPage > 0 && loadPageInResult(currentPage - 1)
  }, [loadPageInResult, currentPage])

  const loadPreviousPage = useCallback(() => {
    currentPage < resultPagesCount - 1 && loadPageInResult(currentPage + 1)
  }, [loadPageInResult, currentPage, resultPagesCount])

  return (
    <div className={css.pagingTools}>
      <Subtitle1>
        <Icon
          icon={['fas', 'arrow-left']}
          className={css.prevPageIcon}
          onClick={loadNextPage}
        />{' '}
        Page {currentPage + 1} out of {resultPagesCount}{' '}
        <Icon
          icon={['fas', 'arrow-right']}
          className={css.nextPageIcon}
          onClick={loadPreviousPage}
        />
      </Subtitle1>
    </div>
  )
}

const PublicationCandidate = ({
  publication,
  className,
  onChange,
  defaultChecked,
}) => {
  return (
    <div className={classNames(css.publication, className)}>
      <div>
        <Field
          type="radio"
          name="publicationId"
          id={`publicationId_${publication.publicationId}`}
          value={publication.publicationId}
          defaultChecked={defaultChecked}
          // onChange does not work here, had to use onclick
          onClick={onChange}
        />
      </div>

      <Subtitle3>
        <label htmlFor={`publicationId_${publication.publicationId}`}>
          {publication.title}{' '}
        </label>
        <Link
          external
          pathname={`/publication/moderatorDownload/${publication.publicationId}`}
          target="_blank"
        >
          <Icon icon={['fas', 'link']} />
        </Link>
        <div className={css.publicationDetails}>
          {publication.journal}, {publication.date}
        </div>
      </Subtitle3>
    </div>
  )
}

const PublicationsSearchResult = ({
  result,
  resultCount,
  resultPagesCount,
  currentPage,
  request,
  values,
  setFieldValue,
  loadPageInResult,
}) => {
  const handlePublicationChoice = useCallback(
    (event) => {
      const publicationId = event.target.value
      setFieldValue('publicationId', publicationId)
    },
    [setFieldValue]
  )

  const handlePublicationChange = useCallback(() => {
    if (!request.isLoading && request.isLoaded && values.publicationId) {
      const publication = result.find(
        (publication) =>
          parseInt(values.publicationId) === publication.publicationId
      )

      if (!publication) {
        // This happens when the search key changes but the form is not reset yet in this render - old publication id
        return
      }

      setFieldValue('pubmedId', publication.pubmedId)
      setFieldValue('attachment', '')

      // Only check for file location and not access, because we want to view attachment even if sponsorship is not active yet
      if (!isEmpty(publication.fileLocation)) {
        setFieldValue('attachmentAction', 'none')
      } else {
        setFieldValue('attachmentAction', 'add')
      }
    }
  }, [
    request.isLoaded,
    request.isLoading,
    values.publicationId,
    result,
    setFieldValue,
  ])

  useEffect(handlePublicationChange, [
    values.publicationId,
    request.isLoaded,
    request.isLoading,
  ])

  useEffect(() => {
    if (request.isLoaded && !request.isLoading && resultCount === 1) {
      setFieldValue('publicationId', result[0].publicationId)
    }
  }, [request.isLoaded, request.isLoading, resultCount])

  if (request.isLoading) {
    return <StarLoader />
  }

  if (!request.isLoaded) {
    return null
  }

  if (isEmpty(result) || resultCount <= 0) {
    return (
      <Subtitle1 className={classNames(css.results, css.resultsContainer)}>
        No matches were found.
      </Subtitle1>
    )
  }

  if (values.publicationId) {
    const publication = result.find(
      (publication) =>
        parseInt(values.publicationId) === publication.publicationId
    )

    if (!publication) {
      // This happens when the search key changes but the form is not reset yet in this render - old publication id
      return null
    }

    return (
      <div className={css.resultsContainer}>
        <PublicationCandidate
          publication={publication}
          className={css.results}
          defaultChecked
        />
        {resultCount > 1 && (
          <div className={css.cancelButtonConatiner}>
            <Button
              type="neutral"
              size="small"
              className={css.cancelButton}
              onClick={() => setFieldValue('publicationId', undefined)}
            >
              Cancel
            </Button>
          </div>
        )}
        <AttachmentUpdateSubForm
          publication={publication}
          values={values}
          setFieldValue={setFieldValue}
        />
      </div>
    )
  }

  return (
    <div className={css.resultsContainer}>
      <div className={css.results}>
        <Subtitle1 className={css.searchResult}>
          {`${resultCount} matches were found. Please choose the publication you would like to use from the matches below:`}
        </Subtitle1>
        {result.map((publication) => (
          <PublicationCandidate
            key={publication.publicationId}
            publication={publication}
            onChange={handlePublicationChoice}
          />
        ))}
      </div>
      {resultPagesCount > 1 && (
        <PagingTool
          currentPage={currentPage}
          resultPagesCount={resultPagesCount}
          loadPageInResult={loadPageInResult}
        />
      )}
    </div>
  )
}

const AddPaperForm = ({ sponsorshipId, closeModal }) => {
  const [submissionResultFailed, setSubmissionResultFailed] = useState(false)
  const addPaperToSponsorship = useAddPaperToSponsorship()

  const handleFormSubmit = (values, { setSubmitting }) => {
    addPaperToSponsorship(
      {
        sponsorshipId,
        publicationId: values.publicationId,
        attachment: values.attachment,
        attachmentAction: values.attachmentAction,
      },
      (res) => {
        setSubmitting(false)
        if (res.success) {
          closeModal()
        } else {
          if (res.alreadyAdded) {
            setSubmissionResultFailed(
              'This publication is already added to this sponsorship. You can view/update it from the main sponsorship page.'
            )
          } else {
            setSubmissionResultFailed('Something went wrong. Please try again')
          }
        }
      }
    )
  }

  const initialValues = {
    searchKey: 'Title',
    searchValue: '',
    publicationId: undefined,
    attachment: undefined,
    openAccess: false,
    attachmentAction: 'none',
    pubmedId: '', // Added for yup validation
  }

  if (submissionResultFailed) {
    return (
      <div className={css.submissionFailureResult}>
        {submissionResultFailed}
      </div>
    )
  }

  return (
    <div className={css.form}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        {(formProps) => <InternalAddPaperForm {...formProps} />}
      </Formik>
    </div>
  )
}

export default AddPaperForm
