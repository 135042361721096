import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { Subtitle3, Header4 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { Publication } from 'pharmacy/src/display/publication'
import { mixpanel } from 'mednet-util/src/tracking'
import { publicationAccess } from 'mednet-util/src/constants/publication'
import { mednetAPI } from 'mednet-cns/src/api/v1'

import Stub from 'pharmacy/src/navigation/stub/stub'

import css from './citedPapers.scss'

class CitedPapers extends React.Component {
  static defaultProps = {
    type: 'text',
  }

  constructor(props) {
    super(props)

    this.state = {
      isExpanded: false,
    }
  }

  toggleExpand = () => {
    const { isExpanded } = this.state

    this.setState({
      isExpanded: !isExpanded,
    })
  }

  handleClick = (citedPaper) => () => {
    const { onClick } = this.props
    const { citeId, place } = citedPaper

    if (place === 'Q') {
      mednetAPI(`questionCitedPaper/clickTrackJSON/${citeId}`)
    } else if (place === 'A') {
      mednetAPI(`answerCitedpaper/clickTrackJSON/${citeId}`)
    } else {
      mednetAPI(`questionSponsorshipCitedPaper/clickTrackJSON/${citeId}`)
    }
    mixpanel.track('Clicked Citation', { id: citeId })

    if (onClick) {
      onClick(citedPaper)
    }
  }

  render() {
    const { isExpanded } = this.state
    const {
      citedPapers,
      className,
      type,
      LinkComponent,
      VideoComponent,
      questionId,
      userData,
      stubbed,
    } = this.props

    if (!citedPapers || !citedPapers.length) {
      return null
    }

    /**
     * Sort in place (array) for citations by this order:
     * - publications with sponsored access (with file) and with video -> sum = 101
     * - publications with sponsored access (with file) -> sum = 100
     * - publications with sponsored access (without file) and with video -> sum = 51 [Should never happen]
     * - publications with sponsored access (without file) -> sum = 50 [Should never happen]
     * - publications with open access and with video -> 11
     * - publications with open access -> 10
     * - publications with video -> 1
     * - The rest of publications -> 0
     */
    citedPapers.sort(
      ({ publication: publicationA }, { publication: publicationB }) => {
        const sumA =
          (publicationA.access === publicationAccess.SPONSORED && 100) +
            (publicationA.access === publicationAccess.SPONSORED_NO_FILE &&
              50) +
            (publicationA.access === publicationAccess.OPENED && 10) +
            (publicationA.videoURL && 1) || 0

        const sumB =
          (publicationB.access === publicationAccess.SPONSORED && 100) +
            (publicationB.access === publicationAccess.SPONSORED_NO_FILE &&
              50) +
            (publicationB.access === publicationAccess.OPENED && 10) +
            (publicationB.videoURL && 1) || 0
        return sumB - sumA
      }
    )
    const citedPapersToShow = isExpanded ? citedPapers : citedPapers.slice(0, 3)
    const headerClasses = classNames(css.header, css[`header_${type}`])

    return (
      <div className={className}>
        <Header4 className={headerClasses}>Cited Papers</Header4>
        {citedPapersToShow.map((citedPaper) => (
          <div className={css.paperContainer} key={citedPaper.citeId}>
            <Publication
              userId={userData.userId}
              item={citedPaper.publication}
              questionId={questionId}
              citeId={citedPaper.citeId}
              place={citedPaper.place}
              LinkComponent={stubbed ? Stub : LinkComponent}
              VideoComponent={VideoComponent}
              onClick={this.handleClick(citedPaper)}
            />
          </div>
        ))}
        {citedPapers.length > 3 && (
          <Subtitle3>
            <Button
              className={css.toggleExpand}
              icon={isExpanded ? 'minus' : 'plus'}
              onClick={this.toggleExpand}
              type="text"
            >
              Show {isExpanded ? 'less' : 'more'}
            </Button>
          </Subtitle3>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { citedPapers } = state.question.questions[ownProps.questionId] || {}

  return {
    userData: state.user.data,
    citedPapers,
  }
}

export default connect(mapStateToProps)(CitedPapers)
