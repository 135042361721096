import React from 'react'
import classNames from 'classnames'

import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'

import { Body1, Header3, Subtitle2, Subtitle3 } from 'pharmacy/src/typography'
import { Card } from 'pharmacy/src/display/card'
import { Button } from 'pharmacy/src/input/button'
import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { QuestionPoll } from 'pharmacy/src/question/questionPoll'

import { connect } from 'react-redux'

import { fetchViewsFromSpecialty } from 'mednet-cns/src/reducers/question'

import { LastUsedInfo, INFO_TYPES } from '../lastUsedInfo'
import { QuestionTopicTags } from '../questionTopicTags'

import css from './updateCard.scss'

class UpdateCard extends React.Component {
  state = {
    showMore: false,
  }

  toggleMore = () => {
    const { update, specialtyId, fetchViewsFromSpecialty, question } =
      this.props

    this.setState((state) => ({
      showMore: !state.showMore,
    }))

    if (
      !question.views ||
      (!question.views[specialtyId] && question.views[specialtyId] !== 0)
    ) {
      fetchViewsFromSpecialty(update.questionId, specialtyId)
    }
  }

  onAddUpdate = () => {
    const { onAddUpdate, update } = this.props
    if (onAddUpdate) {
      onAddUpdate(update.questionUpdateId, update.questionId)
    }
  }

  renderViews = () => {
    const { specialtyId, specialtyTitle, question } = this.props

    if (
      !question.views ||
      (!question.views[specialtyId] && question.views[specialtyId] !== 0)
    )
      return null

    return (
      <div className={css.stats}>
        <Subtitle2>{`Unique views in ${specialtyTitle}: ${question.views[specialtyId]}`}</Subtitle2>
        <Subtitle2>{`Unique views in total: ${question.views.total}`}</Subtitle2>
      </div>
    )
  }

  renderPoll() {
    const { update } = this.props
    const { showMore } = this.state

    if (update.actionId !== actionIdByName.POLL_APPROVED || !showMore) {
      return null
    }

    const { poll } = update
    poll.showResults = true
    poll.showVote = false

    return (
      <QuestionPoll
        questionId={update.questionId}
        poll={poll}
        className={css.poll}
        HeaderComponent={Header3}
        hideHeaderInfo
        hideExportToCSV
      />
    )
  }

  render() {
    const {
      update,
      className,
      updateIsLoading,
      hideInfo,
      onAddUpdate,
      specialtyId,
    } = this.props
    const { showMore } = this.state
    const question = update.question || this.props.question

    let details
    let body

    if (
      update.actionId === actionIdByName.ANSWER ||
      update.actionId === actionIdByName.ANSWER_UPDATED
    ) {
      details = update.answer
      body = details.answer
    } else if (update.actionId === actionIdByName.POLL_APPROVED) {
      details = update.poll
      body = details.pollQuestion
    }

    const shortBody =
      (body && body.length) > 150 ? `${body.substring(0, 149)}...` : body

    if (update.actionId !== actionIdByName.POLL_APPROVED) {
      body = showMore ? body : shortBody
    } else {
      body = showMore ? '' : shortBody
    }

    return (
      <Card
        className={classNames({
          [css.card]: true,
          [className]: true,
          [css.sponsored]: Boolean(question.sponsorship),
        })}
      >
        <div className={css.questionWrapper}>
          <div>
            {hideInfo ? null : (
              <LastUsedInfo
                updateId={update.questionUpdateId}
                type={INFO_TYPES.UPDATE}
                className={css.lastUsedInfo}
                mode="dailydigest"
                specialtyId={specialtyId}
              />
            )}
            <QuestionLink questionId={question.questionId} target="_blank">
              <Header3
                className={css.question}
                dangerouslySetInnerHTML={{
                  __html: question.short ? question.short : question.question,
                }}
              ></Header3>
            </QuestionLink>
            <QuestionTopicTags
              topics={question.topics}
              primary={question.primaryTopic}
            />
            {!showMore ? null : this.renderViews()}
          </div>
          {!onAddUpdate ? null : (
            <Button
              className={css.addButton}
              size="small"
              icon={['fal', 'plus']}
              onClick={this.onAddUpdate}
              isDisabled={updateIsLoading}
            />
          )}
        </div>
        {!details ? null : (
          <UserUpdateInformation
            user={details.user}
            dateUpdated={details.created}
            actionId={update.actionId}
          >
            <Body1
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            ></Body1>
          </UserUpdateInformation>
        )}
        {this.renderPoll()}
        <div className={css.showMore}>
          <Subtitle3>
            <Button
              type="secondaryText"
              size="medium"
              onClick={this.toggleMore}
            >
              {showMore ? 'show less' : 'show more'}
            </Button>
          </Subtitle3>
        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  question: state.question.questions[ownProps.update.questionId] || {},
})

const mapDispatchToProps = (dispatch) => ({
  fetchViewsFromSpecialty: (questionId, specialtyId) =>
    dispatch(fetchViewsFromSpecialty(questionId, specialtyId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCard)
