import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import {
  CenteredContent,
  FlexRow,
  FlexRowCell,
} from 'pharmacy/src/display/content'

import {
  getSpecialties,
  getSpecialtiesRequest,
  fetchSpecialties,
} from 'mednet-cns/src/reducers/specialty'
import {
  getFellows,
  getFellowsRequest,
  getFellowsSpecialtyFilter,
  fetchFellows,
  setFellowsSpecialtyFilter,
} from 'mednet-cns/src/reducers/editor'

import { PageTitle } from 'components/pageTitle'
import { TitledSection } from 'components/titledSection'
import { UserPhotoCard } from 'components/userPhotoCard'
import { SpecialtyFilters } from 'components/specialtyFilters'
import { ViewBioModaL } from 'components/viewBioModal'
import { LandingPageContent } from 'components/landingPageContent'

import css from './fellows.scss'

const renderFellowsIntroduction = () => {
  return (
    <CenteredContent size="large" className={css.introductionContainer}>
      <TitledSection header2="Fellows" headerUnderlined>
        theMednet Fellows help drive dialogue within our community by
        highlighting important research, topics of discussion, and best
        practices from their respective institutions. Fellows are invited to the
        program based on their commitment to medical education and a desire to
        share knowledge that improves patient outcomes.
      </TitledSection>
    </CenteredContent>
  )
}

const renderSpecialtyFellows = (
  specialtiesEditors,
  filteredSpecialty,
  showTitle
) => {
  return (
    <TitledSection header3={filteredSpecialty.specialty}>
      <FlexRow size="small">
        {specialtiesEditors[filteredSpecialty.specialtyId].map((editor) => (
          <FlexRowCell key={editor.userId}>
            <UserPhotoCard
              size="small"
              showTitle={showTitle}
              showInstitution
              user={editor}
              horizontalInSmallScreens
            />
          </FlexRowCell>
        ))}
      </FlexRow>
    </TitledSection>
  )
}

const checkIfFetchNeeded = (request) => {
  return !request.isLoading && !request.isLoaded
}

const getFilterSpecialty = (activeSpecialties, filterSpecialtyId) => {
  return activeSpecialties
    ? activeSpecialties[filterSpecialtyId ? filterSpecialtyId : 2]
    : undefined
}

const getSpecialtiesUsers = (specialtiesUsersIds, users) => {
  return specialtiesUsersIds
    ? Object.fromEntries(
        Object.entries(specialtiesUsersIds).map(([specialtyId, usersIds]) => [
          specialtyId,
          usersIds.map((userId) => users[userId]),
        ])
      )
    : undefined
}

const makeSelectEditors = () =>
  createSelector(
    (state) => getSpecialties(state),
    (state) => getFellows(state),
    (state) => state.user.users,
    (state) => getFellowsSpecialtyFilter(state),
    (state) => getSpecialtiesRequest(state),
    (state) => getFellowsRequest(state),
    (
      specialties,
      specialtiesFellowsIds,
      users,
      fellowsFilterSpecialtyId,
      specialtiesRequest,
      fellowsRequest
    ) => {
      const activeSpecialties = specialties
        ? Object.fromEntries(
            Object.entries(specialties).filter(
              ([_, specialty]) => specialty.active
            )
          )
        : undefined

      const specialtiesFellows = getSpecialtiesUsers(
        specialtiesFellowsIds,
        users
      )

      const filteredFellowsSpecialty = getFilterSpecialty(
        activeSpecialties,
        fellowsFilterSpecialtyId
      )

      return {
        specialties: activeSpecialties,
        specialtiesFellows,
        filteredFellowsSpecialty,
        specialtiesRequest,
        fellowsRequest,
      }
    }
  )

export const Fellows = () => {
  const selectEditors = useMemo(makeSelectEditors, [])
  const {
    specialties,
    specialtiesFellows,
    filteredFellowsSpecialty,
    specialtiesRequest,
    fellowsRequest,
  } = useSelector((state) => selectEditors(state))

  const dispatch = useDispatch()

  useEffect(() => {
    if (checkIfFetchNeeded(specialtiesRequest)) {
      dispatch(fetchSpecialties())
    }

    if (checkIfFetchNeeded(fellowsRequest)) {
      dispatch(fetchFellows())
    }
  })

  const fellowsSpecialties =
    specialtiesRequest.isLoaded && fellowsRequest.isLoaded
      ? Object.fromEntries(
          Object.entries(specialties).filter(
            ([specialtyId, _]) => specialtiesFellows[specialtyId]
          )
        )
      : undefined

  const dispatchSetFellowsSpecialtyFilter = (specialtyId) => {
    dispatch(setFellowsSpecialtyFilter(specialtyId))
  }

  return (
    <LandingPageContent>
      <PageTitle>Fellows</PageTitle>

      {renderFellowsIntroduction()}

      {!specialtiesRequest.isLoaded || !fellowsRequest.isLoaded ? (
        <StarLoader isVerticalMargin />
      ) : (
        <React.Fragment>
          {specialtiesRequest.isLoaded && fellowsRequest.isLoaded && (
            <SpecialtyFilters
              specialties={Object.values(fellowsSpecialties)}
              setSpecialtyFilter={dispatchSetFellowsSpecialtyFilter}
              filteredSpecialtyId={filteredFellowsSpecialty.specialtyId}
            />
          )}
          {renderSpecialtyFellows(
            specialtiesFellows,
            filteredFellowsSpecialty,
            false
          )}
        </React.Fragment>
      )}

      <ViewBioModaL />
    </LandingPageContent>
  )
}
