import { keyBy } from 'lodash'
import { makeURL } from 'mednet-util/src/router'
import { all, takeLatest } from 'redux-saga/effects'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_RECENT_CAMPAIGNS = 'campaign/FETCH_RECENT_CAMPAIGNS'
export const FETCH_UNSENT_CAMPAIGNS = 'campaign/FETCH_UNSENT_CAMPAIGNS'
export const FETCH_RECENT_INVITES = 'campaign/FETCH_RECENT_INVITES'
export const FETCH_UNSENT_INVITES = 'campaign/FETCH_UNSENT_INVITES'
export const FETCH_SERVER_SCRIPTS = 'campaign/FETCH_SERVER_SCRIPTS'
export const FETCH_SERVER_SCRIPT_EXAMPLE =
  'campaign/FETCH_SERVER_SCRIPT_EXAMPLE'

export function fetchRecentCampaigns(callback) {
  return {
    type: FETCH_RECENT_CAMPAIGNS,
    callback,
  }
}

export function fetchUnsentCampaigns(callback) {
  return {
    type: FETCH_UNSENT_CAMPAIGNS,
    callback,
  }
}

export function fetchRecentInvitationCampaigns(callback) {
  return {
    type: FETCH_RECENT_INVITES,
    callback,
  }
}

export function fetchUnsentInvitationCampaigns(callback) {
  return {
    type: FETCH_UNSENT_INVITES,
    callback,
  }
}

export function fetchServerScripts(callback) {
  return {
    type: FETCH_SERVER_SCRIPTS,
    callback,
  }
}

export function fetchServerScriptExample(name, callback) {
  return {
    type: FETCH_SERVER_SCRIPT_EXAMPLE,
    name,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, FETCH_RECENT_CAMPAIGNS, () =>
    makeURL(`campaign/getRecentCampaignsJSON`)
  )
  yield makeFetchEffect(takeLatest, FETCH_UNSENT_CAMPAIGNS, () =>
    makeURL(`campaign/getUnsentCampaignsJSON`)
  )
  yield makeFetchEffect(takeLatest, FETCH_RECENT_INVITES, () =>
    makeURL(`campaign/getRecentInvitationCampaignsJSON`)
  )
  yield makeFetchEffect(takeLatest, FETCH_UNSENT_INVITES, () =>
    makeURL(`campaign/getUnsentInvitationCampaignsJSON`)
  )
  yield makeFetchEffect(takeLatest, FETCH_SERVER_SCRIPTS, () =>
    makeURL(`campaign/getServerScriptsJSON`)
  )
  yield makeFetchEffect(takeLatest, FETCH_SERVER_SCRIPT_EXAMPLE, ({ name }) =>
    makeURL(`campaign/getServerScriptExampleJSON`, { name })
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  campaigns: {},
  unsentCampaigns: {},
  invites: {},
  unsentInvites: {},
  scripts: [],
  scriptExamples: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_RECENT_CAMPAIGNS): {
      return receiveReducer(state, action, () => ({
        campaigns: keyBy(action.response?.campaigns, 'campaignId'),
      }))
    }

    case receiveAction(FETCH_UNSENT_CAMPAIGNS): {
      return receiveReducer(state, action, () => ({
        unsentCampaigns: keyBy(action.response?.campaigns, 'campaignId'),
      }))
    }

    case receiveAction(FETCH_RECENT_INVITES): {
      return receiveReducer(state, action, () => ({
        invites: keyBy(action.response?.invites, 'campaignId'),
      }))
    }

    case receiveAction(FETCH_UNSENT_INVITES): {
      return receiveReducer(state, action, () => ({
        unsentInvites: keyBy(action.response?.invites, 'campaignId'),
      }))
    }

    case receiveAction(FETCH_SERVER_SCRIPTS): {
      return receiveReducer(state, action, () => ({
        scripts: action.response?.scripts,
      }))
    }

    case receiveAction(FETCH_SERVER_SCRIPT_EXAMPLE): {
      return receiveReducer(state, action, () => ({
        scriptExamples: {
          ...state.scriptExamples,
          ...action.response,
        },
      }))
    }

    default:
      return state
  }
}
