import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import classNames from 'classnames'

import { Subtitle2 } from 'pharmacy/src/typography'
import { TextInput } from 'pharmacy/src/input/textInput'
import { SimpleSelect } from 'pharmacy/src/input/select'
import { TagSearch } from 'pharmacy/src/display/tagSearch'
import { Button, ButtonCheckbox } from 'pharmacy/src/input/button'
import { DatePicker, DateRangePicker } from 'pharmacy/src/input/datePicker'
import { TextEditor } from 'pharmacy/src/input/textEditor'

import css from './sponsorshipForm.scss'

export const SponsorshipSchema = Yup.object().shape({
  description: Yup.string().required(),
  sponsor: Yup.mixed().required(),
  topic: Yup.mixed().required(),
  topics: Yup.mixed(),
  emblem: Yup.boolean(),
  useCustomInfo: Yup.boolean(),
  infoText: Yup.mixed(),
  experts: Yup.array().required(),
  editor: Yup.mixed().required(),
  dates: Yup.object().shape({
    from: Yup.string().required(),
    to: Yup.string().required(),
  }),
})

class SponsorshipForm extends React.Component {
  handleSelectChange = (fieldName) => (selected) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldTouched(fieldName)
    setFieldValue(fieldName, selected)
  }

  handleDateChange = (dates) => {
    const { setFieldValue } = this.props

    setFieldValue('dates', dates)
  }

  handleReleaseDateChange = (date) => {
    const { setFieldValue } = this.props

    setFieldValue('releaseDate', date)
  }

  handleCheckboxChange = (fieldName) => () => {
    const { setFieldValue, values } = this.props

    setFieldValue(fieldName, !values[fieldName])
  }

  handleEditorChange = (value) => {
    const { setFieldValue } = this.props

    setFieldValue('infoText', value)
  }

  render() {
    const {
      closeModal,
      handleSubmit,
      isSubmitting,
      sponsors,
      formRef,
      handleChange,
      errors,
      values,
      submitText,
    } = this.props

    const submitIsDisabled = _.isEmpty(values) || !_.isEmpty(errors)

    return (
      <form className={css.createContainer} ref={formRef}>
        <div className={css.formInput}>
          <Subtitle2>Sponsorship Name</Subtitle2>
          <TextInput
            name="description"
            showClear={false}
            onChange={handleChange('description')}
            value={values.description}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Client</Subtitle2>
          <SimpleSelect
            options={_.map(sponsors, (sponsor) => ({
              label: sponsor.sponsor,
              value: sponsor.sponsorId,
            }))}
            isSearchable
            creatable
            className={css.sponsorSelect}
            name="sponsor"
            onChange={this.handleSelectChange('sponsor')}
            defaultValue={
              values.sponsor && {
                label: values.sponsor.sponsor,
                value: values.sponsor.sponsorId,
              }
            }
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Primary Topic</Subtitle2>
          <TagSearch
            index="topic"
            isMulti={false}
            name="topic"
            value={values.topic}
            creatable
            onChange={this.handleSelectChange('topic')}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Automatically Added Topics</Subtitle2>
          <TagSearch
            index="topic"
            isMulti
            name="topics[]"
            value={values.topics}
            creatable
            onChange={this.handleSelectChange('topics')}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Experts</Subtitle2>
          <TagSearch
            index="user"
            name="experts[]"
            value={values.experts.map(({ email, ...rest }) => rest)}
            onChange={this.handleSelectChange('experts')}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Editor</Subtitle2>
          <TagSearch
            index="user"
            isMulti={false}
            name="editor"
            value={values.editor}
            onChange={this.handleSelectChange('editor')}
          />
        </div>
        <div className={classNames(css.formInput, css.emblem)}>
          <ButtonCheckbox
            unselectText="Emblem"
            selectText="Emblem"
            inputProps={{
              name: 'emblem',
              value: true,
              onChange: this.handleCheckboxChange('emblem'),
              checked: values.emblem,
            }}
          />
          <TextInput
            className={css.emblemInput}
            name="emblemText"
            showClear={false}
            onChange={handleChange('emblemText')}
            value={values.emblemText}
            placeholder="Emblem text..."
            disabled={!values.emblem}
          />
        </div>
        <div className={classNames(css.formInput)}>
          <ButtonCheckbox
            unselectText="Paid"
            selectText="Paid"
            inputProps={{
              name: 'isPaid',
              value: true,
              onChange: this.handleCheckboxChange('isPaid'),
              checked: values.isPaid,
            }}
          />
        </div>
        <div className={classNames(css.formInput, css.infoTextWrapper)}>
          <ButtonCheckbox
            className={css.customInfo}
            unselectText="Use custom info text"
            selectText="Use custom info text"
            inputProps={{
              name: 'useCustomInfo',
              value: true,
              onChange: this.handleCheckboxChange('useCustomInfo'),
              checked: values.useCustomInfo,
            }}
          />
          <TextEditor
            name="infoText"
            onEditorChange={this.handleEditorChange}
            value={values.infoText}
            disabled={!values.useCustomInfo}
            autosave_restore_when_empt={false}
          />
        </div>
        <div className={classNames(css.dates, css.formInput)}>
          <div>
            <Subtitle2>Active dates</Subtitle2>
            <DateRangePicker
              fromProps={{
                inputProps: { name: 'startDate' },
                defaultValue: moment(values.dates.from).format('L'),
              }}
              toProps={{
                inputProps: { name: 'endDate' },
                defaultValue: moment(values.dates.to).format('L'),
              }}
              onChange={this.handleDateChange}
            />
          </div>
          <div className={css.releaseDate}>
            <Subtitle2>Public release date</Subtitle2>
            <DatePicker
              inputProps={{ name: 'releaseDate' }}
              defaultValue={moment(values.releaseDate).format('L')}
              onChange={this.handleReleaseDateChange}
            />
          </div>
        </div>

        <div className={css.buttonsContainer}>
          <Button type="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isDisabled={submitIsDisabled}
            isLoading={isSubmitting}
          >
            {submitText}
          </Button>
        </div>
      </form>
    )
  }
}

export default SponsorshipForm
