import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'

import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

import { FETCH_QUESTION_CITED_PAPERS } from './question'
import {
  ADD_PUBLICATION_TO_SPONSORSHIP,
  FETCH_SPONSORSHIP_PUBLICATIONS,
} from './sponsorship'

export const FETCH_QUESTION_CITATION_DOWNLOAD_LINK =
  'questionCitedPaper/FETCH_QUESTION_CITATION_DOWNLOAD_LINK'
export const FETCH_ANSWER_CITATION_DOWNLOAD_LINK =
  'answerCitedpaper/FETCH_ANSWER_CITATION_DOWNLOAD_LINK'
export const FETCH_QUESTION_SPONSORSHIP_CITATION_DOWNLOAD_LINK =
  'questionSponsorshipCitedPaper/FETCH_QUESTION_SPONSORSHIP_CITATION_DOWNLOAD_LINK'
export const FETCH_PUBMED_INFO = 'publication/FETCH_PUBMED_INFO'
export const UPLOAD_SPONSORED_PAPER = 'publication/UPLOAD_SPONSORED_PAPER'
export const SEARCH_PUBLICATIONS = 'publication/SEARCH_PUBLICATIONS'
export const UPDATE_PUBLICATION = 'publication/UPDATE_PUBLICATION'

export function fetchQuestionCitationDownloadLink(citeId, callback) {
  return {
    type: FETCH_QUESTION_CITATION_DOWNLOAD_LINK,
    citeId,
    callback,
  }
}

export function fetchAnswerCitationDownloadLink(citeId, callback) {
  return {
    type: FETCH_ANSWER_CITATION_DOWNLOAD_LINK,
    citeId,
    callback,
  }
}

export function fetchQuestionSponsorshipCitationDownloadLink(citeId, callback) {
  return {
    type: FETCH_QUESTION_SPONSORSHIP_CITATION_DOWNLOAD_LINK,
    citeId,
    callback,
  }
}

export function fetchPubmedInfo(articleId, callback) {
  return {
    type: FETCH_PUBMED_INFO,
    requestId: articleId,
    callback,
  }
}

export function uploadSponsoredPaper(formData, callback) {
  return {
    type: UPLOAD_SPONSORED_PAPER,
    formData,
    callback,
  }
}

export function searchPublications(searchKey, searchValue, callback) {
  return {
    type: SEARCH_PUBLICATIONS,
    searchKey,
    searchValue,
    callback,
  }
}

export function updatePublication(formData, callback) {
  return {
    type: UPDATE_PUBLICATION,
    formData,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_QUESTION_CITATION_DOWNLOAD_LINK,
    (action) => makeURL(`questionCitedPaper/downloadLinkJSON/${action.citeId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_ANSWER_CITATION_DOWNLOAD_LINK,
    (action) => makeURL(`answerCitedPaper/downloadLinkJSON/${action.citeId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_QUESTION_SPONSORSHIP_CITATION_DOWNLOAD_LINK,
    (action) =>
      makeURL(`questionSponsorshipCitedPaper/downloadLinkJSON/${action.citeId}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_PUBMED_INFO, (action) =>
    makeURL(`publication/getPubmedInfoJSON/${action.requestId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    UPLOAD_SPONSORED_PAPER,
    makeURL(`publication/UploadSponsoredPaperJSON`),
    (action) => makePOSTHeadersFromParams(action.formData)
  )

  yield makeFetchEffect(takeLatest, SEARCH_PUBLICATIONS, (action) =>
    makeURL('publication/searchJSON', {
      searchKey: action.searchKey,
      searchValue: action.searchValue,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    UPDATE_PUBLICATION,
    makeURL(`publication/updateSponsoredPaperJSON`),
    (action) => makePOSTHeadersFromParams(action.formData)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  publications: {},
  pubmedInfo: {},
  searchResult: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_QUESTION_CITED_PAPERS): {
      const publications = state.publications || {}
      const newPublications = {}
      const citedPapers = action.response.citedPapers

      if (!citedPapers) {
        return state
      }

      citedPapers.forEach(({ publication }) => {
        newPublications[publication.publicationId] = {
          ...(publications[publication.publicationId] || {}),
          ...publication,
        }
      })

      return receiveReducer(state, action, () => ({
        publications: {
          ...publications,
          ...newPublications,
        },
      }))
    }

    case receiveAction(FETCH_QUESTION_CITATION_DOWNLOAD_LINK):
    case receiveAction(FETCH_ANSWER_CITATION_DOWNLOAD_LINK):
    case receiveAction(FETCH_QUESTION_SPONSORSHIP_CITATION_DOWNLOAD_LINK): {
      const newPublicationObject = action.response.publication

      if (!newPublicationObject) {
        return state
      }

      const publication =
        state.publications[newPublicationObject.publicationId] || {}

      return receiveReducer(state, action, () => ({
        publications: {
          ...state.publications,
          [newPublicationObject.publicationId]: {
            ...publication,
            ...newPublicationObject,
          },
        },
      }))
    }

    case receiveAction(FETCH_PUBMED_INFO): {
      return receiveReducer(state, action, () => ({
        pubmedInfo: {
          ...state.pubmedInfo,
          [action.requestId]: action.response,
        },
      }))
    }

    case receiveAction(SEARCH_PUBLICATIONS): {
      return receiveReducer(state, action, () => {
        const publications = state.publications || {}
        const newPublications = {}

        action.response.forEach((publication) => {
          newPublications[publication.publicationId] = {
            ...(publications[publication.publicationId] || {}),
            ...publication,
          }
        })

        return {
          publications: {
            ...publications,
            ...newPublications,
          },
          searchResult: Object.keys(newPublications),
        }
      })
    }

    case receiveAction(UPLOAD_SPONSORED_PAPER): {
      return receiveReducer(state, action, () => {
        if (action.response.success) {
          let pubmedInfo = state.pubmedInfo

          if (!action.formData.noPubmedId) {
            const newPublication = action.response.publication

            pubmedInfo = {
              ...pubmedInfo,
              [action.formData.pubmedId]: {
                publicationId: newPublication.publicationId,
                pubmedIdExists: true,
                title: newPublication.title,
                journal: newPublication.journal,
                date: newPublication.date,
                file: newPublication.file,
                abstract: newPublication.abstract,
                doi: newPublication.doi,
                country: newPublication.country,
                keywords: newPublication.keywords,
                medline_ta: newPublication.medline_ta,
                nlm_unique_id: newPublication.nlm_unique_id,
                pmc: newPublication.pmc,
                publication_types: newPublication.publication_types,
              },
            }
          }

          const publications = {
            ...state.publications,
            [action.response.publication.publicationId]: {
              ...action.response.publication,
            },
          }

          return {
            pubmedInfo,
            publications,
          }
        }

        return state
      })
    }

    case receiveAction(ADD_PUBLICATION_TO_SPONSORSHIP): {
      return receiveReducer(state, action, () => {
        if (action.response.success) {
          const publication = state.publications[action.publicationId] || {}
          const updatedPublication = action.response.publication

          return {
            publications: {
              ...state.publications,
              [action.formData.publicationId]: {
                ...publication,
                ...updatedPublication,
              },
            },
          }
        }

        return state
      })
    }

    case receiveAction(FETCH_SPONSORSHIP_PUBLICATIONS): {
      return receiveReducer(state, action, () => {
        const publications = state.publications || {}
        const newPublications = action.response.publications

        const updatedPublications = Object.fromEntries(
          Object.entries(newPublications).map(
            ([publicationId, publication]) => [
              publicationId,
              {
                ...(publications[publicationId] || {}),
                ...publication,
              },
            ]
          )
        )

        return {
          publications: {
            ...publications,
            ...updatedPublications,
          },
        }
      })
    }

    case receiveAction(UPDATE_PUBLICATION): {
      return receiveReducer(state, action, () => {
        const publications = state.publications || {}
        const oldPublication = publications[action.formData.publicationId] || {}
        const newPublication = action.response.publication

        let pubmedInfo = state.pubmedInfo

        if (!action.formData.noPubmedId) {
          pubmedInfo = {
            ...pubmedInfo,
            [action.formData.pubmedId]: {
              publicationId: newPublication.publicationId,
              pubmedIdExists: true,
              title: newPublication.title,
              journal: newPublication.journal,
              date: newPublication.date,
              file: newPublication.file,
              abstract: newPublication.abstract,
              doi: newPublication.doi,
              country: newPublication.country,
              keywords: newPublication.keywords,
              medline_ta: newPublication.medline_ta,
              nlm_unique_id: newPublication.nlm_unique_id,
              pmc: newPublication.pmc,
              publication_types: newPublication.publication_types,
            },
          }
        }

        return {
          pubmedInfo,
          publications: {
            ...publications,
            [action.formData.publicationId]: {
              ...oldPublication,
              ...newPublication,
            },
          },
        }
      })
    }

    default:
      return state
  }
}
