import React from 'react'
import { initialize, set, pageview } from 'react-ga'

import {
  calcMixpanelReferrerLabel,
  mixpanel,
  smartlookClient,
} from 'mednet-util/src/tracking'

class Tracking extends React.Component {
  componentDidMount() {
    const { history } = this.props

    try {
      if (process.env.MIXPANEL_TOKEN) {
        mixpanel.init(process.env.MIXPANEL_TOKEN)

        const { referrerLabel, searchQuery } = calcMixpanelReferrerLabel(
          history.location
        )

        // super properties
        mixpanel.register({
          session_referrer_label: sessionStorage.getItem(
            'session_referrer_label'
          ),
          session_referrer_search_query: sessionStorage.getItem(
            'session_referrer_search_query'
          ),
          direct_referrer_label: referrerLabel,
          direct_referrer_search_query: searchQuery,
        })

        const mpLocationListener = () => {
          sessionStorage.removeItem('request_redirected_from_notification')
          const params = new URLSearchParams(history.location.search)
          const src = params.get('src')

          // since these changes are in react router, we can assume it is alway an internal react page
          if (src === 'popover-search') {
            mixpanel.register({
              direct_referrer_label: 'theMednet Popover Search',
              direct_referrer_search_query: params.get('query'),
            })
          } else {
            mixpanel.register({
              direct_referrer_label: 'theMednet',
              direct_referrer_search_query: null,
            })
          }
        }

        history.listen(mpLocationListener)
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
      smartlookClient.init(process.env.SMARTLOOK_API_KEY)
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (process.env.GOOGLE_ANALYTICS_ID) {
      initialize(process.env.GOOGLE_ANALYTICS_ID)

      const locationListener = (location) => {
        set({ page: location.pathname })
        pageview(location.pathname)
      }

      locationListener(window.location)

      history.listen(locationListener)
    }
  }

  render() {
    return null
  }
}

export default Tracking
