import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { CmeAssessmentForm } from 'pharmacy/src/cme/cmeAssessmentForm/cmeAssessmentForm'
import { Body1, Header2, Header3, Subtitle2 } from 'pharmacy/src/typography'
import { Icon } from 'pharmacy/src/display/icon'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Button } from 'pharmacy/src/input/button'
import UserUpdateInformation from 'pharmacy/src/user/userUpdateInformation/userUpdateInformation'
import { UpdatePreview } from 'pharmacy/src/question/updatePreview'
import { MocAssessmentForm } from 'pharmacy/src/cme/mocAssessmentForm'
import { RedmptionRelatedQuestions } from 'pharmacy/src/cme/redemptionRelatedQuestions'
import { MocAssessmentModal } from 'pharmacy/src/cme/mocAssessmentModal'

import {
  CME_ACTIVITY_TYPE,
  CME_ACTIVITY_TYPE_LABEL,
} from 'mednet-util/src/constants/cme'
import { mixpanel } from 'mednet-util/src/tracking'
import htmlToPlainText from 'mednet-util/src/htmlToPlainText'
import { MOC_ASSESSMENT_MODAL } from 'mednet-util/src/constants/modal'

import { useToggleFollowQuestion } from 'mednet-cns/src/hooks/question'
import useScrollbarWidth from 'mednet-cns/src/hooks/useScrollbarWidth'
import {
  useCmeConfig,
  useQuestionCmePreviewLazy,
  useRedeemCmeActivity,
  useUpdateRedeemedCmeActivity,
} from 'mednet-cns/src/hooks/cme'
import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import { CmeActivityLink } from 'components/cmeActivityLink'

import css from './cmeAssessmentPage.scss'

const getActivityUniqueKey = (activity) => {
  return activity.cmeActivityId
}

const QuestionPreviewButton = ({
  activity,
  loadQuestionPreviewDetails,
  questionPreviewRequest,
  previewExpanded,
  setPreviewExpanded,
  isDisabled,
}) => {
  const handleClick = useCallback(() => {
    if (!isDisabled) {
      setPreviewExpanded(!previewExpanded)

      if (activity.contentType === CME_ACTIVITY_TYPE.QUESTION_ANSWER) {
        if (
          !questionPreviewRequest.isLoaded &&
          !questionPreviewRequest.isLoading
        ) {
          loadQuestionPreviewDetails(activity.contentId, true)
        }
      } else {
        if (
          !questionPreviewRequest.isLoaded &&
          !questionPreviewRequest.isLoading
        ) {
          loadQuestionPreviewDetails(activity.contentId, false)
        }
      }

      mixpanel.track('Clicked CME Preview Answers', {
        contentId: activity.contentId,
        contentType: activity.contentType,
      })
    }
  }, [
    isDisabled,
    setPreviewExpanded,
    loadQuestionPreviewDetails,
    previewExpanded,
    activity.contentId,
    activity.contentType,
    questionPreviewRequest,
  ])

  if (activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW) {
    return null
  }

  return (
    <div
      className={classNames(css.previewIconContainer, {
        [css.disabledIconContainer]: isDisabled,
      })}
      onClick={handleClick}
    >
      {!previewExpanded && <div>Preview answers</div>}
      <Icon
        icon="angle-down"
        className={classNames(css.arrowDownIcon, {
          [css.arrowUpIcon]: previewExpanded,
        })}
      />
    </div>
  )
}

const QuestionPreview = ({
  questionActivity,
  questionDetails,
  isLoaded,
  positionInSurvey,
}) => {
  const toggleFollowQuestion = useToggleFollowQuestion()

  const toggleStarButton = useCallback(() => {
    toggleFollowQuestion(questionActivity.contentId)

    mixpanel.track('Clicked Star Question From CME Preview', {
      contentId: questionActivity.contentId,
      contentType: questionActivity.contentType,
    })
  }, [
    toggleFollowQuestion,
    questionActivity.contentId,
    questionActivity.contentType,
  ])

  const trackFullQClick = useCallback(() => {
    mixpanel.track('Viewed Full Q & A From CME Preview', {
      contentId: questionActivity.contentId,
      contentType: questionActivity.contentType,
    })
  }, [questionActivity.contentId, questionActivity.contentType])

  if (!isLoaded) {
    return null
  }

  return (
    <>
      <CmeActivityLink
        activity={questionActivity}
        onClick={trackFullQClick}
        position={positionInSurvey}
      >
        <div className={classNames(css.questionDetail, css.link)}>
          {htmlToPlainText(questionDetails.detail)}
        </div>
      </CmeActivityLink>
      <Button
        className={css.saveQuestionButton}
        size="small"
        type="neutral"
        icon={questionDetails.isFollowed ? ['fas', 'star'] : ['far', 'star']}
        onClick={toggleStarButton}
      >
        <div className={css.saveQuestionButtonText}>
          {questionDetails.isFollowed ? 'Unstar' : 'Star'}
        </div>
      </Button>
    </>
  )
}

const QuestionAnswersPreview = ({
  questionId,
  questionDetails,
  isLoaded,
  activityType,
}) => {
  const questionPagePath = useMemo(
    () => `/question/${questionId}`,
    [questionId]
  )

  const openQuestionLink = useCallback(() => {
    window.open(questionPagePath, '_blank', 'noopener,noreferrer')
    trackFullQClick()
  }, [questionPagePath, window, trackFullQClick])

  const trackFullQClick = useCallback(() => {
    mixpanel.track('Viewed Full Q & A From Cme Preview', {
      contentId: questionId,
      contentType: activityType,
    })
  }, [questionId, activityType])

  const trackProfileClick = useCallback(
    (profileUserId) => () => {
      mixpanel.track('Clicked User Profile From CME Preview', {
        profileUserId,
        contentId: questionId,
        contentType: activityType,
      })
    },
    [questionId, activityType]
  )

  return (
    <div>
      {isLoaded ? (
        <div className={css.answersList}>
          {questionDetails.updates.map((update) => (
            <div key={update.questionUpdateId} className={css.answerContainer}>
              <UserUpdateInformation
                user={update.user}
                dateUpdated={update.dateUpdated}
                actionId={update.actionId}
                useUserProfileLink
                onProfileClick={trackProfileClick(update.user?.userId)}
              >
                <div
                  className={css.link}
                  // Use this instead of <a> element because the 'more' link in the preview component uses <a> and <a> cannot include another <a>
                  onClick={openQuestionLink}
                >
                  <UpdatePreview item={update} TextComponent={Body1} />
                </div>
              </UserUpdateInformation>
            </div>
          ))}
        </div>
      ) : (
        <StarLoader />
      )}
      <div className={css.fullQuestionButtonContainer}>
        <Button
          className={css.fullQuestionButton}
          size="small"
          type="neutral"
          pathname={questionPagePath}
          target="_blank"
          onClick={trackFullQClick}
        >
          View full Q&A
        </Button>
      </div>

      <div className={css.assessmentTitle}>Assessment</div>
    </div>
  )
}

const CmeAssessmentCard = forwardRef(
  (
    {
      activity,
      progressText,
      cardDisabled,
      handleCardSubmit,
      location,
      withPreview,
      setHandlingSubmit,
      positionInSurvey,
      containerClassName,
    },
    ref
  ) => {
    const { contentType, summary } = activity
    const redeemCmeActivity = useRedeemCmeActivity()
    const updateRedeemedCmeActivity = useUpdateRedeemedCmeActivity()
    const [
      questionPreviewDetails,
      questionPreviewDetailsRequest,
      loadQuestionPreviewDetails,
    ] = useQuestionCmePreviewLazy(activity.contentId)

    const [redeemId, setRedeemId] = useState(undefined)
    const [previewExpanded, setPreviewExpanded] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const getSubmissionTrackingProps = useCallback(
      (data, { impacts, goals, applications }, res) => {
        return {
          question_id:
            activity.contentType !== CME_ACTIVITY_TYPE.PUBLICATION_VIEW
              ? activity.contentId
              : null,
          publication_id:
            activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW
              ? activity.contentId
              : null,
          activity_type: CME_ACTIVITY_TYPE_LABEL[activity.contentType],
          location,
          position_in_feed: positionInSurvey,
          success: res.success,
          goal_selected_id: parseInt(data.goalId),
          application_selected_id: parseInt(data.applicationId),
          impact_selected_id: parseInt(data.impactId),
          goal_selected_text: goals.find(
            (goal) => goal.goalId === parseInt(data.goalId)
          ).description,
          application_selected_text: applications.find(
            (application) =>
              application.applicationId === parseInt(data.applicationId)
          ).description,
          impact_selected_text: impacts.find(
            (impact) => impact.impactId === parseInt(data.impactId)
          ).description,
        }
      },
      [activity, positionInSurvey, location]
    )

    const handleSubmit = useCallback(
      (data, { impacts, goals, applications }, callback) => {
        setHandlingSubmit(true)
        setPreviewExpanded(false)
        setIsSubmitting(true)

        redeemCmeActivity(data, activity.cmeActivityId, (res) => {
          setIsSubmitting(false)
          handleCardSubmit(res)

          if (res.success) {
            setRedeemId(res.createdId)
          }

          callback(res)

          mixpanel.track(
            'redeemed_cme',
            getSubmissionTrackingProps(
              data,
              { impacts, goals, applications },
              res
            )
          )

          setHandlingSubmit(false)
        })
      },
      [
        redeemCmeActivity,
        setRedeemId,
        handleCardSubmit,
        setIsSubmitting,
        activity.contentId,
        activity.contentType,
        location,
      ]
    )

    const handleUpdateSubmit = useCallback(
      (data, { impacts, goals, applications }, callback) => {
        setHandlingSubmit(true)
        setPreviewExpanded(false)
        setIsSubmitting(true)

        updateRedeemedCmeActivity(data, redeemId, (res) => {
          setIsSubmitting(false)
          handleCardSubmit(res)
          callback(res)

          mixpanel.track(
            'updated_cme',
            getSubmissionTrackingProps(
              data,
              { impacts, goals, applications },
              callback
            )
          )

          setHandlingSubmit(false)
        })
      },
      [
        updateRedeemedCmeActivity,
        handleCardSubmit,
        setIsSubmitting,
        activity.contentId,
        activity.contentType,
        location,
      ]
    )

    const trackSummaryLinkClick = useCallback(() => {
      mixpanel.track('Clicked CME Activity Summary', {
        contentId: activity.contentId,
        contentType: activity.contentType,
      })
    }, [activity.contentId, activity.contentType])

    return (
      <div className={classNames(containerClassName)} ref={ref}>
        <div className={css.card}>
          <div className={css.cardHeader}>
            <Subtitle2 className={css.activityTypeContainer}>
              Activity Type:{' '}
              <span className={css.activityType}>
                {CME_ACTIVITY_TYPE_LABEL[contentType]}
              </span>
            </Subtitle2>

            <div className={css.progress}>{progressText}</div>

            <CmeActivityLink
              activity={activity}
              onClick={trackSummaryLinkClick}
              position={positionInSurvey}
            >
              <Header3 className={classNames(css.activitySummary, css.link)}>
                {summary}
              </Header3>
            </CmeActivityLink>

            {previewExpanded && (
              <QuestionPreview
                questionActivity={activity}
                questionDetails={questionPreviewDetails}
                isLoaded={questionPreviewDetailsRequest.isLoaded}
                positionInSurvey={positionInSurvey}
              />
            )}

            {withPreview && (
              <QuestionPreviewButton
                activity={activity}
                loadQuestionPreviewDetails={loadQuestionPreviewDetails}
                questionPreviewRequest={questionPreviewDetailsRequest}
                previewExpanded={previewExpanded}
                setPreviewExpanded={setPreviewExpanded}
                isDisabled={isSubmitting}
              />
            )}
          </div>

          {previewExpanded && (
            <QuestionAnswersPreview
              questionId={activity.contentId}
              questionDetails={questionPreviewDetails}
              isLoaded={questionPreviewDetailsRequest.isLoaded}
              activityType={activity.contentType}
            />
          )}
          <CmeAssessmentForm
            activityKey={getActivityUniqueKey(activity)}
            fullWidthText
            allowUpdate
            handleFormSubmit={handleSubmit}
            handleFormUpdateSubmit={handleUpdateSubmit}
            isDisabled={cardDisabled && !redeemId}
          />

          {redeemId && (
            <div className={css.redeemedText}>
              Redeemed
              <Icon icon="check-circle" className={css.checkIcon} />
            </div>
          )}

          {cardDisabled && !redeemId && !isSubmitting && (
            <div className={css.disabledCard}>
              <div className={css.disableMessageContainer}>
                <div className={css.disableMessage}>
                  Redeemed maximaum credits for the current year
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
)

CmeAssessmentCard.displayName = 'CmeAssessmentCard'

const usePageLeaveHandler = (
  history,
  redeemedCredits,
  gotToNextStep,
  setGoToNextStep,
  isAbimMocEnabledForUser,
  isAbpMocEnabledForUser,
  redeemedAllAssessmentActivites
) => {
  const [
    openMocAssessmentModal,
    closeMocAssessmentModal,
    isMocAssessmentModalOpen,
  ] = useModalDrivers(MOC_ASSESSMENT_MODAL.modalId)

  const [trackedIfAllRedeemed, setTrackedIfAllRedeemed] = useState(false)

  useEffect(() => {
    const unblock = history.block((location, _action) => {
      if (!trackedIfAllRedeemed) {
        setTrackedIfAllRedeemed(true)
        if (redeemedAllAssessmentActivites) {
          mixpanel.track('Redeemed All Activities in CME Assessment Page')
        } else {
          mixpanel.track('Did Not Redeem All Activities in CME Assessment Page')
        }
      }

      // don't block the user if he dis not redeem credits or if he already saw the MOC form or the success message
      if (redeemedCredits <= 0 || gotToNextStep) {
        return true
      }

      // If the user is just leaving from cme tab to another CME tab, show him the MOC form or the success message first
      if (
        history.location.pathname.startsWith('/cme') &&
        location.pathname.startsWith('/cme')
      ) {
        setGoToNextStep(true)
        return false
      }

      // This is when the user is leaving the CME dashboard, don't block him unless he still needs to redeem his MOC credits
      // (Don't block him to show him the success message, this is not important!)
      if (isAbimMocEnabledForUser || isAbpMocEnabledForUser) {
        // if the modal is already open don't block him
        if (isMocAssessmentModalOpen) {
          return true
        }

        openMocAssessmentModal({
          onExit: closeMocAssessmentModal,
        })
        return false
      }

      return true
    })

    return () => {
      unblock()
    }
  }, [
    redeemedCredits,
    gotToNextStep,
    isMocAssessmentModalOpen,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    redeemedAllAssessmentActivites,
  ])

  // Be carefull if the dependency array grew with variables that usually change, the function reference should not change in order to be able to remove the listener
  const beforeUnloadListener = useCallback(
    (event) => {
      event.preventDefault()
      // This message will be ignored by most modern browsers
      event.returnValue =
        'Do you want to redeem MOC for your activity before leaving?'
      setGoToNextStep(true)
    },
    [setGoToNextStep]
  )

  // Without this the event is not removed when leaving the page
  const eventListenerOptions = {
    capture: true,
  }

  useEffect(() => {
    if (
      (isAbimMocEnabledForUser || isAbpMocEnabledForUser) &&
      redeemedCredits > 0 &&
      !gotToNextStep
    ) {
      window.addEventListener(
        'beforeunload',
        beforeUnloadListener,
        eventListenerOptions
      )
    } else {
      window.removeEventListener(
        'beforeunload',
        beforeUnloadListener,
        eventListenerOptions
      )
    }
  }, [
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    redeemedCredits,
    gotToNextStep,
  ])

  // These two functions are sparated because of the dependences. If we put them in one function we'll have to
  // add redeemedAllAssessmentActivites as a dependency and that will cause the function reference to change
  // before beign able to remove it from the unload listeners
  const trackAllActivitiesWereRedeemed = useCallback(() => {
    setTrackedIfAllRedeemed(true)
    mixpanel.track('Redeemed All Activities in CME Assessment Page')
  }, [setTrackedIfAllRedeemed])

  const trackNotAllActivitiesWereRedeemed = useCallback(() => {
    setTrackedIfAllRedeemed(true)
    mixpanel.track('Did Not Redeem All Activities in CME Assessment Page')
  }, [setTrackedIfAllRedeemed])

  useEffect(() => {
    if (!trackedIfAllRedeemed) {
      if (redeemedAllAssessmentActivites) {
        window.removeEventListener(
          'beforeunload',
          trackNotAllActivitiesWereRedeemed,
          eventListenerOptions
        )

        window.addEventListener(
          'beforeunload',
          trackAllActivitiesWereRedeemed,
          eventListenerOptions
        )
      } else {
        window.removeEventListener(
          'beforeunload',
          trackAllActivitiesWereRedeemed,
          eventListenerOptions
        )

        window.addEventListener(
          'beforeunload',
          trackNotAllActivitiesWereRedeemed,
          eventListenerOptions
        )
      }
    } else {
      window.removeEventListener(
        'beforeunload',
        trackNotAllActivitiesWereRedeemed,
        eventListenerOptions
      )
      window.removeEventListener(
        'beforeunload',
        trackAllActivitiesWereRedeemed,
        eventListenerOptions
      )
    }
  }, [redeemedAllAssessmentActivites, trackedIfAllRedeemed])

  // On unmount remove listeners
  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      })

      window.removeEventListener(
        'beforeunload',
        trackAllActivitiesWereRedeemed,
        {
          capture: true,
        }
      )

      window.removeEventListener(
        'beforeunload',
        trackNotAllActivitiesWereRedeemed,
        {
          capture: true,
        }
      )
    }
  }, [])
}

const ACTIVITY_STATE = {
  AVAILABLE: 1,
  REDEEMED: 2,
  DISABLED: 3,
}

export const CmeAssessmentPage = (props) => {
  /* 
    These are updated using the selector from redux state in parent component. They are selected in the
    parent component because they are read from two different places for sponsorship and for the cme dashboard
  */
  const {
    activities,
    location,
    withPreview,
    className,
    onExit,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    showNextStepAsCard,
    history,
  } = props

  /*
    We want to have the initial value of the available activities, we don't want to get the updated value where some activities
    are missing because they were redeemed in this page or the maximum per their year was redeemed in this page. We want to allow 
    updating activities that are already redeemed in this page
  */
  const [assessmentActivities] = useState(activities)

  const [assessmentActivitiesStates, setAssessmentActivitiesStates] = useState(
    Object.fromEntries(
      assessmentActivities.map((_activity, index) => [
        index,
        ACTIVITY_STATE.AVAILABLE,
      ])
    )
  )

  const [lastSubmittedCardIndex, setLastSubmittedCardIndex] =
    useState(undefined)

  const [cmeConfig, cmeConfigRequest] = useCmeConfig()

  const cardsRefs = assessmentActivities.map((_) => useRef())

  const [cardsHandlingSubmit, setCardsHandlingSubmit] = useState({})
  const scrollbarWidth = useScrollbarWidth()
  const [gotToNextStep, setGoToNextStep] = useState()

  const getNextCardRef = useCallback(
    (currentIndex) => {
      let nextIndex = currentIndex + 1

      while (
        nextIndex < assessmentActivities.length &&
        assessmentActivitiesStates[nextIndex] === ACTIVITY_STATE.DISABLED
      ) {
        nextIndex++
      }

      if (nextIndex < assessmentActivities.length) {
        return cardsRefs[nextIndex]
      }

      /* 
        If there are still available activities that can be redeemed but are not after the current activity, should go back to first 
        available not redeemed activity 
      */
      const firstAvailableIndex = Object.keys(assessmentActivitiesStates).find(
        (index) =>
          assessmentActivitiesStates[index] === ACTIVITY_STATE.AVAILABLE
      )

      if (firstAvailableIndex >= 0) {
        return cardsRefs[firstAvailableIndex]
      }

      // If there aren't any available activity, scroll to the end if not already on last
      if (currentIndex === assessmentActivities.length - 1) {
        return undefined
      }
      return cardsRefs[assessmentActivities.length - 1]
    },
    [assessmentActivities, assessmentActivitiesStates, cardsRefs]
  )

  const handleCardSubmit = useCallback(
    (redeemedIndex, { success, redeemedMaxForCurrentYear }) => {
      const updatedStates = Object.fromEntries(
        Object.entries(assessmentActivitiesStates).map(([index, state]) => {
          const intIndex = parseInt(index)

          if (state !== ACTIVITY_STATE.REDEEMED) {
            if (success) {
              if (intIndex === redeemedIndex) {
                return [intIndex, ACTIVITY_STATE.REDEEMED]
              } else if (redeemedMaxForCurrentYear) {
                return [intIndex, ACTIVITY_STATE.DISABLED]
              }
            } else if (redeemedMaxForCurrentYear) {
              return [intIndex, ACTIVITY_STATE.DISABLED]
            }
          }

          return [intIndex, state]
        })
      )

      setAssessmentActivitiesStates(updatedStates)

      /*
        - This function runs after the submission with the old component state before calling it. To fix it, set the last submitted index here 
          and handle scroll after the states are updated in the next render
        - wrap the index in an object to trigger the scrollToNextCard for the update submission where the actual value of index may not change
      */
      if (success) {
        setLastSubmittedCardIndex({
          index: redeemedIndex,
        })
      }
    },
    [
      setLastSubmittedCardIndex,
      assessmentActivitiesStates,
      setAssessmentActivitiesStates,
    ]
  )

  const scrollToNextCard = useCallback(() => {
    if (lastSubmittedCardIndex !== undefined) {
      if (typeof document === 'undefined' || document.documentMode) {
        // This is for IE, don't scroll as it will jump not scroll smoothly
        return
      }

      const { index } = lastSubmittedCardIndex
      const nextCardRef = getNextCardRef(index)?.current

      if (!nextCardRef) {
        return
      }

      const { bottom: currentCardBottom } =
        cardsRefs[index].current.getBoundingClientRect()

      // This means the user has already scrolled down, so don't do anything
      if (currentCardBottom <= 300) {
        return
      }

      nextCardRef.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [
    lastSubmittedCardIndex,
    getNextCardRef,
    cardsRefs,
    typeof document === 'undefined' ? null : document,
  ])

  useEffect(scrollToNextCard, [lastSubmittedCardIndex])

  const redeemedCredits = useMemo(
    () =>
      Object.values(assessmentActivitiesStates).filter(
        (state) => state === ACTIVITY_STATE.REDEEMED
      ).length * cmeConfig.credits_per_activity,
    [assessmentActivitiesStates, cmeConfig.credits_per_activity]
  )

  const redeemedActivities = useMemo(
    () =>
      assessmentActivities.filter(
        (_activity, index) =>
          assessmentActivitiesStates[index] === ACTIVITY_STATE.REDEEMED
      ),
    [assessmentActivitiesStates]
  )

  const handelRedeemedCreditsChange = useCallback(() => {
    if (
      redeemedCredits >=
        assessmentActivities.length * cmeConfig.credits_per_activity &&
      !Object.values(cardsHandlingSubmit).some(
        (handlingSubmit) => handlingSubmit
      )
    ) {
      setGoToNextStep(true)
    }
  }, [
    redeemedCredits,
    assessmentActivities,
    cmeConfig.credits_per_activity,
    cardsHandlingSubmit,
    setGoToNextStep,
  ])

  useEffect(handelRedeemedCreditsChange, [redeemedCredits, cardsHandlingSubmit])

  usePageLeaveHandler(
    history,
    redeemedCredits,
    gotToNextStep,
    setGoToNextStep,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    redeemedActivities.length >= assessmentActivities.length
  )

  const handleExitButtonClick = useCallback(() => {
    setGoToNextStep(true)
    mixpanel.track(
      `Clicked CME Assessment ${
        isAbimMocEnabledForUser || isAbpMocEnabledForUser
          ? 'Continue to MOC '
          : 'Save & Exit'
      } button`,
      {
        location,
        isAfterOneRedemptionOutOfMany:
          redeemedActivities.length === 1 && assessmentActivities.length > 1,
      }
    )
  }, [
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    redeemedActivities.length,
    assessmentActivities.length,
    location,
  ])

  const mocAssessmentPromptTitle = useCallback(
    (board) => {
      return (
        <div>
          Do you also want to redeem{' '}
          <span className={css.mocCredits}>{redeemedCredits}</span>
          {board} MOC points for this activity?
        </div>
      )
    },
    [redeemedCredits]
  )

  if (!cmeConfigRequest.isLoaded) {
    return <StarLoader />
  }

  if (gotToNextStep) {
    if (isEmpty(redeemedActivities)) {
      onExit()
    } else if (isAbimMocEnabledForUser || isAbpMocEnabledForUser) {
      return (
        <div
          className={classNames({
            [className]: true,
            [css.centeredWhitePage]: !showNextStepAsCard,
          })}
        >
          <MocAssessmentForm
            activities={redeemedActivities}
            creditsPerActivity={cmeConfig.credits_per_activity}
            maxCreditsPerYear={cmeConfig.max_credits_per_year}
            location="Cme Assessment Page"
            isAbimMocEnabledForUser={isAbimMocEnabledForUser}
            isAbpMocEnabledForUser={isAbpMocEnabledForUser}
            isAfterCmeRedemption
            showRelatedQuestions
            showAsCard={showNextStepAsCard}
            title={mocAssessmentPromptTitle}
            onExit={onExit}
          />
        </div>
      )
    } else {
      return (
        <div
          className={classNames({
            [className]: true,
            [css.centeredWhitePage]: !showNextStepAsCard,
          })}
        >
          <RedmptionRelatedQuestions
            redeemedCmeActivitiesIds={redeemedActivities.map(
              (activity) => activity.cmeActivityId
            )}
            creditsPerActivity={cmeConfig.credits_per_activity}
            onExit={onExit}
            showAsCard={showNextStepAsCard}
          />
        </div>
      )
    }
  }

  return (
    <div className={className}>
      <div className={css.assessmentHeader}>
        <Header2>Reflections:</Header2>
        <Subtitle2 className={css.creditsCountContainer}>
          <Icon icon="award" size="lg" className={css.creditsIcon} />
          <span className={css.creditsValue}>{redeemedCredits} </span>
          CME Credits Earned
        </Subtitle2>
      </div>

      <div className={css.cardsConatiner}>
        <div>
          {assessmentActivities.map((activity, index) => (
            <CmeAssessmentCard
              ref={cardsRefs[index]}
              key={getActivityUniqueKey(activity)}
              progressText={`(${index + 1}/${assessmentActivities.length})`}
              activity={activity}
              cardDisabled={
                assessmentActivitiesStates[index] === ACTIVITY_STATE.DISABLED
              }
              handleCardSubmit={(res) => handleCardSubmit(index, res)}
              location={location}
              withPreview={withPreview}
              setHandlingSubmit={(handlingSubmit) => {
                setCardsHandlingSubmit({
                  ...cardsHandlingSubmit,
                  index: handlingSubmit,
                })
              }}
              positionInSurvey={index + 1}
              containerClassName={
                index > 0 ? css.cardContainerWithPadding : undefined
              }
            />
          ))}
        </div>
      </div>
      {redeemedCredits > 0 && (
        <div
          className={css.exitButtonContainer}
          style={{ width: `calc(100% - ${scrollbarWidth}px)` }}
        >
          <Button className={css.exitButton} onClick={handleExitButtonClick}>
            {isAbimMocEnabledForUser || isAbpMocEnabledForUser
              ? 'Continue to claim MOC'
              : 'Save & Exit'}
          </Button>
        </div>
      )}
      <MocAssessmentModal
        activitiesIds={redeemedActivities}
        creditsPerActivity={cmeConfig.credits_per_activity}
        maxCreditsPerYear={cmeConfig.max_credits_per_year}
        location="Cme Assessment Page"
        isAbimMocEnabledForUser={isAbimMocEnabledForUser}
        isAbpMocEnabledForUser={isAbpMocEnabledForUser}
        isAfterCmeRedemption
        showAsCard={showNextStepAsCard}
        title={mocAssessmentPromptTitle}
      />
    </div>
  )
}
