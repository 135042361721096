import React from 'react'
import _ from 'lodash'
import { Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { GlobalEventListener } from 'react-global-event-listener'

import { BaseQuestionLink } from 'pharmacy/src/navigation/questionLink'
import { BaseUserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { BaseUserLink } from 'pharmacy/src/navigation/userLink'
import { NavigationContext } from 'pharmacy/src/contexts/navigation'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { Tracking } from 'pharmacy/src/misc/tracking'

import { Manager } from 'components/manager'
import { AnteriorHeader } from 'components/anteriorHeader'
import { Mention } from 'components/mention'
import { OnboardingModal } from 'components/onboardingModal'
import { ROUTES } from 'constants/routes'

import 'sanitize.css'
import 'pharmacy/src/style/app.scss'

const App = ({ history }) => {
  return (
    <ErrorBoundary>
      <GlobalEventListener>
        <NavigationContext.Provider
          value={{
            UserNameLinkComponent: BaseUserNameLink,
            QuestionLinkComponent: BaseQuestionLink,
            UserLinkComponent: BaseUserLink,
          }}
        >
          <Helmet titleTemplate="theMednet - %s" defaultTitle="theMednet">
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Helmet>
          <Manager>
            <ErrorBoundary>
              <Tracking history={history} />
            </ErrorBoundary>
            <ErrorBoundary>
              <AnteriorHeader history={history} />
            </ErrorBoundary>
            <ErrorBoundary>
              <OnboardingModal />
            </ErrorBoundary>
            <ErrorBoundary>
              <Mention />
            </ErrorBoundary>
            <Switch>
              {_.map(ROUTES, (routeObj) => {
                const { label, ...routeProps } = routeObj
                return <Route key={routeObj.path} {...routeProps} />
              })}
            </Switch>
          </Manager>
        </NavigationContext.Provider>
      </GlobalEventListener>
    </ErrorBoundary>
  )
}

export default App
