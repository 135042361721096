import { takeLatest, all } from 'redux-saga/effects'
import _ from 'lodash'

import { makeURL } from 'mednet-util/src/router'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

import {
  ADD_DIGEST_UPDATE,
  CREATE_DIGEST,
  DELETE_DIGEST_UPDATE,
  FETCH_TODAY_DIGEST,
} from './digest'
import { makeRequestName } from './request'

export const SEARCH_FOR_UPDATES = 'questionUpdate/SEARCH_FOR_UPDATES'
export const FETCH_UPDATE = 'questionUpdate/FETCH_UPDATE'
export const FETCH_UPDATE_IN_NOTIFICATION_USAGE =
  'questionUpdate/FETCH_UPDATE_IN_NOTIFICATION_USAGE'

export function searchQuestionUpdate(specialtyId, params, callback) {
  return {
    type: SEARCH_FOR_UPDATES,
    requestId: `${specialtyId}`,
    specialtyId,
    params,
    callback,
  }
}

export function fetchUpdate(updateId) {
  return {
    type: FETCH_UPDATE,
    requestId: `${updateId}`,
    updateId,
  }
}

export function fetchUpdateInNotificationUsage(
  specialtyId,
  updateIds,
  excludeNotificationIds
) {
  return {
    type: FETCH_UPDATE_IN_NOTIFICATION_USAGE,
    specialtyId,
    updateIds,
    excludeNotificationIds,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, SEARCH_FOR_UPDATES, (action) =>
    makeURL(`questionUpdate/search/${action.specialtyId}`, action.params)
  )
  yield makeFetchEffect(
    takeLatest,
    FETCH_UPDATE_IN_NOTIFICATION_USAGE,
    (action) =>
      makeURL(`questionUpdate/getUpdateInNotificationUsageJSON`, {
        updateIds: action.updateIds,
        excludeNotificationIds: action.excludeNotificationIds,
        specialtyId: action.specialtyId,
      })
  )
  yield makeFetchEffect(takeLatest, FETCH_UPDATE, (action) =>
    makeURL(`questionUpdate/getJSON/${action.updateId}`)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  search: {},
  notifications: {},
  updates: {},
}

const reduceUpdates = (updates, update) => {
  updates[update.questionUpdateId] = { ...update }
  return updates
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(CREATE_DIGEST):
    case receiveAction(FETCH_TODAY_DIGEST):
    case receiveAction(ADD_DIGEST_UPDATE):
    case receiveAction(DELETE_DIGEST_UPDATE):
      if (action.response.updates) {
        return receiveReducer(state, action, () => ({
          updates: {
            ...state.updates,
            ...action.response.updates.reduce(reduceUpdates, {}),
          },
        }))
      }
      return state
    case receiveAction(FETCH_UPDATE):
      if (action.response.update) {
        return receiveReducer(state, action, () => ({
          updates: {
            ...state.updates,
            ...{ [action.updateId]: action.response.update },
          },
        }))
      }
      return state
    case receiveAction(SEARCH_FOR_UPDATES): {
      return receiveReducer(state, action, () => ({
        search: {
          [action.specialtyId]: {
            ...state.search,
            answers: [...action.response.answers],
            questions: [...action.response.questions],
            polls: [...action.response.polls],
          },
        },
        updates: {
          ...state.updates,
          ...action.response.answers.reduce(reduceUpdates, {}),
          ...action.response.questions.reduce(reduceUpdates, {}),
          ...action.response.polls.reduce(reduceUpdates, {}),
        },
      }))
    }

    case receiveAction(FETCH_UPDATE_IN_NOTIFICATION_USAGE): {
      return receiveReducer(state, action, () => {
        const specialtyNotifications =
          state.notifications[action.specialtyId] || {}
        return {
          notifications: {
            ...state.notifications,
            [action.specialtyId]: {
              ...specialtyNotifications,
              ...action.response.notifications,
            },
          },
        }
      })
    }

    default:
      return state
  }
}

export function getUpdate(state, updateId) {
  return _.get(state, `questionUpdate.updates["${updateId}"]`)
}

export function getIsFetchUpdateLoading(state, updateId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_UPDATE, updateId),
    {}
  ).isLoading
}
