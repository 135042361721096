import React, { useCallback, useEffect, useRef } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

import { Button } from 'pharmacy/src/input/button'
import { Link } from 'pharmacy/src/navigation/link'
import { Icon } from 'pharmacy/src/display/icon'

import { makeAssetURL } from 'mednet-util/src/router'

import { useLatestAnnouncement } from 'mednet-cns/src/hooks/announcement'

import css from './hero.scss'

const ScrollingLogos = ({ assetsPath }) => {
  const containerRef = useRef()

  const rotateElements = useCallback(async () => {
    const firstChild = containerRef.current.firstChild
    const originalParentWidth = containerRef.current.scrollWidth
    containerRef.current.removeChild(firstChild)
    const afterRemoveParentWidth = containerRef.current.scrollWidth
    containerRef.current.appendChild(firstChild)
    containerRef.current.scrollLeft -=
      originalParentWidth - afterRemoveParentWidth
  }, [])

  const scrollInLoop = useCallback(() => {
    setTimeout(() => {
      if (containerRef.current) {
        const firstChild = containerRef.current.firstChild

        const { left: containerLeft } =
          containerRef.current.getBoundingClientRect()

        const { left: firstChildLeft, width: firstChildWidth } =
          firstChild.getBoundingClientRect()

        if (firstChildLeft + firstChildWidth <= containerLeft) {
          rotateElements() //do it asyncronously and don't wait for it so we don't feel a glitch
        }

        if (
          containerRef.current.scrollWidth -
            (containerRef.current.scrollLeft +
              containerRef.current.clientWidth) >=
          5
        ) {
          containerRef.current.scrollLeft += 1
          requestAnimationFrame(scrollInLoop)
        } else {
          // We never do this since we rotate elements, we never get to the end of the scrolling element. This code exists to handle any edge cases if found
          containerRef.current.scrollLeft = 1
          requestAnimationFrame(scrollInLoop)
        }
      }
    }, 25)
  }, [])

  useEffect(() => {
    scrollInLoop()
  }, [])

  return (
    <div className={css.heroFooterImages} ref={containerRef}>
      <div>
        <img
          className={css.mdAnderson}
          src={makeAssetURL(`${assetsPath}md-anderson-black 1.svg`)}
        />
      </div>
      <div>
        <img
          className={css.johnsHopkins}
          src={makeAssetURL(`${assetsPath}johns-hopkins-medicine-logo.svg`)}
        />
      </div>
      <div>
        <img
          className={css.clevelandClinic}
          src={makeAssetURL(`${assetsPath}cleveland-clinic-seek-logo.com.svg`)}
        />
      </div>
      <div>
        <img
          className={css.mayoClinic}
          src={makeAssetURL(`${assetsPath}mayo-clinic.svg`)}
        />
      </div>
      <div>
        <img
          className={css.universityOfChicago}
          src={makeAssetURL(`${assetsPath}the-university-of-chicago.svg`)}
        />
      </div>
      <div>
        <img
          className={css.yaleUniversity}
          src={makeAssetURL(`${assetsPath}yale-university.svg`)}
        />
      </div>
    </div>
  )
}

export const Hero = ({ assetsPath }) => {
  const [latestAnnouncment, latestAnnouncementRequest] = useLatestAnnouncement()
  const { ref: containerRef, inView } = useInView()

  return (
    <div
      className={css.hero}
      style={{
        backgroundImage: `url("${makeAssetURL(
          `${assetsPath}Hero Pattern.svg`
        )}"),
        linear-gradient(5deg, #2f358f 0.01%, #509cf5 100%)`,
      }}
    >
      {latestAnnouncementRequest.isLoaded && (
        <div className={css.newsBanner}>
          <a href={latestAnnouncment.url} target="_blank" rel="noreferrer">
            <div className={css.newsBannerText}>
              <div>
                <img
                  src={makeAssetURL(`${assetsPath}newsIcon.svg`)}
                  className={css.newsIcon}
                />
              </div>

              <div>
                <span className={css.newsBannerUnderlinedText}>
                  {latestAnnouncment.title}
                </span>
                <span className={css.newsBannerSubText}>
                  <span className={css.newsBannerSubtitle}>
                    {latestAnnouncment.subtitle &&
                      `${latestAnnouncment.subtitle} · `}
                  </span>
                  <span>
                    {moment(latestAnnouncment.date, 'YYYY-MM-DD').format(
                      'MMMM DD, YYYY'
                    )}
                  </span>
                </span>
              </div>
            </div>
          </a>
          <a
            href={latestAnnouncment.url}
            target="_blank"
            rel="noreferrer"
            className={css.newsBannerLink}
          >
            Read the News
            <Icon
              className="followIcon"
              icon={['fas', 'long-arrow-right']}
              prefix="fas"
            />
          </a>
        </div>
      )}
      <div className={css.heroMainContent}>
        <div className={css.heroText}>
          A physician-only Q&A platform for expert answers to real world
          clinical questions
          <div className={css.registerSignInActions}>
            <Button
              type="orange"
              className={css.registerButton}
              pathname="/register"
            >
              <div className={css.registerButtonText}>Register</div>
            </Button>
            <Link className={css.loginLink} pathname="/user/login">
              Log in
            </Link>
          </div>
        </div>
        <div
          className={classNames(
            css.answersPreviewContainer,
            css.answersPreviewForLargeScreen
          )}
        >
          <div className={css.answersPreview1}>
            <img
              src={makeAssetURL(
                `${assetsPath}homepage back question - desktop.png`
              )}
            />
          </div>
          <div className={css.answersPreview2}>
            <img
              src={makeAssetURL(
                `${assetsPath}homepage front question - desktop.png`
              )}
            />
          </div>
        </div>
        <div
          className={classNames(
            css.answersPreviewContainer,
            css.answersPreviewForSmallScreen
          )}
        >
          <div className={css.answersPreview1}>
            <img
              className={css.answersPreview1}
              src={makeAssetURL(
                `${assetsPath}homepage back question - mobile.png`
              )}
            />
          </div>
          <div className={css.answersPreview2}>
            <img
              className={css.answersPreview2}
              src={makeAssetURL(
                `${assetsPath}homepage front question - mobile.png`
              )}
            />
          </div>
        </div>
      </div>
      <div className={css.heroFooter} ref={containerRef}>
        <div className={css.heroFooterText}>
          Hear from leading{' '}
          <Link className={css.expertsInlineLink} pathname="/experts">
            experts
          </Link>{' '}
          from over 500 institutions
          <Link pathname="/experts">
            <div className={css.heroFooterLink}>See a List of All Experts</div>
          </Link>
        </div>
        {/* We need to stop the loop of images when hero is not visible, it affects the testimonial scrolling
        It is easier to hide the component when hero footer is not visible rather than trying to stop the loop */}
        {inView && <ScrollingLogos assetsPath={assetsPath} />}
      </div>
    </div>
  )
}
