import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Modal } from 'pharmacy/src/display/modal'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Icon } from 'pharmacy/src/display/icon'
import { RawBody } from 'pharmacy/src/display/rawBody'

import { VIEW_BIO_MODAL } from 'mednet-util/src/constants/modal'
import { getUserName } from 'mednet-util/src/string'

import { getUserBio } from 'mednet-cns/src/reducers/user'
import { closeModal } from 'mednet-cns/src/reducers/modal'

import { TitledSection } from '../titledSection'

import css from './viewBioModal.scss'

const makeSelectUser = () =>
  createSelector(
    (state) => state.user.users,
    (_, userId) => userId,
    (users, userId) => users[userId]
  )

const renderUserBio = (bio) => {
  if (bio === undefined) {
    return <StarLoader isVerticalMargin />
  }

  return bio ? <RawBody body={bio} /> : 'This person has not added his Bio yet'
}

const BioScreen = (props) => {
  const {
    userId,
    specialty,
    showTitle = true,
    showSpecialty,
    showInstitution,
    readDataFromDb,
  } = props

  const selectUser = useMemo(makeSelectUser, [])

  const user = useSelector((state) => selectUser(state, userId))

  const dispatch = useDispatch()

  useEffect(() => {
    if (user.bio === undefined) {
      dispatch(getUserBio(userId, readDataFromDb))
    }
  })

  const { title, institutionName, bio } = user
  const userName = getUserName(user)
  const subtitile = showTitle
    ? title
    : showSpecialty
    ? specialty
    : showInstitution
    ? institutionName
    : ''

  return (
    <div>
      <Icon
        icon={['fal', 'times']}
        size="lg"
        className={css.closeWindowIcon}
        onClick={() => {
          dispatch(closeModal(VIEW_BIO_MODAL.modalId))
        }}
      />
      <div className={css.containerWithMargin}>
        {userName ? (
          <TitledSection
            header3={userName}
            header5={subtitile}
            headerLeftAligned
            headerUnderlined
            contentLeftAligned
          >
            {renderUserBio(bio)}
          </TitledSection>
        ) : (
          <StarLoader isVerticalMargin />
        )}
      </div>
    </div>
  )
}

export const ViewBioModaL = (props) => {
  return (
    <Modal
      closeOnBackdrop
      modalId={VIEW_BIO_MODAL.modalId}
      size="larger"
      {...props}
    >
      <BioScreen />
    </Modal>
  )
}
