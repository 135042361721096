import React, { useCallback, useEffect } from 'react'
import { createSelector } from 'reselect'

import { Link } from 'pharmacy/src/navigation/link'

import {
  fetchSpecialties,
  getSpecialties,
  getSpecialtiesRequest,
} from 'mednet-cns/src/reducers/specialty'

import { useDispatch, useSelector } from 'react-redux'

import { getRequest } from 'mednet-cns/src/api/v1'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  fetchCountOfQuestions,
  FETCH_COUNT_OF_QUESTIONS,
} from 'mednet-cns/src/reducers/question'

import { PageTitle } from 'components/pageTitle'
import { TitledSection } from 'components/titledSection'
import { LandingPageContent } from 'components/landingPageContent'

import css from './publicFAQ.scss'

export const PublicFAQ = () => {
  const selectSpecialties = useCallback(
    createSelector(
      (state) => getSpecialties(state),
      (state) => getSpecialtiesRequest(state),
      (state) => state.question.questionsCount,
      (state) => getRequest(state, makeRequestName(FETCH_COUNT_OF_QUESTIONS)),
      (
        specialties,
        specialtiesRequest,
        questionsCount,
        questionsCountRequest
      ) => {
        const filteredSpecialties = specialties
          ? Object.entries(specialties)
              .filter(
                ([_, specialty]) =>
                  specialty.active && !/oncology/i.test(specialty.specialty)
              )
              .map(([_, specialty]) => specialty.specialty)
          : undefined

        filteredSpecialties
          ? filteredSpecialties.unshift('Oncology')
          : filteredSpecialties

        return {
          specialties: filteredSpecialties,
          specialtiesRequest,
          questionsCount,
          questionsCountRequest,
        }
      }
    ),
    []
  )

  const {
    specialties,
    specialtiesRequest,
    questionsCount,
    questionsCountRequest,
  } = useSelector((state) => selectSpecialties(state))

  const dispatch = useDispatch()

  useEffect(() => {
    if (!specialtiesRequest.isLoaded && !specialtiesRequest.isLoading) {
      dispatch(fetchSpecialties())
    }

    if (!questionsCountRequest.isLoaded && !questionsCountRequest.isLoading) {
      dispatch(fetchCountOfQuestions())
    }
  })

  const specialtiesString = specialties
    ? specialties.join(', ').replace(/,([^,]*)$/, ', and$1')
    : 'Oncology, Hematology, Rheumatology, Neurology, Dermatology, Pulmonology, and Nephrology'

  const titledSectionCommonProps = {
    className: css.questionAnswerContainer,
    headerLeftAligned: true,
    contentLeftAligned: true,
    headerUnderlined: true,
  }

  return (
    <LandingPageContent>
      <PageTitle>FAQs</PageTitle>
      <div className={css.faqsContainer}>
        <TitledSection
          {...titledSectionCommonProps}
          header2="Q1: What kind of questions are on theMednet?"
        >
          To ensure our platform is valuable and additive to the broader medical
          community, questions must meet the following criteria:
          <ol type="1">
            <li className={css.listItem}>
              <b>Not addressed in existing resources.</b> Questions must fill a
              gap in current knowledge resources (i.e., textbooks, guidelines,
              or UpToDate).
            </li>
            <li className={css.listItem}>
              <b>Relevant to the wider community.</b> To be most effective,
              questions should be helpful to the broader community and not be
              overly specific such as unique patient cases.
            </li>
            <li className={css.listItem}>
              <b>Clinical in nature.</b> Questions must be clinical in nature
              and answerable. Purely theoretical or &quot;food for thought&quot;
              questions are not accepted.
            </li>
          </ol>
        </TitledSection>
        <TitledSection
          {...titledSectionCommonProps}
          header2="Q2: How is theMednet different from other online communities?"
        >
          Unlike traditional online medical communities, theMednet is not a chat
          forum or discussion board. Through our moderated Q&A format, we
          provide peer-reviewed expert answers that physicians can put into
          clinical practice to improve patient outcomes. That&rsquo;s why time
          spent on theMednet now counts toward CME.
        </TitledSection>
        <TitledSection
          {...titledSectionCommonProps}
          header2="Q3: How do we know expert opinions are not biased?"
        >
          At theMednet, transparency is paramount. All authors are required to
          disclose any potential conflicts of interest before their responses
          are published. Any question that arose as a result of a
          <Link className={css.link} pathname="/programs">
            {' '}
            special educational program{' '}
          </Link>
          (&lt;5% of questions on the site) is disclosed. These precautions
          ensure physicians can make informed decisions based on impartial
          expert knowledge.
        </TitledSection>
        <TitledSection
          header2="Q4: How do I get an answer to my question?"
          {...titledSectionCommonProps}
        >
          We recommend you start by searching theMednet&rsquo;s database of more
          than {questionsCount.toLocaleString()} questions to see if there
          already is an answer to your question. If you don&rsquo;t find your
          question, ask it! A physician editor will match your question with
          experts best suited to answer it. You&rsquo;ll be notified by email
          once it&rsquo;s answered.
        </TitledSection>
        <TitledSection
          header2="Q5: Who can join theMednet?"
          {...titledSectionCommonProps}
        >
          Only board-certified or eligible physicians in select specialties in
          the US can join. Today, theMednet community includes{' '}
          {specialtiesString}. We expect to launch additional specialties in{' '}
          {new Date().getMonth() < 10
            ? new Date().getFullYear()
            : new Date().getFullYear() + 1}
          , so stay tuned for updates.
        </TitledSection>
        <TitledSection
          {...titledSectionCommonProps}
          header2="Q6: How is theMednet able to offer its service for free? "
        >
          theMednet was initially self-funded by Nadine and Samir prior to
          receiving a number of grants from organizations like the National
          Science Foundation, the SWOG Hope Foundation, and the Bonnie Addario
          Lung Cancer Foundation. More recently, theMednet has received
          sponsorship funding for special educational programs we feel are
          medically relevant and necessary for the community, such as Journal
          Clubs. With any partnership, we guarantee that:
          <ul>
            <li>
              All Q&A continue to come solely from our physician community with
              no input from the sponsor
            </li>
            <li>Q&A from a sponsored program is disclosed on the site</li>
            <li>
              No physician user data is ever provided back to our sponsors.{' '}
            </li>
          </ul>
          These programs (&lt; 5% of the Q&A on the site) allow us to continue
          to offer theMednet for free.
        </TitledSection>
        <TitledSection
          {...titledSectionCommonProps}
          header2="Q7: Is there data that shows that theMednet improves patient outcomes?"
        >
          Yes! We&rsquo;ve found that more than a third of the time, physicians
          change their patient approach or learn something new from theMednet.
          You can read that publication{' '}
          <Link
            className={css.link}
            pathname="https://pubmed.ncbi.nlm.nih.gov/35588361/"
            external
            target="_blank"
          >
            here
          </Link>
          .
        </TitledSection>
      </div>
    </LandingPageContent>
  )
}
