import React from 'react'

import { Button } from 'pharmacy/src/input/button'

import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import RequestAnswersButton from './requestAnswersButton'

import css from './questionAnswerButtons.scss'

class QuestionAnswersButtons extends React.Component {
  render() {
    const { questionId } = this.props

    return (
      <div className={css.buttonsContainer}>
        <Button
          className={css.writeAnswerButton}
          icon="edit"
          hash="#writeAnswer"
          responsive
        >
          Write an Answer
        </Button>
        <ErrorBoundary>
          <RequestAnswersButton questionId={questionId} />
        </ErrorBoundary>
      </div>
    )
  }
}

export default withPermissions(authItems.createAnswer)(QuestionAnswersButtons)
