import React, { useCallback, useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Utils as QbUtils } from 'react-awesome-query-builder'

import {
  useActiveUsersFromQueryLazy,
  useSelectedUsers,
  useUserTreeQuery,
  useUsersFromQueryLazy,
  useUnregisteredUsersFromQueryLazy,
} from 'mednet-cns/src/hooks/user'

import { Page } from 'pharmacy/src/display/page'
import CenteredContent from 'pharmacy/src/display/content/centeredContent'

import UserTable from 'components/tables/userTable'
import UserSearchWithPresets from 'components/userSearchQueryBuilder/userSearchWithPresets'
import { useQueryBuilderConfig } from 'components/userSearchQueryBuilder/config'

import * as css from './userSearch.scss'

const treeToLogic = (tree, config) => {
  const initialTree = !isEmpty(tree)
    ? tree
    : { id: QbUtils.uuid(), type: 'group' }
  const immutableTree = QbUtils.checkTree(QbUtils.loadTree(initialTree), config)
  const jsonLogic = QbUtils.jsonLogicFormat(immutableTree, config)
  const query = { query: jsonLogic.logic || {} }
  query.id = initialTree.id
  query.name = tree?.name
  query.presetId = tree?.presetId

  return query
}

const UserSearchPage = ({
  queryUserStatus = 'default',
  activeTab,
  selectable,
}) => {
  /** The tree is stored in redux store, and it's changed either in the UserSearchWithPresets component or with ancestor component (recipientsModal) */
  const [tree, setUserTreeQuery] = useUserTreeQuery()
  const [selected, setSelectedUsers] = useSelectedUsers()
  const selectedKeys = Object.keys(selected)

  const config = useQueryBuilderConfig(queryUserStatus)
  const query = useMemo(() => {
    return treeToLogic(tree, config)
  }, [tree])

  const usersHookMapping = {
    active: useActiveUsersFromQueryLazy,
    unregistered: useUnregisteredUsersFromQueryLazy,
    default: useUsersFromQueryLazy,
  }

  const useUsers = usersHookMapping[queryUserStatus] ?? usersHookMapping.default
  const [users, usersRequestStatus, queryUsers] = useUsers(
    query.id,
    query.query,
    selectedKeys
  )
  const { isLoading, isLoaded, errorStatus } = usersRequestStatus

  const handleSelect = useCallback((selected) => {
    setSelectedUsers(selected)
  })

  const handleQueryChange = useCallback((data) => {
    setUserTreeQuery({ ...data.tree, name: data.name, presetId: data.presetId })
    setSelectedUsers({})
  })

  useEffect(() => {
    if (!isEmpty(query?.query)) {
      queryUsers(query.id, query.query, selectedKeys)
    }
  }, [query, queryUsers])

  useEffect(() => {
    if (!isLoading && isLoaded && selectable && query.presetId) {
      setSelectedUsers(
        users.reduce((mapping, user) => {
          mapping[user.id] = true
          return mapping
        }, {})
      )
    }
  }, [isLoading, isLoaded, tree?.presetId])

  return (
    <Page className={css.page}>
      <CenteredContent size="xlarge">
        <UserSearchWithPresets
          onQueryChange={handleQueryChange}
          queryUserStatus={queryUserStatus}
          activeTab={activeTab}
          tree={tree}
        />
        <UserTable
          users={users}
          onSelect={selectable && handleSelect}
          selected={selectable && selected}
          isLoading={isLoading}
          loadingDataError={Boolean(errorStatus)}
          errorMessage="Couldn't load users, please refine your query and try again."
        />
      </CenteredContent>
    </Page>
  )
}

export default UserSearchPage
