import React from 'react'

import { FlexRow, FlexRowCell } from 'pharmacy/src/display/content'
import { Link } from 'pharmacy/src/navigation/link'

import { makeAssetURL } from 'mednet-util/src/router'

import { PageTitle } from 'components/pageTitle'
import { HighlightsBar } from 'components/highlightsBar'
import { SectionHeader } from 'components/titledSection/sectionHeader'
import { TitledSection } from 'components/titledSection/titledSection'
import { LandingPageContent } from 'components/landingPageContent'

import { PartnershipFrom } from './partnershipFrom'

import css from './partnerships.scss'

const assetsPath = 'images/partnerships/'

const MainHeader = () => (
  <div className={css.mainHeader}>
    Engage with <span className={css.blueText}>experts</span> in rich discussion
    through one of our
    <span className={css.blueText}> Partnership Programs</span>
  </div>
)

const Paragraph = ({ firstHeader, secondHeader, children }) => (
  <FlexRowCell className={css.centeredContainer}>
    <TitledSection
      header2={firstHeader}
      headerLeftAligned
      contentLeftAligned
      headerUnderlined
      className={css.paragraph}
    >
      <SectionHeader header3={secondHeader} headerLeftAligned />
      {children}
    </TitledSection>
  </FlexRowCell>
)

const Image = ({ imgName }) => (
  <FlexRowCell className={css.centeredContainer}>
    <img className={css.image} src={makeAssetURL(`${assetsPath}${imgName}`)} />
  </FlexRowCell>
)

const ParagraphWithImageRow = ({
  firstHeader,
  secondHeader,
  children,
  imgName,
  isImageFirst,
  className,
}) => {
  return (
    <FlexRow
      size="large"
      fullWidth
      className={className}
      verticalMargins={false}
    >
      {isImageFirst && <Image imgName={imgName} />}
      <Paragraph firstHeader={firstHeader} secondHeader={secondHeader}>
        {children}
      </Paragraph>
      {!isImageFirst && <Image imgName={imgName} />}
    </FlexRow>
  )
}

const JournalClubSection = ({ className, imageAllowedFirst }) => (
  <ParagraphWithImageRow
    firstHeader="Journal Club"
    secondHeader="Stay up-to-date with the latest in research and data"
    imgName="journal-on-laptop.png"
    isImageFirst={imageAllowedFirst}
    className={className}
  >
    Journal Clubs are a great way to keep up to date with the latest research
    and start a discussion about how to apply in clinical practice.
    <br /> <br />
    In partnership with ASCO and major medical publications (NEJM, Lancet, JCO,
    etc), theMednet is running online journal clubs where doctors get free, easy
    access to pivotal publications, and have the opportunity to engage with
    experts and study authors on the research and practice implications.
  </ParagraphWithImageRow>
)

const TargetedTopicSection = ({ className }) => (
  <ParagraphWithImageRow
    firstHeader="Targeted Topic"
    secondHeader="Learn from experts about a topic or disease area"
    imgName="national-cancer-institute.png"
    isImageFirst={false}
    className={className}
  >
    Deep dive into a specific topic of interest with experts.
    <br /> <br />
    Periodically, theMednet will initiate discussion and solicit questions on a
    singular topic to provide a holistic expert view on a subject through Q&A.
  </ParagraphWithImageRow>
)

const ClinicalTrialSection = ({ className, imageAllowedFirst }) => (
  <ParagraphWithImageRow
    firstHeader="Clinical Trials"
    secondHeader="Raising awareness about NCI clinical trials"
    imgName="trial.png"
    isImageFirst={imageAllowedFirst}
    className={className}
  >
    theMednet is committed to helping physicians get answers to their clinical
    questions. Often, the best answer to a question is a clinical trial.
    <br /> <br />
    theMednet has partnered with NCI and the National Science Foundation to
    connect physicians with more trials around the country so more patients can
    get tomorrow’s standard of care.
  </ParagraphWithImageRow>
)

const TumorBoardSection = ({ className }) => (
  <ParagraphWithImageRow
    firstHeader="Tumor Board"
    secondHeader="Taking institution level tumor boards national"
    imgName="doctors-around-table.png"
    isImageFirst={false}
    className={className}
  >
    Get a peek into the types of discussions that are happening at top cancer
    centers around the country.
    <br /> <br />
    In collaboration with select NCI cancer centers, we designed a process to
    capture knowledge and discussion from top cancer centers and share with the
    Oncology community.
    <br /> <br />
    <Link className={css.link} pathname="/tumorboard">
      Click here to see the list of participating institutions and Site Leaders
    </Link>
  </ParagraphWithImageRow>
)

const FormSection = () => (
  <FlexRow size="large" fullWidth>
    <FlexRowCell className={css.formDescriptionContainer}>
      <div className={css.formDescription}>
        <SectionHeader
          header2="Want to partner with us?"
          headerUnderlined
          headerLeftAligned
        />
        <SectionHeader
          header3="Contact us to start the conversation."
          headerLeftAligned
        />
      </div>
    </FlexRowCell>
    <FlexRowCell className={css.formContainer}>
      <PartnershipFrom />
    </FlexRowCell>
  </FlexRow>
)
export const Partnerships = () => {
  return (
    <LandingPageContent>
      <PageTitle>Partnerships</PageTitle>

      <div className={css.mainHeaderContainer}>
        <SectionHeader header1={<MainHeader />} />
      </div>

      <div className={css.paragraphWithImageContainer}>
        <JournalClubSection
          imageAllowedFirst
          className={css.displayWhenExtraLargeScreen}
        />
        <JournalClubSection className={css.displayWhenNotExtraLargeScreen} />
      </div>

      <div id="targeted_topic" className={css.paragraphWithImageContainer}>
        <TargetedTopicSection
          imageAllowedFirst
          className={css.displayWhenExtraLargeScreen}
        />
        <TargetedTopicSection className={css.displayWhenNotExtraLargeScreen} />
      </div>

      <div id="clinical_trial" className={css.paragraphWithImageContainer}>
        <ClinicalTrialSection
          imageAllowedFirst
          className={css.displayWhenExtraLargeScreen}
        />
        <ClinicalTrialSection className={css.displayWhenNotExtraLargeScreen} />
      </div>

      <div className={css.paragraphWithImageContainer}>
        <TumorBoardSection
          imageAllowedFirst
          className={css.displayWhenExtraLargeScreen}
        />
        <TumorBoardSection className={css.displayWhenNotExtraLargeScreen} />
      </div>

      <div className={css.formSection}>
        <FormSection />
      </div>

      <HighlightsBar header="Our Mission" className={css.missionContainer}>
        <div className={css.mission}>
          Improve knowledge sharing between physicians so that patients get high
          quality care wherever they are treated.
        </div>
      </HighlightsBar>
    </LandingPageContent>
  )
}
