import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import {
  CenteredContent,
  FlexRow,
  FlexRowCell,
} from 'pharmacy/src/display/content'

import {
  getSpecialties,
  getSpecialtiesRequest,
  fetchSpecialties,
} from 'mednet-cns/src/reducers/specialty'
import {
  getDeputyEditors,
  getAssociateEditors,
  getDeputyEditorsRequest,
  getAssociateEditorsRequest,
  getAssociateEditorsSpecialtyFilter,
  fetchDeputyEditors,
  fetchAssociateEditors,
  setAssociateEditorsSpecialtyFilter,
} from 'mednet-cns/src/reducers/editor'

import { PageTitle } from 'components/pageTitle'
import { TitledSection } from 'components/titledSection'
import { UserPhotoCard } from 'components/userPhotoCard'
import { SpecialtyFilters } from 'components/specialtyFilters'
import { ViewBioModaL } from 'components/viewBioModal'
import { LandingPageContent } from 'components/landingPageContent'

import css from './editors.scss'

const renderEditorsIntroduction = () => {
  return (
    <FlexRow>
      <FlexRowCell>
        <TitledSection header2="Editors" headerUnderlined>
          Editors are responsible for maintaining quality and professionalism on
          the platform by moderating questions and engaging experts in their
          field.
        </TitledSection>
      </FlexRowCell>
      <FlexRowCell>
        <TitledSection header2="Peer-Review" headerUnderlined>
          Every day new questions are peer-reviewed and put through our rigorous
          editorial and moderation process to ensure the highest standards.
        </TitledSection>
      </FlexRowCell>
      <FlexRowCell>
        <TitledSection header2="Moderation" headerUnderlined>
          Questions are matched with experts uniquely qualified to answer based
          on their sub-specialty, research areas, publications, clinical trial
          involvement, peer recommendations, and more.
        </TitledSection>
      </FlexRowCell>
    </FlexRow>
  )
}

const renderDeputyEditors = (deputyEditors, specialties) => {
  return (
    <TitledSection header2="Deputy Editors" headerUnderlined>
      <FlexRow fullWidth size="small">
        {deputyEditors.map((deputyEditor) => (
          <FlexRowCell key={deputyEditor.userId}>
            <UserPhotoCard
              user={{
                ...deputyEditor,
                specialty: specialties[deputyEditor.specialtyId].specialty,
              }}
              size="large"
              showTitle={false}
              showSpecialty
            />
          </FlexRowCell>
        ))}
      </FlexRow>
    </TitledSection>
  )
}

const renderAssociateEditorsIntroduction = () => {
  return (
    <CenteredContent size="large" className={css.introductionContainer}>
      <TitledSection header2="Associate Editors" headerUnderlined>
        Associate Editors are Faculty and Fellows who have been invited to
        moderate based on their commitment to medical education in their
        specialty.
      </TitledSection>
    </CenteredContent>
  )
}

const renderSpecialtyEditors = (
  specialtiesEditors,
  filteredSpecialty,
  showTitle
) => {
  return (
    <TitledSection header3={filteredSpecialty.specialty}>
      <FlexRow size="small">
        {specialtiesEditors[filteredSpecialty.specialtyId].map((editor) => (
          <FlexRowCell key={editor.userId}>
            <UserPhotoCard
              size="small"
              showTitle={showTitle}
              showInstitution
              user={editor}
              horizontalInSmallScreens
            />
          </FlexRowCell>
        ))}
      </FlexRow>
    </TitledSection>
  )
}

const checkIfFetchNeeded = (request) => {
  return !request.isLoading && !request.isLoaded
}

const getFilterSpecialty = (activeSpecialties, filterSpecialtyId) => {
  return activeSpecialties
    ? activeSpecialties[filterSpecialtyId ? filterSpecialtyId : 2]
    : undefined
}

const getSpecialtiesUsers = (specialtiesUsersIds, users) => {
  return specialtiesUsersIds
    ? Object.fromEntries(
        Object.entries(specialtiesUsersIds).map(([specialtyId, usersIds]) => [
          specialtyId,
          usersIds.map((userId) => users[userId]),
        ])
      )
    : undefined
}

const makeSelectEditors = () =>
  createSelector(
    (state) => getSpecialties(state),
    (state) => getDeputyEditors(state),
    (state) => getAssociateEditors(state),
    (state) => state.user.users,
    (state) => getAssociateEditorsSpecialtyFilter(state),
    (state) => getSpecialtiesRequest(state),
    (state) => getDeputyEditorsRequest(state),
    (state) => getAssociateEditorsRequest(state),
    (
      specialties,
      deputyEditorsIds,
      specialtiesAssociateEditorsIds,
      users,
      associateEditorsFilterSpecialtyId,
      specialtiesRequest,
      deputyEditorsRequest,
      associateEditorsRequest
    ) => {
      const activeSpecialties = specialties
        ? Object.fromEntries(
            Object.entries(specialties).filter(
              ([_, specialty]) => specialty.active
            )
          )
        : undefined

      const deputyEditors =
        deputyEditorsIds && activeSpecialties
          ? deputyEditorsIds
              .map((userId) => users[userId])
              .filter((user) => activeSpecialties[user.specialtyId])
          : undefined

      const specialtiesAssociateEditors = getSpecialtiesUsers(
        specialtiesAssociateEditorsIds,
        users
      )

      const filteredAssociateEditorsSpecialty = getFilterSpecialty(
        activeSpecialties,
        associateEditorsFilterSpecialtyId
      )

      return {
        specialties: activeSpecialties,
        deputyEditors,
        specialtiesAssociateEditors,
        filteredAssociateEditorsSpecialty,
        specialtiesRequest,
        deputyEditorsRequest,
        associateEditorsRequest,
      }
    }
  )

export const Editors = (props) => {
  const params = new URLSearchParams(props.location.search)

  const selectEditors = useMemo(makeSelectEditors, [])
  const {
    specialties,
    deputyEditors,
    specialtiesAssociateEditors,
    filteredAssociateEditorsSpecialty,
    specialtiesRequest,
    deputyEditorsRequest,
    associateEditorsRequest,
  } = useSelector((state) => selectEditors(state))

  const dispatch = useDispatch()

  useEffect(() => {
    if (checkIfFetchNeeded(specialtiesRequest)) {
      dispatch(fetchSpecialties())
    }

    if (checkIfFetchNeeded(deputyEditorsRequest)) {
      dispatch(fetchDeputyEditors())
    }

    if (checkIfFetchNeeded(associateEditorsRequest)) {
      dispatch(fetchAssociateEditors())
    }

    dispatch(
      setAssociateEditorsSpecialtyFilter(parseInt(params.get('specialtyId')))
    )
  }, [])

  const associateEditorsSpecialties =
    specialtiesRequest.isLoaded && associateEditorsRequest.isLoaded
      ? Object.fromEntries(
          Object.entries(specialties).filter(
            ([specialtyId, _]) => specialtiesAssociateEditors[specialtyId]
          )
        )
      : undefined

  const dispatchSetAssociateEditorsSpecialtyFilter = (specialtyId) => {
    dispatch(setAssociateEditorsSpecialtyFilter(specialtyId))
  }

  return (
    <LandingPageContent>
      <PageTitle>Editors</PageTitle>
      {renderEditorsIntroduction()}

      {!specialtiesRequest.isLoaded || !deputyEditorsRequest.isLoaded ? (
        <StarLoader isVerticalMargin />
      ) : (
        renderDeputyEditors(deputyEditors, specialties)
      )}

      {renderAssociateEditorsIntroduction()}

      {!specialtiesRequest.isLoaded || !associateEditorsRequest.isLoaded ? (
        <StarLoader isVerticalMargin />
      ) : (
        <React.Fragment>
          <SpecialtyFilters
            specialties={Object.values(associateEditorsSpecialties)}
            setSpecialtyFilter={dispatchSetAssociateEditorsSpecialtyFilter}
            filteredSpecialtyId={filteredAssociateEditorsSpecialty.specialtyId}
          />
          {renderSpecialtyEditors(
            specialtiesAssociateEditors,
            filteredAssociateEditorsSpecialty,
            true
          )}
        </React.Fragment>
      )}

      <ViewBioModaL />
    </LandingPageContent>
  )
}
