import React from 'react'
import Autosuggest from 'react-autosuggest'
import { connectAutoComplete } from 'react-instantsearch-dom'

import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'
import { Subtitle2 } from 'pharmacy/src/typography'
import { formatName } from 'mednet-util/src/string'
import { getHitURL } from 'mednet-util/src/search'
import { mixpanel } from 'mednet-util/src/tracking'

import { mednetAPI, makePOSTHeaders } from 'mednet-cns/src/api/v1'

import { GatedAutoCompleteAskQuestionButton } from './headerAutoCompleteAskQuestionButton'

import css from './headerAutoComplete.scss'

const askQuestionSuggestionPlaceholder = 'ask_question_placeholder'

class HeaderAutoComplete extends React.Component {
  state = {
    value: this.props.currentRefinement,
    typingTimer: undefined,
  }

  onChange = (event, { newValue }) => {
    if (this.state.typingTimer) {
      clearTimeout(this.state.typingTimer)
    }

    // Track when stopped typing for 2 sec
    const typingTimer = setTimeout(() => {
      const { hits } = this.props
      const questionsHitsCount =
        hits.find((hit) => hit.index.endsWith('question'))?.hits?.length || 0
      const usersHitsCount =
        hits.find((hit) => hit.index.endsWith('user'))?.hits?.length || 0
      const topicsHitsCount =
        hits.find((hit) => hit.index.endsWith('topic'))?.hits?.length || 0

      mixpanel.track('entered_search_query_web', {
        search_query: this.state.value,
        result_count: questionsHitsCount + usersHitsCount + topicsHitsCount,
        user_result_count: usersHitsCount,
        topics_result_count: topicsHitsCount,
        question_result_count: questionsHitsCount,
      })
    }, 2000)

    this.setState({
      value: newValue,
      typingTimer,
    })
  }

  onFocus = (event) => {
    // matches mediaMedium in constants.scss
    if (window.innerWidth < 1050) {
      event.target.scrollIntoView(true, { behavior: 'smooth' })
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
  }

  onSuggestionsClearRequested = () => {
    this.props.refine()
    this.setState({
      value: '',
    })
  }

  getSuggestionValue(hit) {
    if (hit.question_id) {
      return hit.question
    } else if (hit.user_id) {
      const { firstname, middlename, lastname } = hit
      return formatName(firstname, middlename, lastname)
    } else if (hit.topic_id) {
      return hit.topic
    }

    return null
  }

  onSuggestionClick = (hit) => (e) => {
    // Added this for mobile, without this the event propagates to the window and when clicking a reult in mobile,
    // the search results just disappear instead of redirecting to the clicked result
    e.stopPropagation()
    const formData = new FormData()
    formData.append('term', this.props.currentRefinement)

    if (hit.question_id) {
      formData.append('result_type', 'question')
      formData.append('result_id', hit.question_id)
    } else if (hit.topic_id) {
      formData.append('result_type', 'topic')
      formData.append('result_id', hit.topic_id)
    } else if (hit.user_id) {
      formData.append('result_type', 'user')
      formData.append('result_id', hit.user_id)
    }

    mednetAPI('search/registerSearch', makePOSTHeaders(formData))
  }

  renderSuggestion = (hit) => {
    if (hit === askQuestionSuggestionPlaceholder) {
      return <GatedAutoCompleteAskQuestionButton />
    }

    const highlightResult = hit._highlightResult

    let hitContent
    let hitPosition
    const { hits } = this.props

    if (hit.question_id) {
      hitContent = highlightResult.question.value
      const questionHits = hits.find((item) =>
        item.index.endsWith('question')
      )?.hits

      hitPosition =
        1 +
        questionHits?.findIndex((item) => item.question_id === hit.question_id)
    } else if (hit.user_id) {
      const { firstname, middlename, lastname } = highlightResult
      hitContent = formatName(
        firstname.value,
        middlename.value,
        lastname.value,
        '',
        { useMiddleInitial: false }
      )
    } else if (hit.topic_id) {
      hitContent = highlightResult.topic.value
    }

    return (
      <Link
        className={css.suggestionLink}
        external
        pathname={getHitURL(hit, {
          src: 'popover-search',
          query: this.state.value,
          pos: hitPosition,
        })}
        onClick={this.onSuggestionClick(hit)}
      >
        <Subtitle2>
          <div
            className={css.suggestionLinkContent}
            dangerouslySetInnerHTML={{ __html: hitContent }}
          />
        </Subtitle2>
      </Link>
    )
  }

  renderSuggestionsContainer({ containerProps, children, query }) {
    // we need to do this because of https://github.com/moroshko/react-autosuggest/issues/570
    const filteredChildren =
      children &&
      children.filter((child) => {
        // eslint-disable-next-line no-unused-vars
        const [title, items] = child.props.children
        return items.props.items.length
      })
    return (
      <div {...containerProps}>
        {query && query.length && <div>{filteredChildren}</div>}
      </div>
    )
  }

  renderSectionTitle(section) {
    const sectionTitle = section.index
      ? `${section.index.split('_')[1]}s`
      : null

    if (!section.hits.length && sectionTitle !== 'questions') {
      return null
    }

    return sectionTitle
  }

  getSectionSuggestions(section) {
    if (section.index && section.index.includes('question')) {
      return [...section.hits, askQuestionSuggestionPlaceholder]
    }

    return section.hits
  }

  onKeyUp(event) {
    if (
      event.keyCode === 13 &&
      event.target.value &&
      event.target.value.trim().length
    ) {
      window.location.assign(
        `/search/siteSearch?query=${encodeURIComponent(event.target.value)}`
      )
    }
  }

  renderInputComponent = (inputProps) => {
    return (
      <div className={css.inputContainer}>
        <input {...inputProps} onKeyUp={this.onKeyUp} />
        <Icon className={css.inputIcon} icon={['far', 'search']} />
      </div>
    )
  }

  render() {
    const { hits } = this.props
    const { value } = this.state

    const inputProps = {
      placeholder: 'Search',
      onChange: this.onChange,
      onFocus: this.onFocus,
      value,
    }

    const theme = {
      sectionContainer: css.sectionContainer,
      sectionContainerFirst: css.sectionContainerFirst,
      sectionTitle: css.sectionTitle,
      suggestion: css.suggestion,
      suggestionsContainer: css.suggestionsContainer,
      suggestionsList: css.suggestionsList,
      input: css.input,
    }

    return (
      <Autosuggest
        getSectionSuggestions={this.getSectionSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        inputProps={inputProps}
        multiSection
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        renderInputComponent={this.renderInputComponent}
        renderSectionTitle={this.renderSectionTitle}
        renderSuggestion={this.renderSuggestion}
        suggestions={hits}
        theme={theme}
      />
    )
  }
}

export default connectAutoComplete(HeaderAutoComplete)
