import React, { useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { Field } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { Icon } from 'pharmacy/src/display/icon'
import { ButtonUpload } from 'pharmacy/src/input/button'
import { Link } from 'pharmacy/src/navigation/link'
import { Subtitle2 } from 'pharmacy/src/typography'

import { publicationAccess } from 'mednet-util/src/constants/publication'

import css from './attachmentUpdateSubForm.scss'

export const AttachmentUpdateSubForm = ({
  publication,
  values,
  setFieldValue,
}) => {
  const handleFileUpload = useCallback(
    (file) => {
      setFieldValue('attachment', file)
    },
    [setFieldValue]
  )

  useEffect(() => {
    if (values.attachmentAction === 'delete' && values.noPubmedId) {
      if (!isEmpty(publication.fileLocation)) {
        setFieldValue('attachmentAction', 'none')
      } else {
        setFieldValue('attachmentAction', 'add')
      }
    }
  }, [values.noPubmedId])

  const isOpenAccess =
    publication.access === publicationAccess.OPENED || publication.openAccess

  const actions = [
    {
      value: 'none',
      description: 'Use the attached file as it is.',
    },
    {
      value: 'updateAccess',
      description: `Use the attached file, but change the access to ${
        isOpenAccess ? 'closed.' : 'opened.'
      }`,
    },
    {
      value: 'delete',
      description: 'Delete the attached file and use the pubmed link instead.',
      disabled:
        !publication.pubmedId &&
        values.noPubmedId !== false && // if the field does not exist or it is true, enable delete
        ' - invalid as there is no pubmed Id for this paper',
    },
    {
      value: 'replace',
      description: 'Replace the attached file with a new one.',
    },
  ]

  return (
    <>
      {!isEmpty(publication.fileLocation) && (
        <div>
          <Subtitle2 className={css.updateAttachmentText}>
            <div>
              <Link
                external
                pathname={`/publication/moderatorDownload/${publication.publicationId}`}
                target="_blank"
              >
                <Icon
                  icon={['fas', 'file-download']}
                  className={css.fileDownloadIcon}
                />
              </Link>
            </div>
            <div>
              This paper has an attachment with
              {publication.access === publicationAccess.OPENED ||
              publication.openAccess
                ? ' open access (will be available to download when the sponsorship ends)'
                : ' closed access (will not be available to download when the sponsorship ends)'}
              . Please decide what to do with it:
            </div>
          </Subtitle2>

          {actions.map((action) => {
            if (!action) {
              return null
            }
            return (
              <div
                className={classNames(css.attachmentAction, {
                  [css.disabledAttachmentAction]: action.disabled,
                })}
                key={`attachmentAction_${action.value}`}
              >
                <div>
                  <Field
                    type="radio"
                    name="attachmentAction"
                    id={`attachmentAction_${action.value}`}
                    value={action.value}
                    onClick={() =>
                      setFieldValue('attachmentAction', action.value)
                    }
                    disabled={action.disabled}
                    checked={values.attachmentAction === action.value}
                  />
                </div>

                <Subtitle2>
                  <label htmlFor={`attachmentAction_${action.value}`}>
                    {action.description} {action.disabled}
                  </label>
                </Subtitle2>
              </div>
            )
          })}
        </div>
      )}

      {(values.attachmentAction === 'add' ||
        values.attachmentAction === 'replace') && (
        <div className={css.uploadButtonContainer}>
          <ButtonUpload
            inputProps={{
              name: 'file',
              onChange: handleFileUpload,
            }}
            buttonProps={{
              type: 'secondary',
              className: css.uploadButton,
            }}
          />
          {values.attachment && (
            <div className={css.openAccessInput}>
              <label>
                <Field
                  type="checkbox"
                  name="openAccess"
                  checked={values.openAccess}
                />
                Keep this file available to download when the sponsorship ends
              </label>
            </div>
          )}
        </div>
      )}
    </>
  )
}
