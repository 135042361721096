import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import { FOLLOWING_TYPE } from 'mednet-util/src/constants/following'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
  requestAction,
} from '../cns-util/reducer'

export const FETCH_HOME_TOPICS = 'topic/FETCH_HOME_TOPICS'
export const FETCH_TOPICS_TREE = 'topic/FETCH_TOPICS_TREE'
export const FETCH_TOPIC = 'topic/FETCH_TOPIC'
export const FETCH_TOPICS = 'topic/FETCH_TOPICS'
export const TOGGLE_FOLLOW_TOPIC = 'topic/TOGGLE_FOLLOW_TOPIC'

export function fetchHomeTopics(params) {
  return {
    type: FETCH_HOME_TOPICS,
    params,
  }
}

export function fetchTopicsTree(callback) {
  return {
    type: FETCH_TOPICS_TREE,
    callback,
  }
}

export function fetchTopic(topicId) {
  return {
    type: FETCH_TOPIC,
    requestId: topicId,
    topicId,
  }
}

export function fetchTopics(specialtyId) {
  return {
    type: FETCH_TOPICS,
    requestId: specialtyId,
    specialtyId,
  }
}

export function toggleFollowTopic(topicId) {
  return {
    type: TOGGLE_FOLLOW_TOPIC,
    requestId: topicId,
    topicId,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, FETCH_HOME_TOPICS, (action) =>
    makeURL('topic/getHomeTopics', action.params)
  )

  yield makeFetchEffect(takeLatest, FETCH_TOPICS_TREE, (action) =>
    makeURL('topic/getAvailableTopicsTreeJSON', action.params)
  )

  yield makeFetchEffect(takeLatest, FETCH_TOPIC, (action) =>
    makeURL(`topic/getTopic/${action.topicId}`, {
      specialtyId: action.specialtyId,
    })
  )

  yield makeFetchEffect(takeLatest, FETCH_TOPICS, (action) =>
    makeURL(`topic/getTopics/`, {
      specialtyId: action.specialtyId,
    })
  )

  yield makeFetchEffect(takeLatest, TOGGLE_FOLLOW_TOPIC, (action) =>
    makeURL('following/toggleJSON', {
      type: FOLLOWING_TYPE.TOPIC,
      followingId: action.topicId,
    })
  )
}

const keyTopicChildren = (topics, topicObject) => {
  if (!topicObject.children) {
    return
  }

  _.each(topicObject.children, (childObject) => {
    topics[childObject.topicId] = childObject
    keyTopicChildren(topics, childObject)
  })
}

const keyHomeTopics = (homeTopics) => {
  const topics = {}
  _.each(homeTopics, (topicList) => {
    _.each(topicList, (topicObject) => {
      topics[topicObject.topicId] = topicObject
      keyTopicChildren(topics, topicObject)
    })
  })

  return topics
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  topics: {},
  specialties: {},
  tree: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case requestAction(TOGGLE_FOLLOW_TOPIC): {
      const topicObject = state.topics[action.topicId]

      return {
        ...state,
        topics: {
          ...state.topics,
          [action.topicId]: {
            ...topicObject,
            isFollowed: !topicObject.isFollowed,
          },
        },
      }
    }

    case receiveAction(FETCH_HOME_TOPICS): {
      return receiveReducer(state, action, () => ({
        homeTopics: {
          ...action.response,
        },
        topics: {
          ...state.topics,
          ...keyHomeTopics(action.response),
        },
      }))
    }

    case receiveAction(FETCH_TOPICS_TREE): {
      return receiveReducer(state, action, () => ({
        tree: action.response || [],
      }))
    }

    case receiveAction(FETCH_TOPIC): {
      return receiveReducer(state, action, () => ({
        topics: {
          ...state.topics,
          [action.topicId]: {
            ...state.topics[action.topicId],
            ...action.response.topic,
          },
        },
      }))
    }

    case receiveAction(FETCH_TOPICS): {
      return receiveReducer(state, action, () => ({
        topics: {
          ...state.topics,
          ...action.response.topics,
        },
        specialties: {
          ...state.specialties,
          [action.specialtyId]: _.keys(action.response.topics),
        },
      }))
    }

    default:
      return state
  }
}

export function getSpecialtyTopics(state, specialtyId) {
  return _.map(
    state.topic.specialties[specialtyId] || [],
    (topicId) => state.topic.topics[topicId]
  )
}
