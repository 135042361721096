import React, { useCallback, useState, useEffect } from 'react'
import { createSelector } from 'reselect'
import classNames from 'classnames'

import { Logo } from 'pharmacy/src/style/logo'
import { Header } from 'pharmacy/src/navigation/header'
import { Link } from 'pharmacy/src/navigation/link'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { Icon } from 'pharmacy/src/display/icon'

import { getRequest } from 'mednet-cns/src/api/v1'
import {
  FETCH_USER_WITH_PERMISSIONS,
  FETCH_USER_WITH_PERMISSIONS_PUBLIC,
} from 'mednet-cns/src/reducers/user'

import { useSelector } from 'react-redux'

import { publicRoutes } from 'constants/publicRoutes'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import HeaderDropdown from './headerDropdown'
import HeaderSearch from './headerSearch'
import { GatedAskQuestionButton } from './headerAskQuestionButton'
import { HeaderRegisterButton } from './headerRegisterButton'
import HeaderLink from './headerLink'

import css from './anteriorHeader.scss'

const AnteriorHeader = (props) => {
  const selectUser = useCallback(
    createSelector(
      (state) => (state.user.data ? state.user.data.userId : undefined),
      (state) => getRequest(state, FETCH_USER_WITH_PERMISSIONS_PUBLIC),
      (state) => getRequest(state, FETCH_USER_WITH_PERMISSIONS),
      (userId, publicUserRequest, userRequest) => {
        return {
          userId,
          isLoading: userRequest.isLoading || publicUserRequest.isLoading,
          isError: userRequest.isError || publicUserRequest.isError,
        }
      }
    )
  )

  const { userId, isLoading, isError } = useSelector(selectUser)
  const isGuest = isLoading || isError || !userId
  const [isGuestMenuVisible, setGuestMenuVisible] = useState(false)
  const [isLoginPageActive, setLoginPageActive] = useState(
    props.history.location.pathname === ROUTES[PAGES.LOGIN].path
  )

  useEffect(() => {
    if (props.history) {
      const unlistenToLocation = props.history.listen(() => {
        setGuestMenuVisible(false)
        setLoginPageActive(
          props.history.location.pathname === ROUTES[PAGES.LOGIN].path
        )
        return unlistenToLocation
      })
    }

    return () => {}
  }, [])

  return (
    <Header
      className={css.header}
      history={props.history}
      publicRoutes={publicRoutes}
    >
      <div className={css.logoContainer}>
        <Link
          external={isLoading} // This is needed to redirect when request is loading but user is logged in
          pathname={isGuest ? '/' : '/home/index'}
          className={css.logoLink}
        >
          <Logo className={css.logo} />
        </Link>
      </div>
      {!isLoading && (
        <>
          {!isGuest && (
            <div className={css.search}>
              <ErrorBoundary>
                <HeaderSearch />
              </ErrorBoundary>
            </div>
          )}

          <HeaderDropdown
            className={classNames(
              isGuest ? css.guestHeaderDropdown : css.headerDropdown,
              { [css.hiddenMenuInSmallScreen]: isGuest && !isGuestMenuVisible }
            )}
            isGuest={isGuest}
            history={props.history}
          />

          <div className={css.buttonsContainer}>
            {isGuest ? (
              <>
                <Link pathname={ROUTES[PAGES.LOGIN].path}>
                  <HeaderLink
                    className={classNames(css.signInLink, {
                      [css.activeSignInLink]: isLoginPageActive,
                    })}
                  >
                    Sign In
                  </HeaderLink>
                </Link>

                <HeaderRegisterButton />
                <Icon
                  className={css.menuBars}
                  icon={isGuestMenuVisible ? ['far', 'times'] : ['far', 'bars']}
                  onClick={() => setGuestMenuVisible(!isGuestMenuVisible)}
                />
              </>
            ) : (
              <GatedAskQuestionButton />
            )}
          </div>
        </>
      )}
    </Header>
  )
}

export default AnteriorHeader
