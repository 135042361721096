import React, { useEffect } from 'react'
import initReactFastclick from 'react-fastclick'

import { makeAssetURL } from 'mednet-util/src/router'
import { mixpanel } from 'mednet-util/src/tracking'

import { LandingPageContent } from 'components/landingPageContent'

import { SpecialtiesExpertsList } from './specialtiesExpertsList'

import { Hero } from './hero'
import { PhysiciansSection } from './physicians'
import { Partners } from './partners'
import { Testimonials } from './testimonials'
import { Publications } from './publications'

import css from './publicHome.scss'

const assetsPath = 'images/home/'

// https://stackoverflow.com/questions/17710893/why-when-do-i-have-to-tap-twice-to-trigger-click-on-ios
// https://www.npmjs.com/package/react-fastclick
// This is needed for mobile IOS. I think this may be needed on the app level, I am afraid to cause some not expected behaviour for some component
initReactFastclick()

const AboutUsSection = () => (
  <div className={css.aboutUsSection}>
    <div className={css.sectionTitle}>ABOUT US</div>
    <div className={css.aboutUsText}>
      Studies show that physicians have questions with almost every patient
      encounter. These questions often go unanswered because patients don’t
      always neatly fit into guidelines, textbooks, or UpToDate. Expert
      physicians are making strides each day to find answers that go beyond the
      textbook. Their first-hand experiences provide insights that are helpful
      to other physicians in the field. At theMednet, we 1) document that
      undocumented expert knowledge, 2) make it accessible and searchable for
      physicians, and 3) enable informed decision making to help physicians
      deliver the best care to their patients.
    </div>
  </div>
)

const FundingSection = () => {
  return (
    <div className={css.fundingSection}>
      <div className={css.fundingSectionContent}>
        <div className={css.fundingSectionTitle}>Grant funding from</div>
        <div className={css.logo}>
          <img
            src={makeAssetURL(`${assetsPath}lung-cancer-foundation-logo.png`)}
          />
        </div>
        <div className={css.wideLogo}>
          <img
            src={makeAssetURL(
              `${assetsPath}national-science-foundation-logo.png`
            )}
          />
        </div>
        <div className={css.logo}>
          <img
            src={makeAssetURL(`${assetsPath}the-hope-foundation-logo.png`)}
          />
        </div>
      </div>
    </div>
  )
}

export const PublicHome = () => {
  useEffect(() => {
    mixpanel.track('viewed_homepage')
  }, [])
  return (
    <LandingPageContent noBackgroundImage>
      <Hero assetsPath={assetsPath} />
      <AboutUsSection />
      <SpecialtiesExpertsList
        textClassName={css.sectionText}
        titleClassName={css.sectionTitle}
        buttonClassName={css.secondaryButton}
      />
      <PhysiciansSection
        assetsPath={assetsPath}
        buttonClassName={css.secondaryButton}
        titleClassName={css.secondarySectionTitle}
      />
      <Partners
        assetsPath={assetsPath}
        titleClassName={css.sectionTitle}
        textClassName={css.sectionText}
        buttonClassName={css.secondaryButton}
      />
      <Testimonials
        assetsPath={assetsPath}
        titleClassName={css.secondarySectionTitle}
      />
      <Publications
        assetsPath={assetsPath}
        titleClassName={css.sectionTitle}
        buttonClassName={css.secondaryButton}
      />
      <FundingSection />
    </LandingPageContent>
  )
}
