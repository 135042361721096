import React from 'react'
import { connect } from 'react-redux'

import { Button } from 'pharmacy/src/input/button'
import { Link1 } from 'pharmacy/src/typography'

import { toggleFollowUser } from 'mednet-cns/src/reducers/user'

import css from './followUserButton.scss'

class followUserButton extends React.Component {
  static defaultProps = {
    buttonProps: {
      type: 'text',
    },
  }

  render() {
    const { isFollowed, toggleFollowUser, buttonProps, className } = this.props

    const icon = isFollowed ? 'user-minus' : ['fal', 'user-plus']
    const text = isFollowed ? 'Unfollow' : 'Follow'

    return (
      <Link1 className={[className, css.link].join(' ')}>
        <Button
          icon={icon}
          onClick={() => {
            toggleFollowUser(!isFollowed)
          }}
          {...buttonProps}
        >
          {text}
        </Button>
      </Link1>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFollowed: state.user.users[ownProps.userId].isFollowed,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleFollowUser: (prevFollowedState) =>
      dispatch(
        toggleFollowUser(
          ownProps.userId,
          ownProps.callLocation,
          prevFollowedState,
          ownProps.questionId
        )
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(followUserButton)
