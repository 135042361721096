import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'
import { Header1 } from 'pharmacy/src/typography'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { FETCH_USER_WITH_PERMISSIONS } from 'mednet-cns/src/reducers/user'
import {
  fetchHomeTopics,
  FETCH_HOME_TOPICS,
} from 'mednet-cns/src/reducers/topic'
import { getRequest } from 'mednet-cns/src/api/v1'

import TopicSection from './topicSection'

import css from './topicList.scss'

class TopicList extends React.Component {
  componentDidMount() {
    this.props.fetchHomeTopics()
  }

  render() {
    const {
      userIsLoaded,
      homeTopicsIsLoaded,
      userData,
      homeTopics,
      homeTopicsIsError,
    } = this.props
    const { specialtyId } = userData
    const { featured, diseaseSites, followed, other } = homeTopics

    if (homeTopicsIsError) {
      return null
    }

    return (
      <div>
        <div className={css.header}>
          <Header1>Topics</Header1>
          {userIsLoaded && (
            <Link
              external
              pathname="/topic/index"
              params={{ sid: `t${specialtyId}` }}
              className={css.slidersLink}
            >
              <Icon icon="sliders-h" size="lg" />
            </Link>
          )}
        </div>
        <div>
          <TopicSection
            topics={featured}
            header="Featured"
            isLoaded={homeTopicsIsLoaded}
          />
          <TopicSection
            topics={followed}
            header="Followed"
            isLoaded={homeTopicsIsLoaded}
          />
          <TopicSection
            topics={diseaseSites}
            header="Disease Sites"
            isLoaded={homeTopicsIsLoaded}
            numExpected={10}
          />
          <TopicSection
            topics={other}
            header="Other"
            isLoaded={homeTopicsIsLoaded}
            defaultOpen={false}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const userRequest = getRequest(
    state,
    makeRequestName(FETCH_USER_WITH_PERMISSIONS)
  )
  const homeTopicsRequest =
    _.get(state.request.requests, makeRequestName(FETCH_HOME_TOPICS)) || {}

  return {
    userData: state.user.data || {},
    homeTopics: state.topic.homeTopics || {},
    userIsLoaded: userRequest.isLoaded,
    homeTopicsIsLoaded: homeTopicsRequest.isLoaded,
    homeTopicsIsError: homeTopicsRequest.isError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHomeTopics: () => dispatch(fetchHomeTopics()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicList)
