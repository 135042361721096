import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { useSelectedUsers, useUserTreeQuery } from 'mednet-cns/src/hooks/user'
import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import { RECIPIENTS_MODAL } from 'mednet-util/src/constants/modal'

import { Modal } from 'pharmacy/src/display/modal'
import { Button } from 'pharmacy/src/input/button'
import { Header4, Label1 } from 'pharmacy/src/typography'
import TextInput from 'pharmacy/src/input/textInput/textInput'
import CenteredContent from 'pharmacy/src/display/content/centeredContent'

import UserSearchPage from 'pages/userSearch/userSearch'

import * as css from './recipientsModal.scss'
import * as campaignCSS from './campaign.scss'

const MainScreen = ({
  onAdd,
  onSave,
  recipients,
  id,
  queryUserStatus,
  isDisabled,
}) => {
  const isEdit = Boolean(recipients)
  const [tree, setTreeQuery] = useUserTreeQuery()
  const [name, setName] = useState(recipients?.name)
  const [selected, setSelected] = useSelectedUsers()

  useEffect(() => {
    if (recipients) {
      setSelected(recipients?.selected)
      setTreeQuery(recipients?.tree)
    }
  }, [recipients])

  useEffect(() => {
    if (tree?.name && !name) {
      setName(tree.name)
    }
  }, [tree?.name])

  // eslint-disable-next-line no-unused-vars
  const [_openRecipientsModal, closeRecipientsModal] = useModalDrivers(
    RECIPIENTS_MODAL.modalId
  )

  const handleAdd = useCallback(() => {
    onAdd && onAdd({ name, tree, selected })
    closeRecipientsModal()
  }, [onAdd, name, tree, selected])

  const handleSave = useCallback(() => {
    onSave && onSave({ id, name, tree, selected })
    closeRecipientsModal()
  }, [onSave, name, tree, selected])

  const recipientsAdjective =
    queryUserStatus === 'unregistered' ? 'unregistered' : 'active'

  return (
    <CenteredContent size="xlarge">
      <div className={css.contents}>
        <Header4>
          {isEdit
            ? `Edit ${recipientsAdjective} recipients`
            : `Add ${recipientsAdjective} recipients`}
        </Header4>
        <div className={css.headerBar}>
          <div className={css.name}>
            <Label1>
              Recipients group name:
              <TextInput
                className={classNames(campaignCSS.input, css.recipientsName)}
                onChange={setName}
                value={name}
                disabled={isDisabled}
              ></TextInput>
            </Label1>
          </div>
          <div className={css.actions}>
            <Button
              isDisabled={!Object.keys(selected).length || isDisabled}
              icon={isEdit ? 'check' : 'plus'}
              onClick={isEdit ? handleSave : handleAdd}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </div>
        </div>
        <UserSearchPage
          selectable
          queryUserStatus={queryUserStatus}
          activeTab={isEdit ? 1 : 0}
        />
      </div>
    </CenteredContent>
  )
}

const screens = {
  [RECIPIENTS_MODAL.screens.main]: MainScreen,
}

export const RecipientsModal = React.memo((props) => {
  return (
    <Modal
      closeOnBackdrop
      modalId={RECIPIENTS_MODAL.modalId}
      screens={screens}
      defaultScreen={RECIPIENTS_MODAL.screens.main}
      size="fullscreen"
      {...props}
    ></Modal>
  )
})
RecipientsModal.displayName = 'RecipientsModal'
