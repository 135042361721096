import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import moment from 'moment-timezone'

import { openModal } from 'mednet-cns/src/reducers/modal'
import {
  fetchAvailableSpecialties,
  FETCH_AVAILABLE_SPECIALTIES,
  createDigest,
  CREATE_DIGEST,
  deleteDigest,
  deleteDigestUpdate,
  ADD_DIGEST_UPDATE,
  fetchTodayDigest,
  FETCH_TODAY_DIGEST,
  DELETE_DIGEST,
  suspendDigest,
  resumeDigest,
  RESUME_DIGEST,
  SUSPEND_DIGEST,
  addDigestUpdate,
  setDigestOrder,
  setPrimaryTopic,
  setDigestSubject,
  SET_DIGEST_ORDER,
  DELETE_DIGEST_UPDATE,
} from 'mednet-cns/src/reducers/digest'
import { FETCH_USER_WITH_PERMISSIONS } from 'mednet-cns/src/reducers/user'
import { fetchNotificationUsage } from 'mednet-cns/src/reducers/question'

import {
  DELETE_DIGEST_MODAL,
  DELETE_DIGEST_UPDATE_MODAL,
  DELETE_DIGEST_QUESTION_MODAL,
  EMAIL_PREVIEW_MODAL,
} from 'mednet-util/src/constants/modal'
import { Page } from 'pharmacy/src/display/page'
import { getRequest } from 'mednet-cns/src/api/v1'
import { makeAssetURL } from 'mednet-util/src/router'
import { digestStatus } from 'mednet-util/src/constants/digest'
import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'

import {
  Header1,
  Header2,
  Header3,
  Header4,
  Subtitle1,
  Body1,
} from 'pharmacy/src/typography'
import { SimpleSelect } from 'pharmacy/src/input/select'
import { CoverLoader, StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Button } from 'pharmacy/src/input/button'
import { ConfirmationModal } from 'pharmacy/src/display/modal'
import SidePanel from 'pharmacy/src/display/sidePanel/sidePanel'
import { SimpleDropdownIndicator } from 'pharmacy/src/input/select/components'
import { Icon } from 'pharmacy/src/display/icon'

import { fetchUpdateInNotificationUsage } from 'mednet-cns/src/reducers/questionUpdate'

import classNames from 'classnames'

import { SearchQuestionUpdatesPage } from '../searchQuestionUpdates'
import QuestionUpdateCard from '../../components/questionUpdateCard/questionUpdateCard'
import QuestionShortTitleModal from '../../components/questionShortTitleModal/questionShortTitleModal'
import EmailPreviewModal from '../../components/emailPreviewModal/emailPreviewModal'
import EmailAutoSubject from '../../components/emailAutoSubject/emailAutoSubject'

import css from './manageDigest.scss'

class ManageDigestPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isPanelOpened: false,
      selectedSpecialtyId: props.preselectedSpecialtyId,
    }
  }

  componentDidMount() {
    this.props.fetchAvailableSpecialties()
  }

  componentDidUpdate(prevProps) {
    const {
      availableSpecialtiesIsLoaded,
      userData,
      userDataIsLoaded,
      fetchNotificationUsage,
      fetchUpdateInNotificationUsage,
      digestId,
      preselectedSpecialtyId,
      allUpdateNotifications,
    } = this.props
    const specialtyId = this.state.selectedSpecialtyId
    const questions = this.getQuestions()
    const updates = this.getUpdates()

    if (
      (!prevProps.userDataIsLoaded &&
        userDataIsLoaded &&
        availableSpecialtiesIsLoaded) ||
      (!prevProps.availableSpecialtiesIsLoaded &&
        availableSpecialtiesIsLoaded &&
        userDataIsLoaded)
    ) {
      const selectedSpecialtyId = preselectedSpecialtyId || userData.specialtyId
      this.props.fetchTodayDigest(selectedSpecialtyId)
      this.setState({
        selectedSpecialtyId,
      })
    }

    const questionIdsToFetch = questions
      .filter(
        (question) =>
          !question.notifications || !question.notifications[specialtyId]
      )
      .map((question) => question.questionId)

    const allUpdateNotificationsIds = _.keys(
      allUpdateNotifications[specialtyId] || {}
    )

    const updateIdsToFetch = updates
      .filter(
        (update) =>
          !allUpdateNotificationsIds.includes(
            update.questionUpdateId.toString()
          )
      )
      .map((update) => update.questionUpdateId)

    questions.forEach((question) => {
      const update = question.updates.find(
        (update) => update.actionId === actionIdByName.POLL_APPROVED
      )
      if (
        update &&
        !allUpdateNotificationsIds.includes(
          update.questionUpdateId.toString()
        ) &&
        !updateIdsToFetch.includes(update.questionUpdateId.toString())
      ) {
        updateIdsToFetch.push(update.questionUpdateId.toString())
      }
    })

    if (specialtyId) {
      if (questionIdsToFetch.length) {
        fetchNotificationUsage(specialtyId, questionIdsToFetch, {
          digestId,
        })
      }
      if (updateIdsToFetch.length) {
        fetchUpdateInNotificationUsage(specialtyId, updateIdsToFetch, {
          digestId,
        })
      }
    }
  }

  handlePanelToggle = (isPanelOpened) =>
    this.setState({
      isPanelOpened,
    })

  handleChangeSpecialty = (option) => {
    const selectedSpecialtyId = option.value
    const selectedSpecialtyTitle = option.label
    this.props.fetchTodayDigest(selectedSpecialtyId)
    this.setState({
      selectedSpecialtyTitle,
      selectedSpecialtyId,
    })
  }

  handleCreate = () => {
    const { selectedSpecialtyId } = this.state
    const { createDigest } = this.props

    createDigest(selectedSpecialtyId)
  }

  handleDelete = ({ closeModal }) => {
    const { digestId, deleteDigest } = this.props
    const { selectedSpecialtyId } = this.state

    deleteDigest(digestId, () =>
      this.props.fetchTodayDigest(selectedSpecialtyId)
    )
    closeModal()
  }

  handleDeleteUpdate = ({ closeModal, updateId, questionId }) => {
    const { digestId, deleteDigestUpdate } = this.props
    const { selectedSpecialtyId } = this.state

    const toDelete = _.isArray(updateId) ? updateId : [updateId]

    deleteDigestUpdate(
      selectedSpecialtyId,
      digestId,
      toDelete,
      questionId,
      this.afterUpdateRemoved
    )
    closeModal()
  }

  handleDeleteQuestion = ({ closeModal, questionId }) => {
    const { digestId, deleteDigestUpdate } = this.props
    const { selectedSpecialtyId } = this.state
    const updates = this.getUpdates()
    const updateIdsToRemove = updates
      .filter((update) => update.questionId === questionId)
      .map((update) => update.questionUpdateId)

    deleteDigestUpdate(
      selectedSpecialtyId,
      digestId,
      updateIdsToRemove,
      questionId,
      this.afterUpdateRemoved
    )
    closeModal()
  }

  setNewQuestionPosition = (questionId, destinationIndex) => {
    const { digestId, digestQuestions, setDigestOrder } = this.props
    const question = _.find(
      digestQuestions,
      (question) => question.questionId === questionId
    )
    const newOrder = digestQuestions.filter(
      (question) => question.questionId !== questionId
    )
    newOrder.splice(destinationIndex, 0, question)

    setDigestOrder(digestId, newOrder)
  }

  handleMoveQuestionDown = (questionId) => {
    const { digestQuestions } = this.props
    const index = _.findIndex(
      digestQuestions,
      (question) => question.questionId === questionId
    )

    if (index < digestQuestions.length - 1) {
      this.setNewQuestionPosition(questionId, index + 1)
    }
  }

  handleMoveQuestionUp = (questionId) => {
    const { digestQuestions } = this.props
    const index = _.findIndex(
      digestQuestions,
      (question) => question.questionId === questionId
    )

    if (index > 0) {
      this.setNewQuestionPosition(questionId, index - 1)
    }
  }

  handleDragEnd = (result) => {
    const questionId = Number(result.draggableId)
    const destinationIndex = _.get(result, 'destination.index', null)
    const sourceIndex = _.get(result, 'source.index', null)

    if (
      destinationIndex === sourceIndex ||
      destinationIndex === null ||
      sourceIndex === null
    )
      return

    this.setNewQuestionPosition(questionId, destinationIndex)
  }

  setSubject = _.debounce((subject) => {
    const { setDigestSubject, digestId } = this.props
    setDigestSubject(digestId, subject)
  }, 1000)

  toggleDigestStatus = () => {
    const { digestId, suspendDigest, resumeDigest } = this.props
    const digest = this.getDigest()

    if (digest.status === digestStatus.ACTIVE) {
      suspendDigest(digestId)
    } else {
      resumeDigest(digestId)
    }
  }

  afterUpdateAdded = () => {
    const { digestQuestions, digestId, resumeDigest } = this.props
    const digest = this.getDigest()

    if (
      digestQuestions &&
      digestQuestions.length &&
      digest.status === digestStatus.SUSPENDED
    ) {
      resumeDigest(digestId)
    }
  }

  afterUpdateRemoved = () => {
    const { digestQuestions, digestId, suspendDigest } = this.props
    const digest = this.getDigest()

    if (
      digestQuestions &&
      !digestQuestions.length &&
      digest.status === digestStatus.ACTIVE
    ) {
      suspendDigest(digestId)
    }
  }

  getDigest = () => {
    const { selectedSpecialtyId } = this.state
    const { digests } = this.props
    return digests[selectedSpecialtyId]
  }

  getUpdates = (questionId) => {
    const { allUpdates } = this.props
    let digestQuestions = this.props.digestQuestions

    if (!digestQuestions) {
      digestQuestions = []
    } else if (questionId) {
      digestQuestions = digestQuestions.filter(
        (question) => question.questionId === questionId
      )
    }

    return _.flatMap(digestQuestions, (question) => question.updates).map(
      (updateId) => allUpdates[updateId]
    )
  }

  getQuestions = () => {
    const { allQuestions, digestQuestions } = this.props

    if (!digestQuestions) return []

    return digestQuestions.map(({ questionId }) => allQuestions[questionId])
  }

  getSpecialtyTitle = () => {
    const { selectedSpecialtyId, selectedSpecialtyTitle } = this.state
    const { availableSpecialties } = this.props

    return selectedSpecialtyTitle
      ? selectedSpecialtyTitle
      : availableSpecialties[selectedSpecialtyId].specialty
  }

  renderStats = () => {
    const updates = this.getUpdates()
    const digest = this.getDigest()

    const noAnswerUpdates = updates.filter(
      (update) => update.actionId === actionIdByName.ANSWER
    ).length
    const noPollUpdates = updates.filter(
      (update) => update.actionId === actionIdByName.POLL_APPROVED
    ).length

    return (
      <div className={css.stats}>
        <Body1>
          <span className={css.line}>
            {`${noAnswerUpdates} answer${noAnswerUpdates > 1 ? 's' : ''}`}
          </span>{' '}
          <span className={css.line}>
            {`${noPollUpdates} poll${noPollUpdates > 1 ? 's' : ''}`}
          </span>
        </Body1>
        {digest.status !== digestStatus.ACTIVE || !digest.summary ? null : (
          <Body1>
            <span className={css.line}>
              {`${digest.summary.notifications.notificationsNo} notifications to`}
            </span>{' '}
            <span className={css.line}>
              {`send after ${digest.summary.notifications.firstSend} ET`}
            </span>
          </Body1>
        )}
      </div>
    )
  }

  renderUpdates = () => {
    const {
      addDigestUpdate,
      fetchTodayDigestIsLoading,
      addDigestModalIsLoading,
      createIsLoading,
      openDeleteUpdateModal,
      openDeleteQuestionModal,
      openSubjectsPreviewModal,
      deleteDigestIsLoading,
      setPrimaryTopic,
      primaryTopics,
      digestId,
      subject,
    } = this.props
    const { selectedSpecialtyId } = this.state

    const digest = this.getDigest()
    const updates = this.getUpdates()
    const questions = this.getQuestions()

    if (
      fetchTodayDigestIsLoading ||
      addDigestModalIsLoading ||
      deleteDigestIsLoading ||
      createIsLoading
    ) {
      return <StarLoader />
    }

    const currentTime = moment().tz('UTC')

    const currentHour = currentTime.hour()
    const currentMinute = currentTime.minutes()

    const generateDisabled =
      currentHour === 21 && currentMinute >= 0 && currentMinute < 15

    if (!digest) {
      return (
        <div className={css.createDigestWrapper}>
          <div className={css.importantWarningsContainer}>
            <Header4>
              Digest for the next day is generated and sent automatically
            </Header4>
          </div>
          <Button
            className={css.createDigestButton}
            type="neutral"
            icon={['far', 'plus']}
            onClick={this.handleCreate}
            isDisabled={generateDisabled}
          >
            {generateDisabled
              ? 'Autogenerating (9:00pm - 9:15pm [UTC])'
              : 'Create Digest Manually'}
          </Button>
        </div>
      )
    }

    if (!updates || updates.length === 0) {
      return (
        <div className={css.importantWarningsContainer}>
          <Header4>No updates added to digest</Header4>
          <Subtitle1>
            Digests without updates are automatically suspended and they
            won&apos;t be sent
          </Subtitle1>
        </div>
      )
    }

    const title = this.getSpecialtyTitle()

    return (
      <>
        {digest.status === digestStatus.ACTIVE ? null : (
          <div className={css.importantWarningsContainer}>
            <Header3>
              WARNING! This digest is suspended for the next day!
            </Header3>
          </div>
        )}
        <div className={css.subjectContainer}>
          <Header1 className={css.subjectInfoIconWrapper}>
            <Icon
              className={css.subjectInfoIcon}
              onClick={openSubjectsPreviewModal(digestId, selectedSpecialtyId)}
              icon="info-circle"
            />
          </Header1>
          <EmailAutoSubject
            onSubjectChange={this.setSubject}
            subject={subject}
          />
        </div>
        <hr />
        {this.renderStats()}
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="questions">
            {(provided) => (
              <ul
                className={css.draggable}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {questions.map((question, index) => {
                  const questionUpdates = this.getUpdates(question.questionId)
                  const overridePrimaryTopic =
                    primaryTopics[questionUpdates[0].questionUpdateId]
                  let primaryTopic

                  if (overridePrimaryTopic) {
                    primaryTopic = overridePrimaryTopic
                  } else {
                    primaryTopic = question.primaryTopic
                      ? question.primaryTopic.topicId
                      : undefined
                  }

                  return (
                    <Draggable
                      key={question.questionId}
                      draggableId={question.questionId.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={css.draggableElement}
                        >
                          <QuestionUpdateCard
                            question={question}
                            updates={questionUpdates}
                            dragHandleProps={provided.dragHandleProps}
                            onMoveDown={this.handleMoveQuestionDown}
                            onMoveUp={this.handleMoveQuestionUp}
                            moveButtonsHidden={snapshot.isDragging}
                            onRemoveQuestion={openDeleteQuestionModal}
                            onRemoveUpdate={openDeleteUpdateModal}
                            onAddUpdate={addDigestUpdate(
                              selectedSpecialtyId,
                              digestId,
                              this.afterUpdateAdded
                            )}
                            primaryTopic={primaryTopic}
                            specialtyId={selectedSpecialtyId}
                            specialtyTitle={title}
                            onTopicClick={setPrimaryTopic(
                              digestId,
                              questionUpdates.map(
                                (update) => update.questionUpdateId
                              )
                            )}
                            className={css.questionUpdateCard}
                          ></QuestionUpdateCard>
                        </li>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  }

  renderButtons = () => {
    const {
      addDigestModalIsLoading,
      openDeleteModal,
      fetchTodayDigestIsLoading,
      deleteDigestIsLoading,
      digests,
      toggleDigestIsLoading,
      availableSpecialtiesIsLoaded,
      userDataIsLoaded,
      addDigestUpdate,
      addDigestUpdateIsLoading,
      digestId,
      openPreviewModal,
    } = this.props
    const { selectedSpecialtyId } = this.state

    if (
      fetchTodayDigestIsLoading ||
      addDigestModalIsLoading ||
      deleteDigestIsLoading ||
      !digests[selectedSpecialtyId] ||
      !availableSpecialtiesIsLoaded ||
      !userDataIsLoaded
    ) {
      return null
    }

    const digest = this.getDigest()
    const title = this.getSpecialtyTitle()

    const updates = this.getUpdates()

    return (
      <div className={css.actionsContainer}>
        <SidePanel
          buttonText="Add more"
          header={`Updates for ${title}`}
          onToggle={this.handlePanelToggle}
        >
          <SearchQuestionUpdatesPage
            specialtyId={selectedSpecialtyId}
            specialtyTitle={title}
            digestId={digest.digest_id}
            onAddUpdate={addDigestUpdate(
              digest.specialty_id,
              digest.digest_id,
              this.afterUpdateAdded
            )}
            updateIsLoading={addDigestUpdateIsLoading}
            usedUpdates={updates}
          />
        </SidePanel>
        <Button
          size="small"
          type="destructive"
          icon={['far', 'trash-alt']}
          iconProps={{
            className: css.buttonIcon,
          }}
          onClick={openDeleteModal}
        >
          Delete
        </Button>
        {!updates.length ? null : (
          <>
            <Button
              size="small"
              type="neutral"
              isLoading={toggleDigestIsLoading}
              icon={
                digest.status === digestStatus.ACTIVE
                  ? ['far', 'hourglass']
                  : ['far', 'play']
              }
              iconProps={{
                className: css.buttonIcon,
              }}
              onClick={this.toggleDigestStatus}
            >
              {digest.status === digestStatus.ACTIVE ? 'Suspend' : 'Resume'}
            </Button>
            <Button
              size="small"
              type="neutral"
              icon={['far', 'eye']}
              iconProps={{
                className: css.buttonIcon,
              }}
              onClick={openPreviewModal(digestId, selectedSpecialtyId)}
            >
              Preview
            </Button>
          </>
        )}
      </div>
    )
  }

  renderInfo() {
    return (
      <div className={css.info}>
        <img
          src={makeAssetURL('images/site/moderator/daily-digest-timeline.png')}
        />
      </div>
    )
  }

  render() {
    const {
      availableSpecialties,
      availableSpecialtiesIsLoaded,
      userData,
      userDataIsLoaded,
      preselectedSpecialtyId,
      addDigestUpdateIsLoading,
      deleteDigestUpdateIsLoading,
      setDigestOderIsLoading,
    } = this.props

    const { isPanelOpened } = this.state

    if (!availableSpecialtiesIsLoaded || !userDataIsLoaded) {
      return <StarLoader />
    }

    const defaultSpecialty =
      availableSpecialties[preselectedSpecialtyId || userData.specialtyId]

    return (
      <>
        {!addDigestUpdateIsLoading &&
        !setDigestOderIsLoading &&
        !deleteDigestUpdateIsLoading ? null : (
          <CoverLoader />
        )}
        <Page className={css.page}>
          {!isPanelOpened ? null : (
            <div className={css.contentLeftFiller}></div>
          )}
          <div
            className={classNames({
              [css.content]: true,
              [css.contentPanelOpened]: isPanelOpened,
            })}
          >
            <div className={css.headerContainer}>
              <div className={css.mainHeader}>
                <div className={css.selectContainer}>
                  <SimpleSelect
                    options={_.map(
                      _.sortBy(availableSpecialties, 'specialty'),
                      (specialty) => ({
                        label: specialty.specialty,
                        value: specialty.specialtyId,
                      })
                    )}
                    defaultValue={{
                      label: defaultSpecialty.specialty,
                      value: defaultSpecialty.specialtyId,
                    }}
                    onChange={this.handleChangeSpecialty}
                    components={{
                      SingleValue: ({ children }) => (
                        <Header2 className={css.containerText}>
                          {children}
                        </Header2>
                      ),
                      DropdownIndicator: SimpleDropdownIndicator,
                    }}
                  />
                </div>
                <Header2 className={css.headerText}>Daily Digest</Header2>
              </div>
              {this.renderButtons()}
            </div>
            {this.renderUpdates()}
          </div>
          <QuestionShortTitleModal />
          <EmailPreviewModal />
          <ConfirmationModal
            closeText="Cancel"
            closeType="neutral"
            header="Are you sure you want to remove this update?"
            modalId={DELETE_DIGEST_UPDATE_MODAL.modalId}
            onSubmit={this.handleDeleteUpdate}
            submitText="Delete"
            submitType="destructive"
          />
          <ConfirmationModal
            closeText="Cancel"
            closeType="neutral"
            header="Are you sure you want to remove this question with all its updates?"
            modalId={DELETE_DIGEST_QUESTION_MODAL.modalId}
            onSubmit={this.handleDeleteQuestion}
            submitText="Delete"
            submitType="destructive"
          />
          <ConfirmationModal
            closeText="Cancel"
            closeType="neutral"
            header="Are you sure you want to delete this digest?"
            modalId={DELETE_DIGEST_MODAL.modalId}
            onSubmit={this.handleDelete}
            submitText="Delete"
            submitType="destructive"
          />
        </Page>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchAvailableSpecialtiesRequest = getRequest(
    state,
    FETCH_AVAILABLE_SPECIALTIES
  )
  const fetchTodayDigetRequest = getRequest(state, FETCH_TODAY_DIGEST)
  const addDigestUpdateRequest = getRequest(state, ADD_DIGEST_UPDATE)
  const deleteDigestUpdateRequest = getRequest(state, DELETE_DIGEST_UPDATE)
  const createDigestRequest = getRequest(state, CREATE_DIGEST)
  const deleteDigestRequest = getRequest(state, DELETE_DIGEST)
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)
  const resumeRequest = getRequest(state, RESUME_DIGEST)
  const suspendRequest = getRequest(state, SUSPEND_DIGEST)
  const setDigestOderRequest = getRequest(state, SET_DIGEST_ORDER)
  const preselectedSpecialtyId = new URLSearchParams(
    ownProps.location.search
  ).get('preselectedSpecialtyId')

  return {
    toggleDigestIsLoading: resumeRequest.isLoading || suspendRequest.isLoading,
    fetchTodayDigestIsLoading: fetchTodayDigetRequest.isLoading,
    addDigestUpdateIsLoading: addDigestUpdateRequest.isLoading,
    deleteDigestUpdateIsLoading: deleteDigestUpdateRequest.isLoading,
    availableSpecialties: state.digest.availableSpecialties,
    availableSpecialtiesIsLoaded: fetchAvailableSpecialtiesRequest.isLoaded,
    createIsLoading: createDigestRequest.isLoading,
    setDigestOderIsLoading: setDigestOderRequest.isLoading,
    digestId: state.digest.digestId,
    subject: state.digest.subject,
    digestQuestions: state.digest.questions,
    allUpdates: state.questionUpdate.updates,
    allUpdateNotifications: state.questionUpdate.notifications,
    allQuestions: state.question.questions,
    userData: state.user.data || {},
    userDataIsLoaded: userRequest.isLoaded,
    deleteDigestIsLoading: deleteDigestRequest.isLoading,
    digests: state.digest.digests,
    preselectedSpecialtyId,
    primaryTopics: state.digest.primaryTopics,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTodayDigest: (specialtyId, callback) =>
      dispatch(fetchTodayDigest(specialtyId, callback)),
    fetchAvailableSpecialties: () => dispatch(fetchAvailableSpecialties()),
    openDeleteModal: () => dispatch(openModal(DELETE_DIGEST_MODAL.modalId)),
    openDeleteUpdateModal: (updateId, questionId) =>
      dispatch(
        openModal(DELETE_DIGEST_UPDATE_MODAL.modalId, { updateId, questionId })
      ),
    openDeleteQuestionModal: (questionId) =>
      dispatch(openModal(DELETE_DIGEST_QUESTION_MODAL.modalId, { questionId })),
    openPreviewModal: (digestId, specialtyId) => () =>
      dispatch(
        openModal(
          EMAIL_PREVIEW_MODAL.modalId,
          { digestId, specialtyId },
          EMAIL_PREVIEW_MODAL.screens.digest
        )
      ),
    openSubjectsPreviewModal: (digestId, specialtyId) => () =>
      dispatch(
        openModal(
          EMAIL_PREVIEW_MODAL.modalId,
          { digestId, specialtyId },
          EMAIL_PREVIEW_MODAL.screens.subjects
        )
      ),
    createDigest: (specialtyId) => dispatch(createDigest(specialtyId)),
    deleteDigest: (digestId, callback) =>
      dispatch(deleteDigest(digestId, callback)),
    suspendDigest: (digestId) => dispatch(suspendDigest(digestId)),
    resumeDigest: (digestId) => dispatch(resumeDigest(digestId)),
    addDigestUpdate:
      (specialtyId, digestId, callback) => (updateId, questionId) =>
        dispatch(
          addDigestUpdate(specialtyId, digestId, updateId, questionId, callback)
        ),
    deleteDigestUpdate: (
      specialtyId,
      digestId,
      updateId,
      questionId,
      callback
    ) =>
      dispatch(
        deleteDigestUpdate(
          specialtyId,
          digestId,
          updateId,
          questionId,
          callback
        )
      ),
    fetchNotificationUsage: (specialtyId, questionIds, exclude) =>
      dispatch(fetchNotificationUsage(specialtyId, questionIds, exclude)),
    fetchUpdateInNotificationUsage: (specialtyId, updateIds, exclude) =>
      dispatch(fetchUpdateInNotificationUsage(specialtyId, updateIds, exclude)),
    setDigestOrder: (digestId, questionId, updateIds) =>
      dispatch(setDigestOrder(digestId, questionId, updateIds)),
    setPrimaryTopic: (digestId, questionUpdateId) => (topicId) =>
      dispatch(setPrimaryTopic(digestId, questionUpdateId, topicId)),
    setDigestSubject: (digestId, subject) =>
      dispatch(setDigestSubject(digestId, subject)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDigestPage)
