import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link } from 'pharmacy/src/navigation/link'

import {
  useCmeAndMocAvailabilityCheck,
  useSponsorshipCmeRedeemableAnswers,
} from 'mednet-cns/src/hooks/cme'

import { CmeAssessmentPage } from './cmeAssessmentPage'

import css from './sposorshipCme.scss'

export const SposorshipCme = (props) => {
  const { history } = props
  const { sponsorshipId } = props.match.params
  const [cmeSponsorshipActivities, sponsorshipCmeRedeemableAnswersRequest] =
    useSponsorshipCmeRedeemableAnswers(sponsorshipId)

  const {
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    isCmeAndMocAvailabilityCheckLoaded,
  } = useCmeAndMocAvailabilityCheck()

  const [hasRedeemablesAnswers, setHasRedeemableAnswers] = useState(false)

  useEffect(() => {
    if (!isEmpty(cmeSponsorshipActivities)) {
      // Once this is set to true, don't set it back to false.  Eventually when the user redeems all the answers
      // in this page cmeSponsorshipActivities will be empty, but the user can still do something - update assessments
      setHasRedeemableAnswers(true)
    }
  }, [
    sponsorshipCmeRedeemableAnswersRequest.isLoaded,
    cmeSponsorshipActivities,
  ])

  if (
    !sponsorshipCmeRedeemableAnswersRequest.isLoaded ||
    !isCmeAndMocAvailabilityCheckLoaded
  )
    return <StarLoader isFullScreen />

  if (!hasRedeemablesAnswers) {
    return (
      <div className={css.noneActivitiesMessage}>
        {/* It means answers are redeemed / max in year is redeemed /the expert opened an old link and updated old answers created before the CME release. */}
        You have nothing to redeem for this sponsorship
        <div className={css.link}>
          <Link pathname="/home/index">Go to home page</Link>
        </div>
      </div>
    )
  }

  return (
    <CmeAssessmentPage
      activities={cmeSponsorshipActivities}
      isAbimMocEnabledForUser={isAbimMocEnabledForUser}
      isAbpMocEnabledForUser={isAbpMocEnabledForUser}
      location="Sponsorship Assessment Page"
      history={history}
      onExit={() => {
        history.push({
          pathname: '/home/index',
        })
      }}
    />
  )
}
