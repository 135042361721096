import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import {
  makePOSTHeaders,
  makePOSTHeadersFromParams,
} from 'mednet-cns/src/api/v1'
import { FOLLOWING_TYPE } from 'mednet-util/src/constants/following'

import {
  receiveAction,
  requestAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

import { FETCH_USER_WITH_PERMISSIONS } from './user'
import { FETCH_FEED } from './feed'
import { DELETE_ANSWER, MOVE_ANSWER } from './answer'
import {
  ADD_DIGEST_UPDATE,
  CREATE_DIGEST,
  DELETE_DIGEST_UPDATE,
  FETCH_TODAY_DIGEST,
} from './digest'
import { makeRequestName } from './request'

export const TOGGLE_FOLLOW_QUESTION = 'question/TOGGLE_FOLLOW_QUESTION'
export const TOGGLE_VOTE_QUESTION = 'question/TOGGLE_VOTE_QUESTION'
export const FETCH_QUESTION_METADATA = 'question/FETCH_METADATA'
export const FETCH_QUESTION_CITED_PAPERS =
  'question/FETCH_QUESTION_CITED_PAPERS'
export const FETCH_RELATED_QUESTIONS = 'question/FETCH_RELATED_QUESTIONS'
export const FETCH_QUESTION_ANSWERS = 'question/FETCH_QUESTION_ANSWERS'
export const FETCH_SUGGESTED_USERS = 'question/FETCH_SUGGESTED_USERS'
export const SHARE_QUESTION = 'question/SHARE_QUESTION'
export const CREATE_ANSWER = 'question/CREATE_ANSWER'
export const CREATE_ANSWER_AS_USER = 'question/CREATE_ANSWER_AS_USER'
export const CREATE_QUESTION_REQUEST = 'question/CREATE_QUESTIONREQUEST'
export const FETCH_QUESTION_UPDATES = 'question/FETCH_QUESTION_UPDATES'
export const FETCH_NOTIFICATION_USAGE = 'question/FETCH_NOTIFICATION_USAGE'
export const CREATE_QUESTION = 'question/CREATE_QUESTION'
export const ASK_QUESTION = 'question/ASK_QUESTION'
export const ASK_QUESTION_AS_USER = 'question/ASK_QUESTION_AS_USER'
export const PUT_SHORT_QUESTION = 'question/PUT_SHORT_QUESTION'
export const FETCH_VIEWS_FROM_SPECIALTY = 'question/FETCH_VIEWS_FROM_SPECIALTY'
export const FETCH_CAMPAIGN_QUESTION_METADATA =
  'question/FETCH_CAMPAIGN_QUESTION_METADATA'
export const FETCH_CAMPAIGN_QUESTION_CITED_PAPERS =
  'question/FETCH_CAMPAIGN_QUESTION_CITED_PAPERS'
export const FETCH_CAMPAIGN_RELATED_QUESTIONS =
  'question/FETCH_CAMPAIGN_RELATED_QUESTIONS'
export const FETCH_CAMPAIGN_QUESTION_ANSWERS =
  'question/FETCH_CAMPAIGN_QUESTION_ANSWERS'
export const FETCH_CAMPAIGN_QUESTION_UPDATES =
  'question/FETCH_CAMPAIGN_QUESTION_UPDATES'
export const FETCH_COUNT_OF_QUESTIONS = 'question/FETCH_COUNT_OF_QUESTIONS'
export const FETCH_PERCENTAGE_OF_VIEWED_QUESTIONS =
  'question/FETCH_PERCENTAGE_OF_VIEWED_QUESTIONS'

export function fetchCountOfQuestions() {
  return {
    type: FETCH_COUNT_OF_QUESTIONS,
  }
}

export function fetchPercentageOfViewedQuestions() {
  return {
    type: FETCH_PERCENTAGE_OF_VIEWED_QUESTIONS,
  }
}

export function toggleFollowQuestion(questionId, itemIndex, prevFollowedState) {
  return {
    type: TOGGLE_FOLLOW_QUESTION,
    requestId: questionId,
    questionId,
    itemIndex,
    prevFollowedState,
  }
}

export function toggleVoteQuestion(
  questionId,
  feedName,
  itemIndex,
  prevVotedState
) {
  return {
    type: TOGGLE_VOTE_QUESTION,
    requestId: questionId,
    questionId,
    feedName,
    itemIndex,
    prevVotedState,
  }
}

export function fetchQuestionMetadata(questionId, callback) {
  return {
    type: FETCH_QUESTION_METADATA,
    requestId: questionId,
    questionId,
    callback,
  }
}

export function fetchQuestionCitedPapers(questionId) {
  return {
    type: FETCH_QUESTION_CITED_PAPERS,
    requestId: questionId,
    questionId,
  }
}

export function fetchRelatedQuestions(questionId) {
  return {
    type: FETCH_RELATED_QUESTIONS,
    requestId: questionId,
    questionId,
  }
}

export function fetchQuestionAnswers(questionId, params, callback) {
  return {
    type: FETCH_QUESTION_ANSWERS,
    requestId: questionId,
    questionId,
    params,
    callback,
  }
}

export function fetchQuestionUpdates(questionId, actions) {
  return {
    type: FETCH_QUESTION_UPDATES,
    requestId: questionId,
    questionId,
    actions,
  }
}

export function fetchCampaignQuestionMetadata(hash, questionId, callback) {
  return {
    type: FETCH_CAMPAIGN_QUESTION_METADATA,
    requestId: hash,
    hash,
    questionId,
    callback,
  }
}

export function fetchCampaignQuestionCitedPapers(hash, questionId) {
  return {
    type: FETCH_CAMPAIGN_QUESTION_CITED_PAPERS,
    requestId: hash,
    hash,
    questionId,
  }
}

export function fetchCampaignRelatedQuestions(hash, questionId) {
  return {
    type: FETCH_CAMPAIGN_RELATED_QUESTIONS,
    requestId: hash,
    hash,
    questionId,
  }
}

export function fetchCampaignQuestionAnswers(hash, questionId, params) {
  return {
    type: FETCH_CAMPAIGN_QUESTION_ANSWERS,
    requestId: hash,
    hash,
    questionId,
    params,
  }
}

export function fetchCampaignQuestionUpdates(hash, questionId, actions) {
  return {
    type: FETCH_CAMPAIGN_QUESTION_UPDATES,
    requestId: hash,
    hash,
    questionId,
    actions,
  }
}

export function fetchSuggestedUsers(questionId) {
  return {
    type: FETCH_SUGGESTED_USERS,
    requestId: questionId,
    questionId,
  }
}

export function shareQuestion(questionId, form) {
  return {
    type: SHARE_QUESTION,
    requestId: questionId,
    questionId,
    form,
  }
}

export function createAnswer(questionId, form, callback) {
  return {
    type: CREATE_ANSWER,
    requestId: questionId,
    questionId,
    form,
    callback,
  }
}

export function createAnswerAsUser(questionId, form, callback) {
  return {
    type: CREATE_ANSWER_AS_USER,
    requestId: questionId,
    questionId,
    form,
    callback,
  }
}

export function createQuestionRequest(questionId, formData, callback) {
  return {
    type: CREATE_QUESTION_REQUEST,
    requestId: questionId,
    questionId,
    formData,
    callback,
  }
}

export function fetchNotificationUsage(
  specialtyId,
  questionIds,
  excludeNotificationIds
) {
  return {
    type: FETCH_NOTIFICATION_USAGE,
    specialtyId,
    questionIds,
    excludeNotificationIds,
  }
}

export function createQuestion(params, callback) {
  return {
    type: CREATE_QUESTION,
    params,
    callback,
  }
}

export function askQuestion(params, callback) {
  return {
    type: ASK_QUESTION,
    params,
    callback,
  }
}

export function askQuestionAsUser(params, callback) {
  return {
    type: ASK_QUESTION_AS_USER,
    params,
    callback,
  }
}

export function putShortQuestion(questionId, short, callback) {
  return {
    type: PUT_SHORT_QUESTION,
    questionId,
    short,
    callback,
  }
}

export function fetchViewsFromSpecialty(questionId, specialtyId, callback) {
  return {
    type: FETCH_VIEWS_FROM_SPECIALTY,
    questionId,
    specialtyId,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, TOGGLE_VOTE_QUESTION, (action) =>
    makeURL('qvote/toggleJSON', {
      questionId: action.questionId,
      feedName: action.feedName,
      itemIndex: action.itemIndex,
      prevVotedState: action.prevVotedState,
    })
  )

  yield makeFetchEffect(takeLatest, FETCH_QUESTION_METADATA, (action) =>
    makeURL(`question/getMetadataJSON/${action.questionId}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_QUESTION_CITED_PAPERS, (action) =>
    makeURL(`question/getCitedPapersJSON/${action.questionId}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_RELATED_QUESTIONS, (action) =>
    makeURL(`question/getRelatedQuestionsJSON/${action.questionId}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_QUESTION_ANSWERS, (action) =>
    makeURL(`question/getAnswersJSON/${action.questionId}`, action.params)
  )

  yield makeFetchEffect(takeLatest, FETCH_QUESTION_UPDATES, (action) =>
    makeURL(`question/getUpdatesJSON/${action.questionId}`, {
      actions: action.actions,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_CAMPAIGN_QUESTION_METADATA,
    (action) => makeURL(`campaign/question/getMetadataJSON/${action.hash}`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_CAMPAIGN_QUESTION_CITED_PAPERS,
    (action) => makeURL(`campaign/question/getCitedPapersJSON/${action.hash}`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_CAMPAIGN_RELATED_QUESTIONS,
    (action) =>
      makeURL(`campaign/question/getRelatedQuestionsJSON/${action.hash}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_CAMPAIGN_QUESTION_ANSWERS, (action) =>
    makeURL(`campaign/question/getAnswersJSON/${action.hash}`, action.params)
  )

  yield makeFetchEffect(takeLatest, FETCH_CAMPAIGN_QUESTION_UPDATES, (action) =>
    makeURL(`campaign/question/getUpdatesJSON/${action.hash}`, {
      actions: action.actions,
    })
  )

  yield makeFetchEffect(takeLatest, FETCH_SUGGESTED_USERS, (action) =>
    makeURL(`question/getSuggestedUsersJSON/${action.questionId}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_VIEWS_FROM_SPECIALTY, (action) =>
    makeURL(`question/getViewsFromSpecialty/${action.questionId}`, {
      specialtyId: action.specialtyId,
    })
  )

  yield makeFetchEffect(takeLatest, TOGGLE_FOLLOW_QUESTION, (action) =>
    makeURL('following/toggleJSON', {
      type: FOLLOWING_TYPE.QUESTION,
      followingId: action.questionId,
      itemIndex: action.itemIndex,
      prevFollowedState: action.prevFollowedState,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    SHARE_QUESTION,
    (action) => makeURL(`question/shareJSON/${action.questionId}`),
    (action) => makePOSTHeaders(new FormData(action.form))
  )

  yield makeFetchEffect(
    takeLatest,
    PUT_SHORT_QUESTION,
    (action) => makeURL(`question/short/${action.questionId}`),
    (action) => {
      const formData = new FormData()
      formData.append('short', action.short)
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_ANSWER,
    (action) => makeURL(`question/createAnswerJSON/${action.questionId}`),
    (action) => makePOSTHeaders(new FormData(action.form))
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_ANSWER_AS_USER,
    (action) => makeURL(`question/createAnswerAsUserJSON/${action.questionId}`),
    (action) => makePOSTHeaders(new FormData(action.form))
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_QUESTION_REQUEST,
    makeURL('questionRequest/create'),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, FETCH_NOTIFICATION_USAGE, (action) =>
    makeURL(`question/getNotificationUsageJSON/`, {
      specialtyId: action.specialtyId,
      questionIds: action.questionIds,
      excludeNotificationIds: action.excludeNotificationIds,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_QUESTION,
    makeURL('question/createJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    ASK_QUESTION,
    makeURL('question/askJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    ASK_QUESTION_AS_USER,
    makeURL('question/askAsUserJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_COUNT_OF_QUESTIONS,
    makeURL('question/getQuestionsCountPublicJSON')
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_PERCENTAGE_OF_VIEWED_QUESTIONS,
    makeURL('question/getViewedQuestionsEveryMonthPercentagePublicJSON')
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  questions: {},
  updates: {},
  questionsCount: 10000,
  viewedQuestionsPercentage: 80,
}

const updateQuestionObject = (existingObject, newObject) => {
  const { user, poll, ...rest } = newObject
  const updatedObject = { ...rest }

  if (user) {
    updatedObject.userId = user.userId
  }

  if (poll) {
    updatedObject.pollId = poll.pollId
  }

  if (newObject.lastUpdate && newObject.lastUpdate.user) {
    const { user, ...rest } = newObject.lastUpdate

    updatedObject.lastUpdate = {
      ...rest,
      userId: user.userId,
    }
  }

  return _.assign(existingObject || {}, updatedObject)
}

const getUpdatedQuestions = (existingQuestions, newQuestions) => {
  return _.keyBy(
    _.map(newQuestions, (item) =>
      updateQuestionObject(existingQuestions[item.questionId], item)
    ),
    'questionId'
  )
}

const updateUpdateObject = (existingObject, newObject) => {
  const { user, ...rest } = newObject
  const updatedObject = { ...rest }

  if (user) {
    newObject.userId = user.userId
  }

  return _.assign(existingObject || {}, updatedObject)
}

const getUpdatedUpdates = (existingUpdates, newUpdates) => {
  return _.keyBy(
    _.map(newUpdates, (item) =>
      updateUpdateObject(existingUpdates[item.questionUpdateId], item)
    ),
    'questionUpdateId'
  )
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(CREATE_DIGEST):
    case receiveAction(FETCH_TODAY_DIGEST):
    case receiveAction(ADD_DIGEST_UPDATE):
    case receiveAction(DELETE_DIGEST_UPDATE):
      if (action.response.questions) {
        return receiveReducer(state, action, () => ({
          questions: {
            ...state.questions,
            ...action.response.questions.reduce((map, question) => {
              map[question.questionId] = { ...question }
              return map
            }, {}),
          },
        }))
      }
      return state
    case receiveAction(FETCH_FEED): {
      if (action.response.questions) {
        return receiveReducer(state, action, () => ({
          questions: {
            ...state.questions,
            ...getUpdatedQuestions(state.questions, action.response.questions),
          },
          updates: {
            ...state.updates,
            ...getUpdatedUpdates(state.updates, action.response.items),
          },
        }))
      }

      if (action.itemKey !== 'questionId') {
        return state
      }

      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          ...getUpdatedQuestions(state.questions, action.response.items),
        },
      }))
    }

    case receiveAction(FETCH_USER_WITH_PERMISSIONS): {
      if (!action.response.user || !action.response.user.onboardingQuestion) {
        return state
      }

      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          ...getUpdatedQuestions(state.questions, [
            action.response.user.onboardingQuestion,
          ]),
        },
      }))
    }

    case receiveAction(FETCH_CAMPAIGN_RELATED_QUESTIONS):
    case receiveAction(FETCH_RELATED_QUESTIONS): {
      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          ...getUpdatedQuestions(
            state.questions,
            action.response.relatedQuestions
          ),
          [action.questionId]: {
            ...state.questions[action.questionId],
            relatedQuestions: action.response.relatedQuestions.map(
              (questionObject) => questionObject.questionId
            ),
          },
        },
      }))
    }

    case requestAction(TOGGLE_FOLLOW_QUESTION): {
      const questionObject = state.questions[action.questionId]
      if (!questionObject) {
        return state
      }

      return {
        ...state,
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...questionObject,
            isFollowed: !questionObject.isFollowed,
          },
        },
      }
    }

    case requestAction(TOGGLE_VOTE_QUESTION): {
      const questionObject = state.questions[action.questionId]

      return {
        ...state,
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...questionObject,
            isVoted: !questionObject.isVoted,
            numVotes: questionObject.isVoted
              ? questionObject.numVotes - 1
              : questionObject.numVotes + 1,
          },
        },
      }
    }

    case receiveAction(FETCH_CAMPAIGN_QUESTION_METADATA):
    case receiveAction(FETCH_QUESTION_METADATA): {
      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          [action.questionId]: updateQuestionObject(
            state.questions[action.questionId],
            action.response
          ),
        },
      }))
    }

    case receiveAction(FETCH_CAMPAIGN_QUESTION_CITED_PAPERS):
    case receiveAction(FETCH_QUESTION_CITED_PAPERS): {
      const questionObject = state.questions[action.questionId] || {}

      return receiveReducer(state, action, () => {
        const citedPapers = _.uniqBy(
          action.response.citedPapers,
          'publication.publicationId'
        )
        const citations = action.response.citedPapers

        return {
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...questionObject,
              citedPapers,
              citations,
            },
          },
        }
      })
    }

    case receiveAction(FETCH_CAMPAIGN_QUESTION_ANSWERS):
    case receiveAction(FETCH_QUESTION_ANSWERS): {
      const questionObject = state.questions[action.questionId] || {}

      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...questionObject,
            answers: _.map(
              action.response.answers,
              (answerObject) => answerObject.answerId
            ),
          },
        },
      }))
    }

    case receiveAction(FETCH_SUGGESTED_USERS): {
      const questionObject = state.questions[action.questionId] || {}

      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...questionObject,
            suggestedUsers: _.map(
              action.response.suggestedUsers,
              (userObject) => userObject.userId
            ),
          },
        },
      }))
    }

    case receiveAction(FETCH_VIEWS_FROM_SPECIALTY): {
      const questionObject = state.questions[action.questionId] || {}

      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...questionObject,
            views: {
              ...questionObject.views,
              [action.specialtyId]: action.response[action.specialtyId],
              total: action.response.total,
            },
          },
        },
      }))
    }

    case receiveAction(CREATE_ANSWER_AS_USER):
    case receiveAction(CREATE_ANSWER): {
      const questionObject = state.questions[action.questionId] || {}

      return receiveReducer(state, action, () => {
        if (action.response.success === false) {
          return {}
        }

        return {
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...questionObject,
              numAnswers: questionObject.numAnswers + 1,
              answers: [
                ...questionObject.answers,
                action.response.answer.answerId,
              ],
            },
          },
        }
      })
    }

    case requestAction(MOVE_ANSWER):
    case requestAction(DELETE_ANSWER): {
      const questionObject = state.questions[action.questionId] || {}

      return {
        ...state,
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...questionObject,
            answers: _.filter(
              questionObject.answers,
              (answer) => answer !== action.answerId
            ),
          },
        },
      }
    }

    case receiveAction(FETCH_CAMPAIGN_QUESTION_UPDATES):
    case receiveAction(FETCH_QUESTION_UPDATES): {
      const questionObject = state.questions[action.questionId] || {}

      return receiveReducer(state, action, () => {
        return {
          questions: {
            ...state.questions,
            [action.questionId]: {
              ...questionObject,
              updates: action.response.updates,
            },
          },
        }
      })
    }

    case receiveAction(FETCH_NOTIFICATION_USAGE): {
      return receiveReducer(state, action, () => {
        const questionsNotifications = action.questionIds.reduce(
          (questionsNotifications, id) => {
            questionsNotifications[id] = state.questions[id] || {}
            const notifications = questionsNotifications[id].notifications || {}
            questionsNotifications[id].notifications = {
              ...notifications,
              [action.specialtyId]: {
                ...(notifications[action.specialtyId] || {}),
                ...action.response[id],
              },
            }
            return questionsNotifications
          },
          {
            ..._.get(state, '', {}),
          }
        )
        return {
          questions: {
            ...state.questions,
            ...questionsNotifications,
          },
        }
      })
    }

    case receiveAction(PUT_SHORT_QUESTION): {
      return receiveReducer(state, action, () => ({
        questions: {
          ...state.questions,
          [action.questionId]: {
            ...state.questions[action.questionId],
            short: action.short,
          },
        },
      }))
    }

    case receiveAction(FETCH_COUNT_OF_QUESTIONS): {
      return receiveReducer(state, action, () => ({
        questionsCount: action.response.questionsCount,
      }))
    }

    case receiveAction(FETCH_PERCENTAGE_OF_VIEWED_QUESTIONS): {
      return receiveReducer(state, action, () => ({
        viewedQuestionsPercentage:
          action.response.viewedQuestionsEveryMonthPercentage,
      }))
    }

    default:
      return state
  }
}

export function fetchQuestionMetadataRequest(state, questionId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_QUESTION_METADATA, questionId),
    {}
  )
}

export function getQuestion(state, questionId) {
  return _.get(state, ['question', 'questions', questionId])
}

export function fetchQuestionUpdatesRequest(state, questionId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_QUESTION_UPDATES, questionId),
    {}
  )
}

export function getUpdates(state, questionId) {
  return _.filter(
    _.get(state, ['question', 'questions', questionId, 'updates'], [])
  )
}
