import React from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withGlobalEventListener } from 'react-global-event-listener'

import { Body1, Subtitle2, Subtitle3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { getFeedNameKey, getFeedItems } from 'mednet-util/src/feed'
import { fetchFeed, FETCH_FEED } from 'mednet-cns/src/reducers/feed'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'

import css from './feed.scss'

class Feed extends React.Component {
  static defaultProps = {
    scrollMargin: 200,
    mixins: [],
    feedParams: {},
  }

  constructor(props) {
    super(props)

    if (!props.isLoaded) {
      props.fetchFeed()
    }
  }

  componentDidMount() {
    this.props.subscribeListener(
      window,
      'scroll',
      'Feed.onScroll',
      this.onScroll,
      { listenerWrapper: _.partialRight(_.throttle, 200) }
    )
  }

  componentDidUpdate(prevProps) {
    const { isLoaded, isLoading, isError } = this.props
    const isLoadedOrLoading = isLoaded || isLoading

    const prevFeedNameKey = getFeedNameKey(
      this.props.feedName,
      this.props.feedKey
    )
    const curFeedNameKey = getFeedNameKey(prevProps.feedName, prevProps.feedKey)

    if (!isLoadedOrLoading && prevFeedNameKey !== curFeedNameKey) {
      this.props.fetchFeed()
    }

    if (prevProps.isLoading && !isLoading && !isError) {
      this.onScroll()
    }
  }

  componentWillUnmount() {
    // TODO(guido): Improve react-global-event-listener for changing elements

    try {
      this.props.unsubscribeListener(window, 'scroll', 'Feed.onScroll')
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  onScroll = () => {
    const { isLoading, pageNumber, hasMorePages, scrollMargin, fetchOnScroll } =
      this.props

    // IE Edge sets scrollTop on body instead of documentElement
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    const scrollDepth = window.innerHeight + scrollTop

    if (
      isLoading ||
      isLoading === undefined ||
      hasMorePages === false ||
      fetchOnScroll === false
    ) {
      return
    }

    // This is for when the intro screen is displayed, without this condiion feed is fetched infinitely
    if (
      document.documentElement.style.overflow === 'hidden' ||
      document.body.style.overflow === 'hidden'
    ) {
      return
    }

    if (scrollDepth + scrollMargin >= document.documentElement.offsetHeight) {
      this.props.fetchFeed(pageNumber + 1)
    }
  }

  onTryAgain = () => {
    this.props.fetchFeed()
  }

  renderMixin = (mixinIndex, itemIndex) => {
    const { mixins } = this.props
    const MixinComponent = mixins[mixinIndex].mixinComponent

    return (
      <ErrorBoundary key={`mixin-${mixinIndex}-${itemIndex}`}>
        <div className={css.itemContainer}>
          <MixinComponent itemIndex={itemIndex} />
        </div>
      </ErrorBoundary>
    )
  }

  renderItem = (index) => {
    const {
      FeedItem,
      feedName,
      feedKey,
      itemComponent,
      itemContainerClass,
      trackViewdItems,
    } = this.props

    return (
      <ErrorBoundary key={`item-${index}`}>
        <div className={itemContainerClass || css.itemContainer}>
          <FeedItem
            feedName={feedName}
            feedKey={feedKey}
            itemIndex={index}
            itemComponent={itemComponent}
            trackViewdItems={trackViewdItems}
          />
        </div>
      </ErrorBoundary>
    )
  }

  renderFeed = () => {
    const { items, mixins, transformItems } = this.props

    return getFeedItems({
      items: transformItems ? transformItems(items) : items,
      mixins,
    }).map((item) => {
      if (item.mixinIndex !== undefined) {
        return this.renderMixin(item.mixinIndex, item.itemIndex)
      }

      return this.renderItem(item.index)
    })
  }

  renderEmptyState = () => {
    const { items, isLoaded, emptyMessage } = this.props

    if (isLoaded && items && !items.length && emptyMessage) {
      return (
        <Subtitle2 className={css.emptyContainer}>{emptyMessage}</Subtitle2>
      )
    }

    return null
  }

  render() {
    const {
      children,
      isLoaded,
      isLoading,
      isError,
      initialLoaderProps,
      items,
    } = this.props

    if (!isLoaded) {
      return <StarLoader {...initialLoaderProps} />
    }

    return (
      <div>
        <div>{this.renderFeed()}</div>
        {this.renderEmptyState()}
        {isError && (
          <div className={css.errorContainer}>
            <Body1>Something went wrong!</Body1>
            <Subtitle3>
              Please{' '}
              <Button type="text" onClick={this.onTryAgain}>
                try again
              </Button>{' '}
              in a couple of minutes.
            </Subtitle3>
          </div>
        )}
        {isLoaded && isLoading && <StarLoader />}
        {children && children(items)}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const feedNameKey = getFeedNameKey(ownProps.feedName, ownProps.feedKey)
  const feed = state.feed.feeds[feedNameKey] || {}
  const feedRequest =
    _.get(state.request.requests, makeRequestName(FETCH_FEED, feedNameKey)) ||
    {}

  return {
    items: feed.items,
    pageNumber: feed.pageNumber || 0,
    hasMorePages: feed.hasMorePages,
    isLoaded: feedRequest.isLoaded,
    isLoading: feedRequest.isLoading,
    isError: feedRequest.isError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { feedParams, itemKey } = ownProps
  const feedNameKey = getFeedNameKey(ownProps.feedName, ownProps.feedKey)

  return {
    fetchFeed: (pageNumber) =>
      dispatch(
        fetchFeed(ownProps.feedName, ownProps.feedURL, feedNameKey, itemKey, {
          pageNumber,
          feedParams,
        })
      ),
  }
}

export default compose(
  withGlobalEventListener,
  connect(mapStateToProps, mapDispatchToProps)
)(Feed)
