import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { closeModal, openModal } from 'mednet-cns/src/reducers/modal'

import { TextEditor } from 'pharmacy/src/input/textEditor'
import { Modal } from 'pharmacy/src/display/modal'
import { Button } from 'pharmacy/src/input/button'
import CenteredContent from 'pharmacy/src/display/content/centeredContent'

import { DYNAMIC_TEMPLATES_MODAL } from 'mednet-util/src/constants/modal'

import { JOB_TYPE } from 'mednet-cns/src/reducers/systemJob'

import DynamicTemplatesPage from 'pages/templates/dynamic'

import * as css from './emailBodyEditor.scss'

const DynamicTemplatesScreen = ({ onClick: handleClick, ...props }) => {
  const onClick = () => {
    handleClick && handleClick(props.editor)
  }
  return (
    <CenteredContent size="xlarge">
      <div className={css.actions}>
        <Button onClick={onClick} icon="pen">
          Save
        </Button>
      </div>
      <DynamicTemplatesPage {...props} />
    </CenteredContent>
  )
}

const screens = {
  [DYNAMIC_TEMPLATES_MODAL.screens.main]: DynamicTemplatesScreen,
}

const DynamicTemplatesModal = React.memo((props) => {
  return (
    <Modal
      closeOnBackdrop
      modalId={DYNAMIC_TEMPLATES_MODAL.modalId}
      screens={screens}
      defaultScreen={DYNAMIC_TEMPLATES_MODAL.screens.main}
      size="fullscreen"
      {...props}
    ></Modal>
  )
})
DynamicTemplatesModal.displayName = 'DynamicTemplatesModal'

/**
 * Accepted campaignTypes: 'campaign', 'invitation'
 */
const EmailBodyEditor = ({
  value,
  onEditorChange,
  sponsorshipId,
  campaignType = JOB_TYPE.CAMPAIGN,
  disabled,
}) => {
  const dispatch = useDispatch()
  const sponsorshipParam = !sponsorshipId
    ? ''
    : `&sponsorshipId=${sponsorshipId}`
  const location = window?.location
    ? window.location
    : {
        protocol: 'https:',
        host: 'themednet.org',
      }
  const templates = useMemo(() => {
    if (
      campaignType === JOB_TYPE.INVITE ||
      campaignType === JOB_TYPE.REMINDERINVITE
    ) {
      return [
        {
          title: 'Register button',
          description: 'Button with link to register with the invite',
          content: `<table width=250 height=40><tbody><tr><td style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; display: block; background-color: #006dcc; text-align: center; line-height: 40px !important;"><a style="text-decoration: none; color: #ffffff; width: 100%; height: 100%; display: inline-block;" href="${location.protocol}//${location.host}/invitation/forwardUrl/%inv%">Join theMednet</a></td></tr></tbody></table>`,
        },
        {
          title: 'Register link',
          description: 'Link to register with the invite',
          content: `<a href="${location.protocol}//${location.host}/invitation/forwardUrl/%inv%">Join theMednet</a>`,
        },
        {
          title: 'Recipient first name',
          description: 'Recipient first name',
          content: '%receiver-fname%',
        },
        {
          title: 'Recipient last name',
          description: 'Recipient last name',
          content: '%receiver-lname%',
        },
        {
          title: 'Recipient specialty',
          description: 'Recipient specialty',
          content: '%specialty%',
        },
        {
          title: 'Recipient subspecialty',
          description: 'Recipient specialty',
          content: '%subspecialty%',
        },
        {
          title: "Recipient's institution nickname",
          description: "Recipient's institution nickname",
          content: '%inst-nickname%',
        },
      ]
    }
    return [
      {
        title: 'Download publication paper button',
        description:
          'Button with link to download paper. REMEMBER TO CHANGE PAPER ID IN THE LINK',
        content: `<table width=250 height=40><tbody><tr><td style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; display: block; background-color: #6a9b29; text-align: center; line-height: 40px !important;"><a style="text-decoration: none; color: #ffffff; width: 100%; height: 100%; display: inline-block;" href="${location.protocol}//${location.host}/notification/forwardUrl/%nid%?dl=1&p=[SET THE PUBLICATION ID HERE]">Download Paper for Free</a></td></tr></tbody></table>`,
      },
      {
        title: 'Ask anonymously question button',
        description: 'Button with link to ask a question anonymously',
        content: `<table width=250 height=40><tbody><tr><td style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; display: block; background-color: #006dcc; text-align: center; line-height: 40px !important;"><a style="text-decoration: none; color: #ffffff; width: 100%; height: 100%; display: inline-block;" href="${location.protocol}//${location.host}/question/ask?notification_id=%nid%&anon=1${sponsorshipParam}">Ask a question anonymously</a></td></tr></tbody></table>`,
      },
      {
        title: 'Ask question button',
        description: 'Button with link to ask a question',
        content: `<table width=250 height=40><tbody><tr><td style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; display: block; background-color: #006dcc; text-align: center; line-height: 40px !important;"><a style="text-decoration: none; color: #ffffff; width: 100%; height: 100%; display: inline-block;" href="${location.protocol}//${location.host}/question/ask?notification_id=%nid%${sponsorshipParam}">Ask a question here</a></td></tr></tbody></table>`,
      },
      {
        title: 'Download publication paper link',
        description:
          'Link to download paper. REMEMBER TO CHANGE PAPER ID IN THE LINK',
        content: `<a href="${location.protocol}//${location.host}/notification/forwardUrl/%nid%?dl=1&p=[SET THE PUBLICATION ID HERE]">Download poaper for free here</a>`,
      },
      {
        title: 'Ask anonymously question link',
        description: 'Link to ask a question anonymously',
        content: `<a href="${location.protocol}//${location.host}/question/ask?notification_id=%nid%&anon=1${sponsorshipParam}">Ask a question anonymously here</a>`,
      },
      {
        title: 'Ask question link',
        description: 'Link to ask a question',
        content: `<a href="${location.protocol}//${location.host}/question/ask?notification_id=%nid%${sponsorshipParam}">Ask a question here</a>`,
      },
      {
        title: 'Recipient first name',
        description: 'Recipient first name',
        content: '%receiver-fname%',
      },
      {
        title: 'Recipient last name',
        description: 'Recipient last name',
        content: '%receiver-lname%',
      },
      {
        title: 'Recipient uuid',
        description: 'Recipient uuid',
        content: '%receiver-uuid%',
      },
    ]
  }, [campaignType])

  const editorRef = useRef()
  const templatePlaceholderRef = useRef()

  const handleEditorInit = useCallback((_e, editor) => {
    editorRef.current = editor
  })

  const handleEditorSetup = useCallback(
    (editor) => {
      editor.ui.registry.addButton('dynamicTemplate', {
        text: 'Dynamic Templates',
        onAction() {
          dispatch(
            openModal(DYNAMIC_TEMPLATES_MODAL.modalId, { campaignType, editor })
          )
        },
      })
    },
    [campaignType]
  )

  const saveTemplate = useCallback(
    (editor) => {
      const html = templatePlaceholderRef.current.innerHTML
      editor.insertContent(html)
      dispatch(closeModal(DYNAMIC_TEMPLATES_MODAL.modalId))
    },
    [templatePlaceholderRef.current, editorRef.current]
  )

  return (
    <>
      <TextEditor
        toolbar="styleselect | fontsizeselect | hr | undo redo | indent outdent | alignleft aligncenter alignright alignjustify | bold italic underline | removeformat | numlist bullist | table | link unlink | image | template | dynamicTemplate | code |"
        templates={templates}
        name="body"
        value={value}
        onEditorChange={onEditorChange}
        onInit={handleEditorInit}
        onSetup={handleEditorSetup}
        disabled={disabled}
      />
      <DynamicTemplatesModal
        innerRef={templatePlaceholderRef}
        onClick={saveTemplate}
      />
    </>
  )
}

export default EmailBodyEditor
