import _ from 'lodash'
import { makeURL } from 'mednet-util/src/router'
import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

import { makeRequestName } from './request'

export const FETCH_AVAILABLE = 'subspecialty/FETCH_AVAILABLE'
export const FETCH_SUBSPECIALTIES = 'subspecialty/FETCH_SUBSPECIALTIES'

export function fetchAvailable(callback) {
  return {
    type: FETCH_AVAILABLE,
    callback,
  }
}

export function fetchSubspecialties(specialtyId, callback) {
  return {
    type: FETCH_SUBSPECIALTIES,
    requestId: specialtyId,
    specialtyId,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_AVAILABLE,
    'subspecialty/getAvailableJSON'
  )

  yield makeFetchEffect(takeLatest, FETCH_SUBSPECIALTIES, (action) =>
    makeURL(`specialty/getSubspecialtiesJSON/${action.specialtyId}`)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  subspecialties: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_AVAILABLE): {
      return receiveReducer(state, action, () => ({
        available: action.response.available.map(
          (subspecialty) => subspecialty.subspecialtyId
        ),
        subspecialties: {
          ...state.subspecialties,
          ..._.keyBy(action.response.available, 'subspecialtyId'),
        },
      }))
    }

    case receiveAction(FETCH_SUBSPECIALTIES): {
      return receiveReducer(state, action, () => {
        const set = action.response[action.specialtyId]
        if (!_.isArray(set)) return state
        return {
          subspecialties: {
            ...state.subspecialties,
            ...set.reduce((map, subspecialty) => {
              map[subspecialty.subspecialtyId] = subspecialty
              return map
            }, {}),
          },
        }
      })
    }

    default:
      return state
  }
}

export function getSubSpecialties(state, specialtyId) {
  return !specialtyId
    ? _.values(state.subspecialty.subspecialties)
    : _.filter(
        state.subspecialty.subspecialties || [],
        (subspecialty) => subspecialty.specialtyId === specialtyId
      )
}

export function getAreSubSpecialtiesLoading(state, specialtyId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SUBSPECIALTIES),
    specialtyId
  ).isLoading
}
