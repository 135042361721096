import React from 'react'
import { Helmet } from 'react-helmet'

import { Header1 } from 'pharmacy/src/typography'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { Page } from 'pharmacy/src/display/page'

import { ProfileCard } from 'components/profileCard'
import { TopicList } from 'components/topicList'
import { ScrollUpButton } from 'components/scrollUpButton'

import HomeFeed from './homeFeed'

import css from './home.scss'

class HomePage extends React.Component {
  render() {
    return (
      <Page>
        <Helmet>
          <title>Homepage</title>
        </Helmet>
        <div className={css.content}>
          <div className={css.profileSpacer} />
          <div className={css.profile}>
            <ErrorBoundary>
              <ProfileCard />
            </ErrorBoundary>
          </div>
          <div className={css.feed}>
            <Header1 className={css.header}>Questions</Header1>
            <HomeFeed />
            <ScrollUpButton />
          </div>
          <div className={css.topicSpacer} />
          <div className={css.topics}>
            <ErrorBoundary>
              <TopicList />
            </ErrorBoundary>
          </div>
        </div>
      </Page>
    )
  }
}

export default HomePage
