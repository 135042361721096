export const userStatus = {
  INACTIVE: 0,
  ACTIVE: 1,
  HIDDEN: 2,
  BANNED: -1,
  PENDING: -2,
  UNREGISTERED: -3,
  DO_NOT_CONTACT: -4,
  BAD_EMAIL: -5,
}

export const userJobType = {
  RESIDENCY: 'R',
  INTERNSHIP: 'I',
  FELLOWSHIP: 'F',
  SCHOOL: 'S',
  JOB: 'J',
  UNKNOWN: 'U',
  TEMP: 'X',
}

export const statName = {
  ANSWERS: 'Questions Answered',
  VIEWS: 'Content Views',
  REACH: 'Doctors Reached',
}
