export const publicationAccess = {
  CLOSED: 0,
  OPENED: 1,
  SPONSORED: 2,
  SPONSORED_NO_FILE: 3,
}

export const ACCESS_LABEL = {
  [publicationAccess.CLOSED]: 'Closed for public access',
  [publicationAccess.OPENED]: 'Opened for public access',
  [publicationAccess.SPONSORED]: 'Sponsored',
}
