import React, { useMemo, useCallback, useState } from 'react'
import { Utils as QbUtils } from 'react-awesome-query-builder'
import { debounce } from 'lodash'

import { useCreatePreset } from 'mednet-cns/src/hooks/preset'

import { TabButton, Tabs } from 'pharmacy/src/navigation/tabs'
import TextInput from 'pharmacy/src/input/textInput/textInput'
import { Button } from 'pharmacy/src/input/button'

import { useQueryBuilderConfig } from 'components/userSearchQueryBuilder/config'
import QueryBuilder from 'components/userSearchQueryBuilder/queryBuilder'
import PresetTable from 'components/tables/presetTable'

import * as css from './userSearchWithPresets.scss'

const QueryBuilderWithPreset = React.memo(
  ({ onQueryChange, preset, queryUserStatus, tree }) => {
    const config = useQueryBuilderConfig(queryUserStatus)
    const initialTree = useMemo(() => {
      return QbUtils.checkTree(
        QbUtils.loadTree(
          tree?.id ? tree : { id: QbUtils.uuid(), type: 'group' }
        ),
        config
      )
    }, [tree])

    const [jsonTree, setJsonTree] = useState(initialTree)
    const [presetName, setPresetName] = useState('')
    const [saving, setSaving] = useState(false)

    const createPreset = useCreatePreset(() => {
      setSaving(false)
      setPresetName('')
    })

    const handleQueryChange = useCallback(
      (tree) => {
        const id = `${jsonTree.id}:${Date.now()}`
        onQueryChange &&
          onQueryChange({
            name: preset?.name,
            presetId: preset?.id,
            id,
            tree,
          })
      },
      [onQueryChange]
    )

    const handleSearchClick = useCallback(() => {
      handleQueryChange(jsonTree)
    }, [jsonTree, handleQueryChange])

    const handlePresetCreate = useCallback(() => {
      setSaving(true)
      createPreset({
        name: presetName,
        tree: JSON.stringify(jsonTree),
      })
    }, [presetName, jsonTree])

    const handleTreeChange = useCallback((_config, immutableTree) => {
      const jsonTree = QbUtils.getTree(immutableTree)
      setJsonTree(jsonTree)
    })

    const handlePresetNameChange = useCallback(
      debounce((value) => {
        setPresetName(value)
      }, 200),
      [setPresetName]
    )

    return (
      <>
        <QueryBuilder
          onChange={handleTreeChange}
          initialTree={initialTree}
          queryUserStatus={queryUserStatus}
        />

        <div className={css.actions}>
          <TextInput
            onChange={handlePresetNameChange}
            value={presetName}
            showClear={false}
            className={css.presetName}
          />
          <Button
            isLoading={saving}
            isDisabled={!presetName}
            onClick={handlePresetCreate}
            size="small"
            className={css.action}
          >
            Save as preset
          </Button>
          <Button
            onClick={handleSearchClick}
            size="small"
            className={css.action}
          >
            Search
          </Button>
        </div>
      </>
    )
  }
)
QueryBuilderWithPreset.displayName = 'QueryBuilderWithPreset'

const UserSearchWithPresets = ({
  tree,
  onQueryChange: handleQueryChange,
  queryUserStatus,
  activeTab = 0,
}) => {
  const [preset, setPreset] = useState()
  const [currentActiveTab, setCurrentActiveTab] = useState([activeTab])

  const handlePresetLoad = (preset) => {
    setPreset(preset)
    setCurrentActiveTab([1])
    handleQueryChange &&
      handleQueryChange({
        name: preset?.name,
        presetId: preset?.id,
        tree: preset?.tree,
        id: preset?.tree?.id,
      })
  }

  const tabs = useMemo(
    () => [
      {
        header: <TabButton title="Presets" icon={['far', 'save']} />,
        component: <PresetTable onPresetLoad={handlePresetLoad} />,
      },
      {
        header: <TabButton title="Query" icon={['far', 'search']} />,
        component: (
          <QueryBuilderWithPreset
            tree={tree}
            onQueryChange={handleQueryChange}
            preset={preset}
            queryUserStatus={queryUserStatus}
          />
        ),
      },
    ],
    [handleQueryChange, preset]
  )

  return (
    <div className={css.container}>
      <Tabs tabs={tabs} activeTab={currentActiveTab} />
    </div>
  )
}

export default UserSearchWithPresets
