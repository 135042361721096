import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Icon } from 'pharmacy/src/display/icon'
import { Button } from 'pharmacy/src/input/button'
import { Link1 } from 'pharmacy/src/typography'

import { toggleFollowQuestion } from 'mednet-cns/src/reducers/question'

import Stub from '../../navigation/stub/stub'

class FollowQuestionButton extends React.Component {
  static defaultProps = {
    type: 'icon',
  }

  handleClick = () => {
    const { isFollowed, toggleFollowQuestion } = this.props
    toggleFollowQuestion(isFollowed)
  }

  render() {
    const { isFollowed, type, stubbed } = this.props
    const starIcon = [isFollowed ? 'fas' : 'far', 'star']
    const title = isFollowed
      ? 'Unstar this question to stop following updates'
      : 'Star this question to follow updates'

    if (type === 'icon') {
      return <Icon icon={starIcon} onClick={this.handleClick} title={title} />
    }

    const LinkComponent = stubbed ? Stub : Link1

    if (type === 'button') {
      return (
        <LinkComponent>
          <Button
            icon={starIcon}
            onClick={stubbed ? _.noop : this.handleClick}
            type="text"
          >
            {isFollowed ? 'Unstar' : 'Star'}
          </Button>
        </LinkComponent>
      )
    }

    if (process.env.DEBUG) {
      // eslint-disable-next-line no-console
      console.error('unknown button type')
    }
    return null
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isFollowed } = state.question.questions[ownProps.questionId]

  return {
    isFollowed,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleFollowQuestion: (prevFollowedState) =>
      dispatch(
        toggleFollowQuestion(
          ownProps.questionId,
          ownProps.itemIndex,
          prevFollowedState
        )
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowQuestionButton)
